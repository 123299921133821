
.table-wrapper {
    overflow-x: auto;
}

table.table {
    border-collapse: collapse;
    font-weight: 400;

    th {
        padding: 12px 10px;
        background: $color-deep-blue-100;
        color: $color-white;
        vertical-align: top;
    }

    tbody tr {
        background: $color-sky-blue-25;

        &:nth-child(odd) {
            background: $color-sky-blue-50;
        }
    }

    td {
        padding: 7px 10px;
        border: solid 2px $color-white;

        &:first-child {
            border-left: none;
        }

        &:last-child {
            border-right: none;
        }
    }
}
