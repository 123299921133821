@import "../../style/vars/_all";

$double-cards: 620px;

.events {
    margin: $spacing-sm -1px $spacing-m;

    @media all and (min-width: $breakpoint-card-single) {
        margin: $spacing-l -1px $spacing-m;
    }

    @media all and (min-width: $double-cards) {
        display: flex;
        flex-wrap: wrap;
    }

    > * {
        border: solid 1px $color-white;

        @media all and (min-width: $double-cards) {
            flex: 0 0 50%;
            max-width: 50%;
        }

        @media all and ($breakpoint-framework-width) {
            flex: 0 0 calc(100% / 3);
            max-width: calc(100% / 3);
        }

        @media all and ($breakpoint-wide) {
            flex: 0 0 25%;
            max-width: 25%;
        }
    }
}
