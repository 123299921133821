@import "../../style/vars/_all";

.header {
    @media all and (min-width: 660px) {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .image {
            order: 2;
        }
    }

    @media all and (min-width: 1024px) {
        .image {
            flex: 0 0 250px;
        }
    }

    h3 {
        margin-bottom: $spacing-m;
    }
}

.name {
    margin: 0 0 $spacing-m;
}

.image {
    margin: 0 0 $spacing-m;
}

.profileImage {
    width: 180px;
    height: 180px;
    border-radius: 50%;
}

.info {
    @media all and (min-width: 1024px) {
        display: flex;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .intro {
            flex: 0 1 650px;
        }

        .contactText {
            flex: 0 0 250px;
        }
    }
}

.intro,
.contactText {
    margin-bottom: $spacing-m;
}

.contactActions {
    width: 100%;

    a {
        margin: $spacing-s $spacing-s 0 0;
    }
}
