@import "../../style/vars/_all";
@import "../../style/shared-components-styles/country-page-styles";

:global {
    .country-page-latest-news-block {
        @include container-wide;

        &__heading {
            @include country-page-block-heading;
        }

        &__list {
            display: flex;
            flex-direction: column;

            @media (min-width: $breakpoint-ipad) {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
            }

            &-item {
                margin-bottom: 44px;

                &:last-child{
                    margin-bottom: 0;
                }

                @media (min-width: $breakpoint-ipad) {
                    flex: 0 0 calc(100% / 3 - 20px);
                    max-width: 380px;
                    margin-bottom: 0;
                    margin-right: 20px;
                    
                }

                @media all and ($breakpoint-framework-width) {
                    flex: 0 0 calc(100% / 3 - 60px);
                    max-width: none;
                    margin-right: 60px;
                }

                &:nth-child(3n) {
                    margin-right: 0;
                }
            }
        }

        &__item {
            color: $color-deep-blue;

            &-heading {
                font-size: convert-to-rem(24px);
                line-height: convert-to-rem(32px);
                margin: 18px 0 0;
                font-weight: 700;

                @media (min-width: $breakpoint-ipad) {
                    margin-top: 24px;
                }
            }
        }

        &__item:hover,
        &__item:focus-visible {
            .country-page-latest-news-block__item-heading{
                text-decoration: underline;
            }
        }

        &__published-date {
            margin: 14px 0 0;
            font-weight: 400;
            font-size: convert-to-rem(16px);
            line-height: convert-to-rem(24px);
        }

        &__archive-link{
            margin-top: 48px;
            display: inline-flex;

            @media (min-width: $breakpoint-ipad) {
                margin-top: 54px;
            }
        }
    }
}