@import "../../style/vars/_all";

.container {
    display: flex;
    flex-direction: row;
    margin: 0 0 30px;
    width: 100%;
    flex: 0 0 auto;

    @media (min-width: 960px) {
        margin: 0 0 60px;
    }
}

.image {
    order: 1;
    flex: 0 0 auto;
    background-color: $color-cool-gray-25;
    margin-right: $spacing-sm;
    height: 100px;
    width: 200px;
    border: solid 2px $color-mountain-gray-50;

    @media (min-width: 500px) {
        margin-right: $spacing-m;
        height: 130px;
        width: 250px;
    }
}

.rounded {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    border: none;

    @media (min-width: 500px) {
        height: 130px;
        width: 130px;
    }
}

.information {
    order: 2;
}

.title {
    margin: 0;
}

.form {
    order: 3;
    flex: 1 0 auto;
}

.label {
    @include link("blue");
    display: table;
}

.add {
    order:1;
}

.delete {
    order:2;
}

.input {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.text {
    color: $color-sub;
    margin: 0 0 $spacing-sm;
}

.actions {
    display: flex;
    margin: -5px (-$spacing-s);
    flex-wrap: wrap;

    .label {
        margin: 5px $spacing-s;
    }
}
