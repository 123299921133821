@import "../../style/vars/_all";
@import "../../style/shared-components-styles/market-insight-styles";

:global {
    .market-insight-latest-blog-posts-block {
        @include market-insight-item-list;

        &__container {
            @include container;
            overflow: hidden;
        }
    }
}