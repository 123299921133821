@import "../../style/vars/_all";

:global {
    .sas-html-injection-block {
        @include container;

        &_light-blue {
            background-color: $color-sky-blue-10;

            .sas_components-ReportContainer-ReportContainer_report-container-inner,
            .sas_components-Pane-Pane_pane {
                background-color: $color-sky-blue-10 !important;
            }
        }
    }
}