@import "../../style/vars/_all";

:global {
    .latest-articles-block {
        @include list-items;
        justify-content: space-between;

        &__see-all-link {
            @include see-all;
            align-self: flex-start;

            &:hover,
            &:focus {
                box-shadow: none !important;
            }
        }

        &__content {
            width: 100%;
            flex: 0 0 100%;
        }

        &__heading {
            flex: 0 0 auto;
            width: 50%;
            margin-bottom: 30px;
        }

        &__error {
            margin: $spacing-l 0 $spacing-m;
            background: $color-seafood-50;
            padding: $spacing-s $spacing-sm;
            color: $color-error-text;
        }

        &__articles {
            display: flex;
            flex-wrap: wrap;
        }

        &__article {
            display: block;
            text-decoration: none;
            color: $color-deep-blue-100;
            margin: 0 0 2px;
            width: 100%;

            @media all and (min-width: $breakpoint-card-single) {
                flex: 0 0 auto;
                width: 50%;
                border-left: solid 1px transparent;
                border-right: solid 1px transparent;
            }
        }

        &__filters {
            position: relative;

            &_loading:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }

        &__tag-filter {
            @include tags;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

            &-option {
                @include tag;
                display: flex;
                justify-content: center;
                flex-direction: column;
                background-color: white;
            }

            &-option-text {
                align-self: center;
            }
        }
    }
}
