$list-item-indent: 90px;
$list-item-indent-tablet: 25px;
$list-item-indent-mobile: 15px;

@mixin market-insight-page-heading {
    @include container;

    font-size: 24px;
    line-height: 1.4;
    font-weight: 400;
    margin: 24px 0 10px;

    @media all and ($breakpoint-framework-width) {
        font-size: 46px;
        line-height: 1.2;
        margin: 65px 0 38px;
    }
}

@mixin market-insight-page-intro {
    @include container;
    margin: 10px 0 40px;

    @media all and ($breakpoint-framework-width) {
        margin: 38px 0 90px;
    }
}

@mixin market-insight-section-heading {
    font-size: 24px;
    line-height: 1.55;
    font-weight: 700;
    margin-bottom: 16px;

    @media all and ($breakpoint-framework-width) {
        font-size: 32px;
        line-height: 1.3;
    }
}

@mixin market-insight-section-intro {
    font-size: 24px;
    line-height: 1.55;
    font-weight: 700;
    margin-bottom: 16px;

    @media all and ($breakpoint-framework-width) {
        font-size: 32px;
        line-height: 1.3;
    }
}

@mixin market-insight-item-list {
    $self: &;
    margin-top: 40px;
    @media all and ($breakpoint-framework-width) {
        margin-top: 72px;
    }

    &__heading {
        font-size: 24px;
        line-height: 1.55;
        font-weight: 700;
        margin-bottom: 16px;

        @media all and ($breakpoint-framework-width) {
            font-size: 32px;
            line-height: 1.3;
        }
    }

    &__list {
        display: flex;
        flex-wrap: nowrap;
        margin-top: 24px;
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        overflow-x: auto;

        @media all and ($breakpoint-framework-width) {
            flex-wrap: wrap;
            padding-left: 0;
            margin-left: 0;
            margin-right: -#{$list-item-indent-tablet};
            margin-bottom: -#{$list-item-indent-tablet};
        }

        @media all and ($breakpoint-wide-sm) {
            margin-right: -#{$list-item-indent};
            margin-bottom: -48px;
        }
    }

    &__item {
        $self: &;
        margin-right: $list-item-indent-tablet;
        flex: 0 0 75vw;

        @media all and ($breakpoint-framework-width) {
            margin-bottom: $list-item-indent-tablet;
            width: auto;
            flex: 0 0 calc((100% / 3) - #{$list-item-indent-tablet});
        }

        @media all and ($breakpoint-wide-sm) {
            flex: 0 0 calc((100% / 3) - #{$list-item-indent});
            margin-right: $list-item-indent;
            margin-bottom: 48px;
        }
    }

    &__link {
        body & {
            font-size: 16px;

            @media all and ($breakpoint-framework-width) {
                font-size: 18px;
            }
        }

        &-holder {
            margin-top: 16px;

            @media all and ($breakpoint-framework-width) {
                text-align: right;
                margin-top: 42px;
            }
        }
    }
}

@mixin market-insight-sidebar-item-heading {
    display: block;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.55;
    margin-bottom: 8px;
}

@mixin market-insight-sidebar-item-intro {
    margin-bottom: $spacing-sm;
}