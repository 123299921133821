@import "../../style/vars/_all";

:global {
    .press-contacts-block {
        &__top-contact {
            &:before {
                content: "";
                background: $color-deep-blue-100;
                position: absolute;
                left: 0;
                top:0;
                width: 100%;
                height: calc(100% - 100px);
            }
        }

        &__top-contact,
        &__press-contacts {
            position: relative;
            @include list-items;
            background: white;
        }

        &__top-contact-wrapper {
            position: relative;
            z-index: 5;
            background: white;
            box-shadow: 0 15px 23px rgba($color-box-shadow, .46);
            overflow: hidden;
        }

        &__contacts {
            flex: 0 0 calc(100% + 30px);
            justify-content: space-between;
            position: relative;
            padding: 0;
            flex-direction: row;
            flex-wrap: wrap;
            display: flex;
            margin: 0 -15px;
        }

        &__contact{
            flex: 0 0 100%;
            padding: 15px;

            @media all and (min-width: 620px) {
                flex: 0 0 50%;
                max-width: 50%;
            }
    
            @media all and (min-width: 1400px) {
                flex: 0 0 calc(100% / 3);
                max-width: calc(100% / 3);
            }
        }

        &__heading {
        }

        &__see-all-link {
            @include see-all;
            align-self: flex-start;

            &:hover,
            &:focus {
                box-shadow: none !important;
            }
        }
    }
}
