@import "../../style/vars/_all";

.newsletter {
    margin: $spacing-m 0;
    padding: $spacing-m 0 0;
    border-top: solid 2px $color-cool-gray-25;

    @media all and ($breakpoint-framework-width) {
        margin: $spacing-l 0;
        padding: $spacing-l 0 0;
    }
}
.submit {
    margin: $spacing-m 0 0;

    @media all and ($breakpoint-framework-width) {
        margin: $spacing-l 0 0;
    }
}