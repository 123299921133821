@import "../../style/vars/_all";
@import "../../style/shared-components-styles/_typography-styles";

:global {
    .rich-text {
        @include base-typography-styles;
        @include content-typography-styles;

        &_in-feature-article-sources {
            line-height: 24px;

            @media all and ($breakpoint-framework-width) {
                line-height: 32px;
            }

            p:not(:last-child),
            p {
                margin: 0;
            }
        }

        &_in-market-insight-customs-quotas-body,
        &_in-customs-quotas-terms-popup {
            color: $color-deep-blue;
            line-height: 1.55;

            @media all and ($breakpoint-framework-width) {
                font-size: 18px;
            }

            h2, h3 {
                font-weight: 400;
                margin: 30px 0 16px;

                @media all and ($breakpoint-framework-width) {
                    margin-top: 50px;
                }
            }

            h2, h3, h4 {
                letter-spacing: -0.74px;

                &:first-child {
                    margin-top: 0;
                }
            }

            h2 {
                font-size: 22px;
                line-height: 1.25;

                @media all and ($breakpoint-framework-width) {
                    font-size: 32px;
                }
            }

            h3 {
                font-size: 18px;
                line-height: 1.33;

                @media all and ($breakpoint-framework-width) {
                    font-size: 24px;
                }
            }

            h4 {
                font-size: 18px;
                line-height: 1.44;
                font-weight: 700;
                margin: 16px 0;
            }

            p {
                &:not(:last-child) {
                    margin: 0 0 30px;
                }
            }

            ul {
                li {
                    &:before {
                        top: 11px;
                        width: 4px;
                        height: 4px;
                        background-color: $color-deep-blue;
                    }
                }
            }

            ul, ol {
                padding-left: 8px;
                margin: 16px 0;

                li {
                    margin: 0;
                    line-height: 1.44;

                    @media all and ($breakpoint-framework-width) {
                        font-size: 18px;
                    }
                }
            }
        }
        &_in-country-page,
        &_in-country-page-image-video-text-block,
        &_in-country-page-latest-news-on-market-access-block,
        &_in-country-page-webinars-block,
        &_in-country-page-latest-reports-and-publications-block {
            font-size: convert-to-rem(18px);
            line-height: convert-to-rem(26px);
            font-weight: 400;
            color: $color-deep-blue;

            h2,
            h3,
            h4,
            h5 {
                font-weight: 700;

                &:first-child {
                    margin-top: 0;
                }
            }

            h2,
            h3 {
                margin-bottom: 16px;
            }

            h3,
            h4 {
                font-size: convert-to-rem(24px);
                line-height: convert-to-rem(32px);
                margin: 0 0 10px;

                @media (min-width: $breakpoint-ipad) {
                    margin-bottom: 16px;
                }
            }

            a {
                color: inherit;
                box-shadow: inset 0 -1px 0 currentColor;
            }
        }

        &_in-country-page-contact-us-block,
        &_in-country-page-contact-us-block-address {
            font-weight: 400;
            font-size: convert-to-rem(18px);
            line-height: convert-to-rem(26px);
            background: $color-blue-border;
            color: white;

            p,
            ol,
            li {
                color: white;
                font-size: convert-to-rem(18px);
                line-height: convert-to-rem(26px);
                font-weight: 400;
            }

            li {
                margin-bottom: 0;
            }

            ul,
            ol {
                margin: 0;
                padding: 0;

                li {
                    margin-left: 20px;
                }
            }

            ul li {
                &:before {
                    background-color: currentColor;
                }
            }

            a {
                display: inline;
                color: white;
                position: relative;
                box-shadow: none;
                transition: border-color 0.3s ease-in-out;
                border-bottom-width: 2px;
                border-bottom-style: solid;
                vertical-align: top;
                padding: 0 0 2px;
                text-decoration: none;
                margin-right: 30px;

                &:hover,
                &:focus-visible {
                    border-color: transparent !important;
                }

                &:after {
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTYgMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPGcgaWQ9Ikljb24tMTZ4MTYtQXJyb3ctUmlnaHQiPg0KICAgICAgICA8cGF0aCBpZD0iSWNvbi0xNngxNi1BcnJvdy1SaWdodF8yIiBkPSJNOCAwTDYuNSAxLjVMMTIgN0gwVjlIMTJMNi41IDE0LjVMOCAxNkwxNiA4TDggMFoiIGZpbGw9IndoaXRlIi8+DQogICAgPC9nPg0KPC9zdmc+DQo=");
                    background-repeat: no-repeat;
                    zoom: 100%;
                    position: absolute;
                    left: calc(100% + 8px);
                    top: 4px;
                    content: "";
                    display: inline-block;
                    background-size: 16px 16px;
                    width: 16px;
                    height: 16px;
                }
            }
        }

        &_in-country-page-webinars-block,
        &_in-country-page-contact-us-block-address,
        &_in-country-page-latest-reports-and-publications-block {
            p{
                margin: 0 !important;

                &:empty {
                    height: convert-to-rem(26px);
                }
            }
        }
    }
}