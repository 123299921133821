@import "../../style/vars/_all";

$bottom-tag-indent: 6px;

:global {
    .simple-tag-list {
        &__items {
            display: flex;
            flex-flow: wrap;
            margin-bottom: -#{$bottom-tag-indent};
        }

        &__item {
            margin-bottom: $bottom-tag-indent;

            &:not(:last-child) {
                margin-right: 8px;
            }
        }
    }
}