@import "../../style/vars/_all";

$navigation-item-height: 40px;
$accordion-heading-selector: "[data-accordion-component='AccordionItemHeading']";
$accordion-button-selector: "[data-accordion-component='AccordionItemButton']";

.headingWrapper {
    display: flex;
    margin-bottom: 16px;
}

.headingIcon {
    max-width: 30px;
    max-height: 26px;
    margin-right: 6px;
    margin-top: 5px;
}

.headingText {
    font-size: 24px;
    line-height: 34px;
    font-weight: 500;
    letter-spacing: -0.3px;
}

.description {
    color: $color-description-text;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 30px;

    a {
        text-decoration: underline;
        color: $color-deep-blue;
        
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}

.navigationItem {
    display: flex;
    position: relative;
    height: $navigation-item-height;
    border-left: 3px solid $color-deep-blue;
    background-color: $color-cool-gray-0;
    margin-bottom: 10px; 
}

.navigationItemDisabled {
    opacity: 0.25;
    #{$accordion-button-selector} {
        pointer-events: none;
    }
}

.navigationLink {
    flex: 1;
    padding: 0 17px;
    font-size: 18px;
    line-height: $navigation-item-height;
    color: $color-deep-blue;
    @include truncate-text;

    &:hover, 
    &:focus {
        & {
            text-decoration: underline;
        }
        &, 
        & + #{$accordion-heading-selector} #{$accordion-button-selector},
        & + #{$accordion-heading-selector} #{$accordion-button-selector} .navigationArrowIcon {
            outline: none;
            background-color: $color-cool-gray-25;

            .navigationItemDisabled & {
                background: none;
            }
        }
    }
}

.navigationArrowBtn {
    display: block;
    width: 72px;
    height: 100%;
    background-color: $color-cool-gray-0;
    border: none;
    cursor: pointer;

    &:hover {
        .navigationArrowIcon {
            background-color: $color-cool-gray-45;
        }
    }
}

.navigationArrowIcon {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    width: 42px;
    margin-left: auto;
    background-color: $color-cool-gray-0;

    &:before {
        content: '';
        border: solid $color-deep-blue;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);

        [aria-expanded="true"] & {
            border-width: 2px 0 0 2px;
            transform: rotate(45deg) translate(2px, 2px);
            -webkit-transform: rotate(45deg) translate(2px, 2px);
        }
    }
}