@import "../../style/vars/_all";

$rootClass: ".image-with-popup";

:global {
    #{$rootClass} {

        position: relative;
        box-shadow: $shadow-block;

        @media all and ($breakpoint-framework-width) {
            @include framed-block;
        }
    
        &__holder {
            position: relative;
            background-color: $color-cool-gray-25;
        }
    }
}
