@import "../../style/vars/_all";

.result {
    display: flex;
    margin: 2px 0 0;
    background-color: $color-white;
    color: $color-deep-blue-100;
    text-decoration: none;

    &.hover,
    &:hover {
        background-color: $color-sea-blue-100;
        color: $color-white;
    }
}

.header {
    order: 2;
    padding: $spacing-sm;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    h2 {
        font-size: 1.125rem;
        line-height: 1.75rem;

        svg {
            height: 13px;
            width: 13px;
            fill: currentColor;
            margin-right: 10px;

            @media all and (min-width: $breakpoint-card-image) {
                display: none;
            }
        }
    }
}

.intro,
.meta {
    font-size: .875rem;
    line-height: 1.1875rem;
    max-height: 1.1875rem;
    overflow: hidden;
}

.meta {
    display: none;
}

.image {
    order: 1;
    flex: 0 0 auto;
    height: auto;
    min-height: $spacing-xl;
    width: $spacing-xl;
    overflow: hidden;
    background-color: $color-cool-gray-25;
    text-align: center;
    justify-content: center;
    display: flex;
    position: relative;
    
    @media all and (max-width: $breakpoint-card-image - 1px) {
        display: none;
    }
}

.employee {
    .image {
        margin: 10px;
        height: 70px;
        width: 70px;
        flex: 0 0 70px;
        border-radius: 50%;
    }
}

.categories:before {
    content: " / ";
}

.news {
    .intro {
        display: none;
    }

    .meta {
        display: block;
    }
}

.no-access {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($color-modal, .8);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    svg {
        fill: $color-white;
        max-height: $spacing-s;
    }

    span {
        color: $color-white;
        display: none;
    }
}