@import "../../style/vars/_all";

:global {
    .customs-quotas-terms-popup {
        &__heading {
            font-size: 32px;
            line-height: 1.16;
            font-weight: 500;
            margin-bottom: 32px;

            @media all and ($breakpoint-framework-width) {
                font-size: 48px;
            }
        }

        &__last-updated {
            font-size: 18px;
            line-height: 1.55;
            font-weight: 300;
            margin-bottom: 44px;
        }

        &__intro {
            margin: $spacing-m 0;
            max-width: 654px;

            @media all and ($breakpoint-framework-width) {
                margin: 48px 0;
            }
        }

        &__actions {
            display: flex;
            align-items: center;
            margin-top: 70px;
            gap: 0 24px;
        }
    }
}
