@import "../../style/vars/_all";

$text-bg: rgba(#fff, 0.8);
$rootClass: ".photographer";

:global {
    #{$rootClass} {
        $self: &;
        z-index: 3;
        position: absolute;
        bottom: 0;
        left: 0;
        color: $color-content-text;
        background-color: $text-bg;
        padding: 2px 8px;
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;

        @media all and ($breakpoint-framework-width) {
            font-size: 18px;
            line-height: 28px;
            padding: 8px 20px;
        }
        
        &_in-image-with-popup{
            left: 15px;
            bottom: 15px;
            padding: 5px 10px;
            background-color: $color-black-transparent-50;
            color: $color-white;
            line-height: 1.5;

            @media all and ($breakpoint-framework-width) {
                font-size: 16px;
                padding: 10px 15px;
            }
        }

        &_in-image-text-block {
            position: static;
            margin-top: 10px;
            padding: 0;
            font-size: 14px;
            line-height: 1.5;
            background: none;

            #{$rootClass}__label {
                font-weight: inherit;
            }
    
            @media all and ($breakpoint-framework-width) {
                font-size: 16px;
                margin-top: 25px;
            }
        }

        &-label {
            font-weight: 500;
        }
    }
}
