@import "../../style/vars/_all";

$list-item-indent: 40px;
$list-item-indent-tablet: 25px;
$list-item-indent-mobile: 15px;

:global {
    .market-insight-cta-link-list {
        &__grid {
            @media all and ($breakpoint-framework-width) {
                display: flex;
                flex-wrap: wrap;
                margin-right: -#{$list-item-indent-tablet};
                margin-bottom: -#{$list-item-indent-tablet};
            }
    
            @media all and ($breakpoint-wide-sm) {
                margin-right: -#{$list-item-indent};
                margin-bottom: -48px;
            }
        }

        &__heading {
            width: 100%;
            flex: 0 0 auto;
            font-size: 18px;
            line-height: 1.4;
            font-weight: 500;
            margin-bottom: 20px;

            @media all and ($breakpoint-framework-width) {
                font-size: 24px;
                margin-bottom: 27px;
            }
        }

        &__item {
            @media all and ($breakpoint-framework-width-max) {
                &:not(:last-child) {
                    margin-bottom: $list-item-indent-mobile;
                }
            }

            @media all and ($breakpoint-framework-width) {
                margin-right: $list-item-indent-tablet;
                margin-bottom: $list-item-indent-tablet;
                flex: 0 0 calc((100% / 3) - #{$list-item-indent-tablet});
            }

            @media all and ($breakpoint-wide-sm) {
                flex: 0 0 calc((100% / 3) - #{$list-item-indent});
                margin-right: $list-item-indent;
                margin-bottom: 48px;
            }
        }
    }
    
    .market-insight-cta-link {
        display: block;
        height: 100%;
        position: relative;
        padding: 24px 40px 20px 16px;
        color: $color-deep-blue;
        border: 2px solid $color-cool-gray-50;
        background-color: $color-white;

        @media all and ($breakpoint-framework-width) {
            padding: 24px 46px 24px 24px;
        }

        &:hover,
        &.hover,
        &:focus {
            border-color: $color-black-transparent-50;
        }

        &:hover,
        &:focus {
            &:after {
                right: 15px;
            }
        }

        &_restricted {
            border-color: $color-cool-gray-45;

            &:hover,
            &.hover,
            &:focus {
                border-color: $color-black-transparent-25;
            }
        }

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 20px;
            margin-top: -7px;
            display: block;
            height: 14px;
            width: 8px;
            background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDggMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDEuNUw3IDcuNUwxIDEzLjUiIHN0cm9rZT0iI0JCQkNCQyIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
            background-size: cover;
            transition: right 300ms;
        }

        &__heading {
            font-size: 20px;
            line-height: 1.36;
            font-weight: 700;
            word-wrap: break-word;

            @media all and ($breakpoint-framework-width) {
                font-size: 22px;
                margin-bottom: -2px;
            }

            &-icon {
                flex: 0 0 16px;
            }
        }

        &__text {
            font-size: 16px;
            line-height: 1.55;
            color: inherit;

            @media all and ($breakpoint-framework-width) {
                font-size: 20px;
                line-height: 1.7;
            }
        }

        &__icon {
            display: inline-block;
            margin-left: 10px;
            margin-bottom: -1px;
            width: 16px;
            height: 21px;
            fill: $color-deep-blue;
        }
    }
}