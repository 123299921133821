@import "../../style/vars/_all";

.searchblock {
    background-color: $color-deep-blue;
    background-size: cover;
    background-position: center;
    color: $color-white;
    display: flex;
    flex: 1;
}

.form {
    align-self: center;
    padding: $spacing-l $spacing-sm;

    @media all and ($breakpoint-framework-width) {
        padding: $spacing-xl $spacing-l;
        max-width: 720px;
        margin-right: 50%;
        margin-left: auto;
    }
}

.tagline {
    text-shadow: 0 2px 4px rgba($color-modal, .41);
    margin: 0 0 $spacing-l;
}

.light {
    color: $color-deep-blue-100;
    text-shadow: none;
}

.searchWrapper {
    @media all and (min-width: 50rem) {
        height: 60px;
    }
}

.searchContainer {
    position: relative;
    background: $color-white;
    z-index: $z-index-search-results;
}

.searchResults {
    position: absolute;
    width: 100%;
    overflow: hidden;
    background-color: $color-cool-gray-25;
    box-shadow: 0 15px 23px rgba($color-box-shadow, .46);
}

.seeAllResults {
    color: $color-deep-blue-100;
    display: inline-block;
    margin: $spacing-s $spacing-sm;
    text-decoration: none;

    &:after {
        content: " ⟶";
    }

    &.hover,
    &:hover {
        color: $color-sea-blue-100;
    }
}

.noResults {
    background-color: $color-cool-gray-25;
    color: $color-deep-blue-100;
    padding: $spacing-sm;
}

.label {
    position: absolute;
    left: -999em;
}

.error {
    position: absolute;
    width: 100%;
    overflow: hidden;
    background-color: $color-cool-gray-25;
    color: $color-deep-blue-100;
    box-shadow: 0 15px 23px rgba($color-box-shadow, .46);
    padding: $spacing-sm;
}

