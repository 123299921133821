@import "../../style/vars/_all";
@import "../../style/shared-components-styles/_typography-styles";

$rootClass: ".more-feature-articles";
$hover-transition: 350ms cubic-bezier(0.165, 0.84, 0.44, 1);

@mixin fixed-proportion-container($width, $height) {
	height: 0px;
	padding-bottom: calc($height / $width) * 100%;
	position: relative;
}

:global {
    #{$rootClass} {
        background: $color-cool-gray-25;
        padding-top: 24px;
        padding-bottom: 24px;
        margin-top: 50px;

        @media all and ($breakpoint-framework-width) {
            padding-top: 75px;
            padding-bottom: 75px;
        }

        @media all and ($breakpoint-wide-l) {
            margin-top: 100px;
            padding-top: 100px;
            padding-bottom: 100px;
        }

        &__container {
            @include page-block;
            max-width: 1180px;
            margin-bottom: 0 !important;
            margin-top: 0 !important;
        }

        &__heading {
            font-size: 18px;
            line-height: 24px;
            margin: 0 0 24px;
            color: $color-deep-blue-100;
            font-weight: 500;
            text-align: center;
            font-size: 24px;
            line-height: 34px;

            @media all and ($breakpoint-framework-width) {
                font-size: 46px;
                line-height: 56px;
                margin-bottom: 40px;
            }
        }

        &__items {
            display: flex;
            flex-direction: column;
            width: 100%;
            flex-wrap: nowrap;

            @media (min-width: $breakpoint-ipad) {
                flex-direction: row;
                justify-content: center;
            }
        }

        &__item {
            width: 100%;
            background: white;
            border-radius: 5px;
            color: $color-content-text;
            margin-bottom: 24px;
            overflow: hidden;

            &:last-child {
                margin-bottom: 0;
            }

            @media (min-width: $breakpoint-ipad) {
                @include hoverable-card();
                width: calc((100% - 40px) / 3);
                margin-bottom: 0;
                margin-right: 20px;
                box-shadow: 0 0 0 rgba(#141d2f, 0.46);
                transition: box-shadow 200ms ease-in-out;

                &:last-child {
                    margin-right: 0;
                }
            }

            &:hover,
            &:focus {
                outline: none;

                @media (min-width: $breakpoint-ipad) {
                    box-shadow: 0 22px 34px rgba(#141d2f, 0.46);

                    #{$rootClass}__item-image {
                        transform: scale(1);
                    }
                }
            }

            &-text {
                padding: 10px 10px 0;
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 10px;
                @include truncate-text(5);
                white-space: pre-line;

                @media all and ($breakpoint-framework-width) {
                    font-size: 18px;
                    line-height: 28px;
                    padding: 20px 20px 0;
                    margin-bottom: 30px;
                }
            }
            &-image {
                @include full-size;
                transform: scale(1.05);
                transition: transform $hover-transition;

                &-container {
                    @include fixed-proportion-container(380, 214);
                    overflow: hidden;
                    &_no-image{
                        background-color: $color-mountain-gray-50;
                    }
                }
            }
        }
    }
}
