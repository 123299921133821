@import "../../style/vars/_all";
@import "../../style/shared-components-styles/_list-page-styles";

:global {
    .market-insight-report-list {
        position: relative;
        min-height: 50px;

        &__heading {
            font-size: 24px;
            font-weight: 700;
            line-height: 1.4;
            margin-bottom: 24px;

            @media all and ($breakpoint-framework-width) {
                font-size: 32px;
                line-height: 1.2;
                margin-bottom: 40px;
            }
        }

        &__filters {
            margin-bottom: 35px;

            @media all and ($breakpoint-framework-width) {
                margin-bottom: 48px;
            }
        }

        &__loading{
            @include list-page-loading;
        }

        &__month {
            @include list-page-month;
        }

        &__error {
            @include list-page-error;
        }

        &__pager {
            margin-top: 30px;

            @media all and ($breakpoint-framework-width) {
                margin-top: 64px;
            }
        }
    }

    .market-insight-report-item {
        @include list-page-item;
    }
}
