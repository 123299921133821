@import "../../style/vars/_all";

$icon-size: 48px;
$icon-size-mobile: 32px;

.socialMediaLinksBlock {
    background-color: $color-cool-gray-25;
}

.socialMediaLinksWrapper {
    padding: $spacing-m;
    padding: $spacing-sm $spacing-sm $spacing-m;

    @media all and ($breakpoint-framework-width) {
        padding: $spacing-m $spacing-m $spacing-xl;
    }

    @media all and ($breakpoint-wide-l) {
        $width: $framework-max-width;
        $padding: calc(50% - (#{$width} / 2));

        padding-left: $padding;
        padding-right: $padding;
    }
}

.socialMediaList {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}

.socialMediaListTitle {
    margin-bottom: $spacing-sm;
    @media all and ($breakpoint-framework-width) {
        margin-bottom: $spacing-m;
    }
}

.socialMediaListItem {
    width: 50%;
    padding: $spacing-sm $spacing-sm $spacing-sm 0;

    @media all and ($breakpoint-framework-width) {
        padding-left: $spacing-sm;
        border-left: 2px solid $color-mountain-gray-50;
        width: 25%;
    }
}

.socialMediaLink {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 1.35;
    color: $color-deep-blue-100;

    &:hover {
        text-decoration: underline;
    }

    @media all and ($breakpoint-framework-width) {
        font-size: 28px;
    }
}

.socialMediaLinkIcon {
    display: block;
    height: auto;
    max-width: $icon-size-mobile;
    max-height: $icon-size-mobile;

    @media all and ($breakpoint-framework-width) {
        max-width: $icon-size;
        max-height: $icon-size;
    }
}

.socialMediaLinkText {
    margin-left: 10px;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-word;

    @media all and ($breakpoint-framework-width) {
        margin-left: $spacing-sm;
    }
}