.employee-block {
    margin: auto;
    max-width: 455px;

    .employee-intro {
        position: relative;
        color: $color-deep-blue;
        background-color: $color-white;
        padding: $spacing-sm;


        @media all and (min-width: 600px) {
            @include bottom-right-decal($color-white);
            padding: $spacing-m $spacing-m $spacing-sm;
            margin-right: $spacing-m + 70;
        }
    }

    .employee-contact {
        display: block;
        margin: $spacing-m 0 0;
    }

    .employees-link {
        width: 100%;
        display: block;
        margin: $spacing-s 0 0;
    }

    .employee-card {
        margin-top: $spacing-sm;

        @media all and (min-width: 600px) {

            .image {
                position: absolute;
                bottom: 0;
                right: -100px;
                margin: 0;
            }
        }

        img {
            max-width: 70px;
            max-height: 70px;
        }
    }
}
