@import "../../style/vars/_all";

.company {
    margin: 0 0 $spacing-m;
}

.description {
    white-space: pre-line;
}

.details {
    @include typo('small-nf');

    > div {
        margin: $spacing-m 0;

        a {
            color: $color-white;
            text-decoration: underline;
        }
    }
}

@media all and (min-width: 1024px) {
    .container {
        display: flex;
    }

    .description {
        flex: 1;
    }

    .details {
        flex: 0 0 auto;
        width: 276px;
        margin-left: $spacing-xl;
        margin-top: -$spacing-m;
    }
}
