.link {
    display: block;
    height: 100%;

    &[disabled] {
        cursor: not-allowed;
    }
}

.hasNoMobileUrl {
    @media (max-width: 1024px) {
        display: none;
    }
}
