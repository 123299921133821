@import "../../style/vars/_all";

.wrapper {
    background-color: $color-cool-gray-25;
    padding: $spacing-l $spacing-sm;
    text-align: center;
    margin: $spacing-sm 0 0;

    h3 {
        margin: 0 0 $spacing-s;
    }

    p {
        max-width: 580px;
        margin: $spacing-s auto $spacing-m;
    }

    a {
        color: $color-sea-blue-100;
        box-shadow: inset 0 -2px 0 $color-sea-blue-25;
    }

    @media all and (min-width: $breakpoint-card-single) {
        margin: $spacing-l 0 0;
        padding: $spacing-xl $spacing-l;
    }
}

.button{
    font-size: inherit;
    border: none;
    background: none;
    color: $color-sea-blue-100;
    box-shadow: inset 0 -2px 0 $color-sea-blue-25;
    cursor: pointer;
    transition: box-shadow 0.3s;

    &:hover,
    &:focus-visible{
        box-shadow: inset 0 -2px 0 transparent;
    }
}

.light {
    background-color: $color-white;
}

.margin {
    margin-left: $spacing-sm;
    margin-right: $spacing-sm;

    @media all and (min-width: $breakpoint-card-single) {
        margin-left: $spacing-l;
        margin-right: $spacing-l;
    }
}