@mixin base-typography-styles() {
    a {
        @extend %link-blue;
    }

    p, address {
        margin: 0 0 $spacing-m;

        &:last-child {
            margin-bottom: 0;
        }
    }

    address {
        font-style: normal;
    }

    > h2 {
        margin: $spacing-l 0 $spacing-s;
    }

    ul {
        li {
            &:before {
                content: "\25CF";
                position: absolute;
                top: 0;
                left: 3px;
                display: block;
            }
        }
    }

    ol {
        counter-reset: item;

        > li {
            counter-increment: item;
        }

        li {
            &:before {
                position: absolute;
                left: 0;
                font-weight: 500;
                content: counter(item) ".";
            }
        }

        ul > li:before {
            content: "";
        }
    }

    ol, ul {
        margin: 0 0 $spacing-m;
        list-style: none;

        li {
            position: relative;
            padding-left: 1.9rem;
            margin-top: $spacing-s;
        }

        ul, ol {
            margin-top: $spacing-s;
            margin-bottom: 0;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    strong {
        font-weight: 500;
    }

    &.negative {
        color: $color-white;

        a {
            color: $color-white;

            &.hover,
            &:hover {
                box-shadow: inset 0 -2px 0 $color-white;
            }
        }
    }
}

@mixin content-typography-styles() {
    font-weight: 300;
    color: $color-content-text;
    font-size: 16px;
    line-height: 1.5;
    
    @media all and ($breakpoint-framework-width) {
        font-size: 20px;
        line-height: 1.6;
    }

    a {
        @extend %link-dark; 
    }

    h1, h2, h3, h4 {
        font-family: $font-family;
        margin: $spacing-s 0 5px;
        font-weight: 500;

        @media all and ($breakpoint-framework-width) {
            margin: $spacing-m 0 5px;
        }
    }

    p, ul, ol {
        &:not(:last-child) {
            margin: 0 0 $spacing-s-mobile;

            @media all and ($breakpoint-framework-width) {
                margin: 0 0 $spacing-sm;
            }
        }
    }

    ul, ol {
        padding-left: $spacing-s;

        @media all and ($breakpoint-framework-width) {
            padding-left: $spacing-m;
        }

        li {
            margin-top: 2px;
            padding-left: $spacing-s;

            @media all and ($breakpoint-framework-width) {
                margin-top: 5px;
                padding-left: $spacing-sm;
            }
        }
    }

    ol {
        li {
            &:before {
                font-weight: inherit;
            }
        }
    }

    ul {
        li {
            &:before {
                content: '';
                display: inline-block;
                width: 4px;
                height: 4px;
                top: 10px;
                border-radius: 50%;
                background-color: $color-content-text;

                @media all and ($breakpoint-framework-width) {
                    top: 14px;
                    width: 5px;
                    height: 5px;
                }
            }
        }
    }
}