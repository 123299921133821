@import "../shared-components-styles/market-insight-styles";

.market-insight-customs-quotas-page {
    $self: &;

    &__heading {
        @include market-insight-page-heading;
        font-weight: 500;

        #{$self} & {
            padding: 0;
        }

        @media all and ($breakpoint-framework-width) {
            font-size: 48px;
            line-height: 1.16;
            margin: 30px 0 34px;
        }
    }

    &__preamble {
        @include container-medium;
        margin-bottom: 50px;
    }

    &__last-updated {
        font-size: 18px;
        line-height: 1.55;
        font-weight: 300;
        margin-bottom: 44px;
    }

    &__intro {
        margin: 10px 0 24px;
        max-width: 650px;

        @media all and ($breakpoint-framework-width) {
            margin: 44px 0 48px;
        }
    }

    &__content {
        @include container-medium;
        padding-top: 35px;
        padding-bottom: 50px;

        @media all and ($breakpoint-framework-width) {
            display: flex;
            padding-top: 115px;
            padding-bottom: 100px;
        }
    }

    &__body {
        @media all and ($breakpoint-framework-width) {
            flex: 0 0 63%;
            padding-right: 128px;
        }
    }

    &__sidebar {
        margin-top: $spacing-l;

        @media all and ($breakpoint-framework-width) {
            flex: 0 0 37%;
            margin-top: 0;
        }
    }
}