@import "../../style/vars/_all";

:global {
    .country-page-activities-campaigns-block {
        @include container-wide;
        flex-direction: column;
        flex-wrap: wrap;
        margin-bottom: 24px;

        @media (min-width: $breakpoint-ipad) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &__item {
            margin-bottom: 56px;
            
            @media (min-width: $breakpoint-ipad) {
                flex: 0 0 calc(50% - 26px);
            }

            &-heading {
                font-size: convert-to-rem(32px);
                font-weight: 700;
                line-height: convert-to-rem(40px);
            }

            &-description {
                margin-top: 10px;
                font-size: convert-to-rem(18px);
                line-height: convert-to-rem(26px);
                font-weight: 400;
                color: $color-deep-blue;
            }

            &-link {
                margin-top: 26px;
            }
        }
    }
}