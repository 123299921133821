.tag-label {
    @include typo('small-nf');
    display: inline-block;
    margin: $spacing-s 10px 0 0;
    border-radius: 2px;
    background-color: $color-sea-blue-50;
    color: $color-deep-blue-100;
    line-height: 29px !important;
    padding: 0 8px;
}
