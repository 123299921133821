body.request-form-page {
    .request-form {
        padding: $spacing-l $spacing-l;
        margin: 0 auto;
        max-width: 768px;

        .intro {
            margin: $spacing-s 0 $spacing-l;
        }
    }
}
