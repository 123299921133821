$font-family: "Radikal", sans-serif;

$typo-small-min: 12px;
$typo-small: 14px;
$typo-small-lineheight: 19px;

$types: (
    body: (
        font-size: (16px, 18px),
        line-height: (26px, 28px),
        font-weight: 400
    ),
    h1: (
        font-size: (28px, 46px),
        line-height: (38px, 56px),
        font-weight: 500
    ),
    h2: (
        font-size: (24px, 28px),
        line-height: (34px, 38px),
        font-weight: 300
    ),
    h3: (
        font-size: (18px, 24px),
        line-height: (28px, 34px),
        font-weight: 400
    ),
    h4: (
        font-size: (16px, 18px),
        line-height: (26px, 28px),
        font-weight: 500
    ),
    lead: (
        font-size: (20px, 28px),
        line-height: (30px, 38px),
        font-weight: 400
    ),
    small: (
        font-size: ($typo-small-min, $typo-small),
        line-height: $typo-small-lineheight
    ),
    small-nf: (
        font-size: $typo-small,
        line-height: $typo-small-lineheight
    )
);

%typo-framework {
    font-size: 16px;
    line-height: 26px;
}

%typo-framework {
    font-size: 1rem;
    line-height: 1.625rem;
}

%typo-sub {
    color: $color-sub;
    font-size: 0.75rem;
    line-height: 1rem;
}

%link-white {
    color: $color-white;
    text-decoration: none;
    box-shadow: inset 0 -1px 0 rgba($color-sea-blue-100, .25);

    &:hover,
    &.hover,
    &:focus {
        box-shadow: inset 0 -1px 0 $color-white;
    }
}

%link-white-deep-blue {
    @extend %link-white;
    box-shadow: inset 0 -1px 0 $color-deep-blue-75;
}

%link-blue {
    @include link("blue");
}

%link-dark {
    color: $color-content-text;
    font-weight: 400;
    text-decoration: none;
    box-shadow: inset 0 -1px 0 $color-content-text;

    &:hover,
    &.hover,
    &:focus {
        box-shadow: none;
    }
}

@mixin link-underlined($color: $color-blue-border){
    box-shadow: inset 0 -2px 0 $color;
    transition: box-shadow 0.5s;
    padding-bottom: 3px;
    outline: none;
}

@mixin link-underlined-hover{
    box-shadow: none;
}
