@import "../../style/vars/_all";
@import "../../style/shared-components-styles/_list-page-styles";
@import "../../style/shared-components-styles/_statistic-list-styles";

:global {
    .market-insight-statistic-archive-list {
        &__loading{
            @include list-page-loading;
        }

        &__error {
            @include list-page-error;
        }

        &__item {
            max-width: 520px;

            &:not(:last-child) {
                margin-bottom: 40px;

                @media all and ($breakpoint-framework-width) {
                    margin-bottom: 72px;
                }
            }
        }
    }

    .market-insight-statistic-archive-item {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.2;

        @media all and (min-width: $breakpoint-mobile) {
            font-size: 16px;
        }

        @media all and ($breakpoint-framework-width) {
            font-size: 20px;
        }

        &__heading {
            display: block;
            font-weight: 600;
            margin-bottom: 16px;

            @media all and ($breakpoint-framework-width) {
                font-size: 24px;
                margin-bottom: 18px;
            }
        }

        &__row {
            display: flex;
            flex-wrap: wrap;

            @media all and ($breakpoint-framework-width) {
                border-bottom: 1px solid $color-cool-gray-50;
            }

            &:not(:last-child) {
                margin-bottom: 18px;
            }
        }

        &__col {
            margin-right: 15px;

            @media all and ($breakpoint-framework-width) {
                flex: 0 0 26%;
                margin-right: 0;
            }

            &_info {
                flex: 0 0 100%;

                @media all and ($breakpoint-framework-width) {
                    flex: 1;
                }
            }
        }
        
        &__cell {
            @include statistic-cell;

            @media all and ($breakpoint-framework-width) {
                padding: 15px 12px;
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        &__link {
            @include statistic-link;
        }

        &__icon {
            @include statistic-icon;
        }
    }
}
