%button-white {
    background-color: $color-white;
    color: $color-deep-blue-100;
}

%button-transparent {
    background-color: $color-transparent;
    color: $color-deep-blue-100;
}

%button-deep-blue {
    background-color: $color-deep-blue-100;
    color: $color-white;
}

%button-sea-blue {
    background-color: $color-sea-blue-100;
    color: $color-white;
}

%button-dirty-blue {
    background-color: $color-sea-blue-65;
    color: $color-deep-blue-100;
}

button {
    font-family: $font-family;
    font-weight: 400;
    border-radius: 0;

    &.flex {
        // Firefox ignores buttons with display: flex;
        div {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &.active,
    &:active {
        /* TODO */
        outline: none !important;
    }

    &.focus,
    &:focus {
        /* TODO */
        outline: none !important;
    }
}

a.button,
button.button,
input.button,
.EPiServerForms button {
    @extend %button-deep-blue;
    @include typo(h3);
    font-family: $font-family;
    display: inline-block;
    min-width: 100%;
    min-height: $spacing-l;
    text-align: center;
    -webkit-appearance: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
    padding: 16px $spacing-m;
    box-shadow: none;
    color: $color-white;

    @media all and (min-width: 400px) {
        min-width: 280px;
    }

    &.secondary {
        @extend %button-transparent;
        color: $color-deep-blue-100;
        box-shadow: inset 0 0 0 2px $color-deep-blue-100;

        &.negative {
            @extend %button-sea-blue;
            box-shadow: inset 0 0 0 2px $color-white;

            &.hover,
            &:hover,
            &:focus {
                @extend %button-deep-blue;
            }
        }

        &.negative-light {
            @extend %button-deep-blue;
            box-shadow: inset 0 0 0 2px $color-white;

            &.hover,
            &:hover,
            &:focus {
                @extend %button-sea-blue;
            }
        }

        &.hover,
        &:hover,
        &:focus {
            @extend %button-sea-blue;
            box-shadow: none;
        }
    }

    &.compact {
        @include typo(h4);
        min-width: 197px;
        min-height: 50px;
        padding: 11px $spacing-sm;
    }

    &.small {
        @include typo(h4);
        min-width: 197px;
        min-height: 50px;
        padding-top: 11px;
        padding-bottom: 11px;
        // line-height: 50px !important;
    }

    &.mini {
        font-weight: 500;
        font-size: 14px;
        min-width: auto;
        min-height: 45px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    &.negative {
        @extend %button-deep-blue;

        &.hover,
        &:hover,
        &:focus {
            @extend %button-white;
        }
    }

    &.negative-light {
        @extend %button-sea-blue;

        &.hover,
        &:hover,
        &:focus {
            @extend %button-white;
        }
    }

    &.negative-dirty {
        @extend %button-dirty-blue;

        &.hover,
        &:hover,
        &:focus {
            @extend %button-white;
        }
    }

    &.external {
        @include typo(h4);
    }

    &.toggle {
        @include typo(body);
        text-align: left;
        min-width: 190px;
        position: relative;

        svg {
            fill: currentColor;
            width: 15px;
            height: 8px;
            position: absolute;
            top: 50%;
            right: $spacing-m;
            transform: translateY(-50%);
        }
    }

    &.hover,
    &:hover,
    &:focus {
        @extend %button-sea-blue;
        box-shadow: none;
        outline: none !important;
    }

    &.active,
    &:active {
        /* TODO */
        outline: none !important;
    }

    &.disabled,
    &[disabled] {
        background-color: $color-button-disabled-background;
        color: $color-button-disabled-text;
        cursor: not-allowed;
    }
}

.EPiServerForms {
    button {
        margin: 20px 0;
    }

    .FormChoice__Input--Checkbox {
        margin: 0 10px;
    }
}

a.button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
