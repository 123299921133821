@import "../vars/_all";
.list-page-header {
    @include page-header;
   
    &.has-background {
        @include page-header-with-bg;
    }

    &.has-background {
        .fluid-image {
            @include page-header-image;
        }
    }

    .text {
        @include page-header-text;
    }

    h1 {
        margin: 0 0 $spacing-s;

        @media all and ($breakpoint-header-width) {
            margin-bottom: $spacing-m;
        }
    }
}
