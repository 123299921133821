@import "../../style/vars/_all";

:global {
    .tags {
        @include tags;
    }

    .tag {
        @include tag;
    }
}
