$input-border-width: 10px;

$select-input-bg: $color-white;
$select-input-border-width: 0px;
$select-input-border-radius: 0px;
$select-padding-horizontal: $spacing-sm;
$select-input-height: 50px;
$select-input-placeholder: $color-input-placeholder;

$select-text-color: $color-deep-blue-100;
$select-option-color: $color-deep-blue-100;
$select-option-focused-color: $color-white;
$select-option-focused-bg: $color-sea-blue-100;
$select-option-selected-color: $color-white;
$select-option-selected-bg: $color-sea-blue-100;

$select-item-color: $color-white;
$select-item-bg: $color-sea-blue-100;

$select-item-gutter: 5px;
$select-item-padding-horizontal: 8px;

.Select {
    .Select-menu-outer {
        box-shadow: 0 15px 23px rgba($color-box-shadow, .46); // TODO: make variable
        border: none;
    }

    &.is-disabled > .Select-control {
        cursor: not-allowed;
        background-color: $color-white !important;

        > * {
            opacity: .5;
        }
    }

    &--multi {
        div.Select-value {
            border-width: 0px;
            margin-left: 0;
            margin-right: 10px;
            white-space: nowrap;

            span.Select-value-icon {
                border-right-width: 2px;
                min-width: 26px;
                text-align: center;
            }

            span.Select-value-label {
                @include typo("small-nf")
            }
        }

        .Select-value {
            margin: 5px;
        }

        .Select-multi-value-wrapper {
            display: inline-flex !important;
            padding: 0 20px;
            align-items: center;
            flex-wrap: wrap;
        }

        div.Select-input,
        input.Select-input {
            margin-left: 0;
        }

        input.Select-input {
            color: $color-deep-blue-100;
            font-weight: 400;
        }
    }

    .Select-option {
        border-bottom: solid 2px $color-cool-gray-25;

        &:last-child {
            border-bottom: none;
        }
    }

    .Select-control {
        &:hover {
            box-shadow: none;
        }
    }

    &.is-open {
        .Select-control {
            box-shadow: inset $input-border-width 0 0 $color-sea-blue-100 !important;
        }

        .Select-arrow {
            transform: rotate(180deg);
        }
    }

    &.negative {
        .Select-option {
            background-color: $color-white;

            &.is-selected {
                background-color: $select-option-selected-bg;
                color: $select-option-selected-color;
            }

            &.is-focused {
                background-color: $select-option-focused-bg;
                color: $select-option-focused-color;
            }
        }

        .Select-menu-outer {
            box-shadow: 0 15px 23px rgba($color-box-shadow, .46); // TODO: make variable
            border: none;
        }

        .Select-control {
            background-color: $color-cool-gray-25 !important;
        }
    }

    .Select-arrow-zone {
        padding-right: $spacing-sm;

        .Select-arrow {
            border: none;
            background: url(../assets/input-dropdown.svg);
            width: 15px;
            height: 8px;
        }
    }

    .Select-loading-zone {
        padding-right: 10px;
    }

    .Select-noresults {
        @include typo('small-nf');
        color: $color-sub;
        font-weight: 500;
    }
}

%input-text {
    @include typo(body);
    font-family: $font-family;
    background-color: $color-cool-gray-25;
    color: $color-deep-blue-100;
    min-height: 3.125rem;
    padding: 0 $spacing-sm;
    border: none;
    width: 100%;
    appearance: none;
    border-radius: 0;

    &.negative {
        background-color: $color-white;

        &.dropdown svg {
            fill: $color-mountain-gray-100;
        }
    }

    &:not([readonly]) {
        &.focus,
        &:focus,
        &.active,
        &:active {
            box-shadow: inset $input-border-width 0 0 $color-sea-blue-100;
            outline: none;
        }
    }

    &.error {
        box-shadow: inset $input-border-width 0 0 $color-error;
        color: $color-error-text;
    }

    &.dropdown {
        svg {
            height: 8px;
            width: 15px;
            margin-left: auto;
            fill: $color-mountain-gray-75;
        }
    }

    &.no-label {
        &::-webkit-input-placeholder {
            color: $color-deep-blue-100;
        }

        &::-moz-placeholder {
            color: $color-deep-blue-100;
        }

        &:-ms-input-placeholder {
            color: $color-deep-blue-100;
        }
    }
}

.input {
    @extend %input-text;
    display: inline-flex;
    align-items: center;
}

.input-wrapper,
.search-wrapper {

    .input-validation {
        @include typo("small-nf");
        margin: 10px 0 0;
        display: block;
        color: $color-sub;
        min-height: 19px;
    }

    &.negative,
    &.negative-light {
        input, button {
            background-color: $color-white;
        }
    }

    &.negative-light {
        label,
        small {
            color: $color-white;
        }
    }

    &.readonly {
        cursor: not-allowed;

        label {
            color: $color-sub;
        }

        input[readonly] {
            color: $color-sub;
            cursor: not-allowed;
        }
    }
}

label {
    @include typo(h4);
    display: table;
    margin: 0 0 10px;
}

input[type="text"],
input.input[type="search"],
input[type="email"],
input[type="password"],
input[type="number"],
textarea {
    @extend %input-text;
}

textarea {
    height: 200px;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow: auto;
}

label.checkbox {
    position: relative;
    margin: 0;

    input {
        position: absolute;
        left: -999em;

        &:focus {
            outline: auto;

            ~ span:before {
                box-shadow: inset 0 0 0 2px $color-sea-blue-100;
            }
        }

        &:checked ~ span {
            &:after {
                content: "";
                position: absolute;
                top: 7px;
                left: 4px;
                height: 13px;
                width: 19px;
              	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxOXB4IiBoZWlnaHQ9IjEzcHgiIHZpZXdCb3g9IjAgMCAxOSAxMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5VbnRpdGxlZDwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPHBhdGggZD0iTTcuMDEwNDA3NjQsNy44ODkwODczIEwxNC44OTk0OTQ5LDEuMDY1ODE0MWUtMTMgTDE3LjAyMDgxNTMsMi4xMjEzMjAzNCBMNy4xMjEzMjAzNCwxMi4wMjA4MTUzIEw2Ljc2Nzc2Njk1LDExLjY2NzI2MTkgTDYuNjU2ODU0MjUsMTEuNzc4MTc0NiBMMSw2LjEyMTMyMDM0IEwzLjEyMTMyMDM0LDQgTDcuMDEwNDA3NjQsNy44ODkwODczIFoiIGlkPSJDb21iaW5lZC1TaGFwZSIgZmlsbD0iIzAwNzhENCI+PC9wYXRoPiAgICA8L2c+PC9zdmc+);
            }
        }
    }

    span {
        position: relative;
        display: flex;
        font-weight: 400;
        font-size: 18px;
        overflow: hidden;

        &:before {
            content: "";
            display: inline-block;
            height: 25px;
            width: 25px;
            box-shadow: inset 0 0 0 2px $color-mountain-gray-50;
            margin-right: 10px;
        }
    }

    &.hover,
    &:hover {
        cursor: pointer;

        span:before {
            box-shadow: inset 0 0 0 2px $color-sea-blue-100;
        }
    }

}

.checkbox-with-link{
    padding: 0 0 0 35px;
    
    label.checkbox{    
        display: inline;
    
        span{
            display: inline;
            font-weight: 500;
            
            &:before{
                position: absolute;
                top: 0;
                left: -35px;
            }
        }

        input:checked ~ span {
            &:after {
                left: -31px;
            }
        }
    }
    
    a{
        @include typo(h4);
        color: #001e64;
        text-decoration: underline;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}

label.radio {
    position: relative;
    margin: 0;

    input {
        position: absolute;
        left: -999em;

        &:focus {
            ~ span:before {
                box-shadow: inset 0 0 0 2px $color-sea-blue-100;
            }
        }

        &:checked ~ span {
            &:after {
                content: "";
                position: absolute;
                top: 7px;
                left: 7px;
                height: 13px;
                width: 13px;
                border-radius: 50%;
                background-color: $color-sea-blue-100;
            }
        }
    }

    span {
        position: relative;
        display: flex;
        font-weight: 400;
        font-size: 18px;

        &:before {
            content: "";
            display: inline-block;
            height: 27px;
            width: 27px;
            border-radius: 50%;
            box-shadow: inset 0 0 0 2px $color-mountain-gray-50;
            margin-right: 10px;
        }
    }

    &.hover,
    &:hover {
        cursor: pointer;

        span:before {
            box-shadow: inset 0 0 0 2px $color-sea-blue-100;
        }
    }
}

.search-wrapper {
    display: flex;

    input[type="submit"],
    button {
        border: none;
        -webkit-appearance: none;
        color: $color-transparent;
        background-color: $color-cool-gray-25;
        padding: 0 $spacing-s;
        cursor: pointer;
        font-size: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex: 0 0 auto;

        svg {
            width: 25px;
            height: 25px;
            margin: 0 1px 0 2px; // Make svg same width as spinner
            fill: $color-mountain-gray-100;
        }

        &:hover,
        &:focus {
            background-color: $color-sea-blue-100;
            outline: auto!important;
            svg {
                fill: $color-white;
            }
        }

        @media all and ($breakpoint-framework-width) {
            padding: 0 $spacing-m;
        }
    }
}

.button-wrapper {
    margin: 0 -15px;
    display: flex;
    flex-wrap: wrap;

    @media all and ($breakpoint-framework-width) {
        padding: 0 10px;
    }

    .button {
        margin: calc($spacing-sm / 2) $spacing-s;
    }
}

.form-wrapper {
    display: flex;
    flex-wrap: wrap;

    @media all and ($breakpoint-framework-width) {
        margin-right: -10px;
        margin-left: -10px;
    }
}

.input-wrapper {
    flex: 0 1 auto;
    width: 100%;
    margin-bottom: 20px;

    label {
        display: block;
    }

    @media all and ($breakpoint-framework-width) {
        padding-right: 10px;
        padding-left: 10px;

        &.input-half-width {
            width: 50%;
        }

        &.input-one-quarter-width {
            width: 25%;
        }

        &.input-three-quarter-width {
            width: 75%;
        }
    }

    &.input {
        &-half-width {
            @media all and ($breakpoint-framework-width) {
                width: 50%;
            }
        }
        &-one-quarter-width {
            @media all and ($breakpoint-framework-width) {
                width: 25%;
            }
        }
        &-three-quarter-width {
            @media all and ($breakpoint-framework-width) {
                width: 75%;
            }
        }

    }
}

.field-validation-error {
    color: $color-error-text;
}
