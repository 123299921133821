@import "../../style/vars/_all";
@import "../../style/vars/full-page-blocks-mixins";

$text-bg: rgba(#fff, 0.8);

:global {
    .full-page-quotation-block {
        $self: &;
        width: 100%;
        height: 100vh;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        z-index: 2;
        justify-content: center;

        &__text-container {
            position: relative;
            z-index: 2;
            align-self: flex-start;
            align-self: center;
            text-align: left;
            max-width: 100%;
            width: 800px;
            padding: 0 18px;
            color: $text-bg;
            @media all and ($breakpoint-framework-width) {
                padding: 0 40px;
            }
        }

        &__icon{
            position: absolute;
            top: -39px;
            left: -69px;
            width: 69px;
            height: 58px;
            display: none;

            @media all and ($breakpoint-framework-width) {
                display: block;
            }
        }

        &__author {
            font-size: 16px;
            line-height: 41px;
            color: $color-content-text;
            font-weight: 300;
            font-style: italic;
            margin: 15px 0 0;

            @media all and ($breakpoint-framework-width) {
                font-size: 22px;
                line-height: 84px;
                margin: 0;
            }
            &-wrapper {
                @include inline-text-with-bg($text-bg, 8px, 8px, 8px);

                @media all and ($breakpoint-framework-width) {
                    @include inline-text-with-bg($text-bg, 7px, 8px, 20px);
                }
            }
        }

        &__text {
            font-size: 22px;
            line-height: 52px;
            color: $color-content-text;
            font-weight: 300;
            font-style: italic;
            white-space: pre-line;

            @media all and ($breakpoint-framework-width) {
                font-size: 45px;
                line-height: 92px;
            }
            &-wrapper {
                @include inline-text-with-bg($text-bg, 8px, 9px, 8px);

                @media all and ($breakpoint-framework-width) {
                    @include inline-text-with-bg($text-bg, 18px, 15px, 20px);
                }
            }
        }

        &__photographer {
            z-index: 3;
            position: absolute;
            bottom: 0;
            left: 0;
            color: $color-content-text;
            background-color: $text-bg;
            padding: 5px;
            display: none;
            font-weight: 300;

            @media all and ($breakpoint-framework-width) {
                display: block;
            }

            &-label {
                font-weight: 500;
            }
        }
    }
}
