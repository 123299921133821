.addtocalendar {
    display: block;
    margin: $spacing-s 0 0;
    position: relative;

    var {
        display: none;
    }

    .atcb-link {
        @extend %link-blue;

        &:focus {
            outline: none;
        }
    }

    .atcb-link:focus~ul,
    .atcb-link:active~ul,
    .atcb-list:hover{
        visibility: visible;
    }


    .atcb-list {
        visibility: hidden;
        position: absolute;
        top: 100%;
        left: 0;
        width: 190px;
        z-index: 900;
        background-color: $color-white;
        box-shadow: 0px 5px 10px 0px rgba(19,29,47,0.25);
        font-size: 14px;
        margin-top: 5px;
    }

    .atcb-item-link {
        display: block;
        color: $color-deep-blue-100;
        padding: 5px $spacing-s;

        &.hover,
        &:hover {
            background-color: $color-sea-blue-100;
            color: $color-white;
        }
    }
}
