.share-links {
    border-top: solid 2px $color-cool-gray-25;
    padding-top: $spacing-s;
    overflow: hidden;

    header {
        @include visually-hidden();
    }
}

.share-link {
    float: left;
    display: block;
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    background-color: $color-mountain-gray-100;
    margin: 0 $spacing-s $spacing-s 0;
    color: $color-white;
    text-align: center;
    margin-bottom: 0 !important;
    cursor: pointer;
    box-shadow: none !important;

    svg {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        width: 20px;
        height: 20px;
        stroke-width: 0;
        stroke: currentColor;
        fill: currentColor;
    }

    &.print {
        background-color: $color-print;
    }

    &.email {
        background-color: $color-email;
    }

    &.facebook {
        background-color: $color-facebook;
    }

    &.twitter {
        background-color: $color-twitter;
    }

    .label {
        @include visually-hidden();
    }
}
