@import "../shared-components-styles/market-insight-styles";

.market-insight-qa-list-page {
    &__heading {
        @include market-insight-page-heading;
    }

    &__intro {
        @include market-insight-page-intro;
    }

    &__pages {
        @include container;
        margin-bottom: 50px;

        @media all and (min-width: $breakpoint-mobile) {
            margin-bottom: 90px;
        }
    }
}