@import "../../style/vars/_all";

.table {
    width: 100%;
    border-collapse: collapse;
    color: $color-deep-blue-100;
    margin: $spacing-m auto;

    @media all and (max-width: 1023px) {
        display: block;

        thead {
            display: none;
        }

        tbody,
        tr {
            display: block;
        }
    }

    th {
        text-align: left;
        padding: $spacing-s $spacing-m;
    }

    
}

.resultsCountText {
    margin: $spacing-m 0;
}

.caption {
    font-size: convert-to-rem(32px);
    line-height: convert-to-rem(40px);
    font-weight: 500;
    text-align: left;
    margin-bottom: 12px;
}

.sortButton{
    border: none;
    padding: 0;
    margin: 0;
    text-align: left;
    font-size: inherit;
    color: inherit;
    line-height: inherit;
    background: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    cursor: pointer;
}

.sortButtonText{
    align-self: center;
    display: flex;
    flex: 0 0 calc(100% - 26px);
    text-decoration: none;
    @extend %link-blue;
    @include typo("small-nf");
    position: relative;
    color: $color-deep-blue-100;
    box-shadow: inset 0 -2px 0 $color-deep-blue-25;
    font-size: inherit;
    padding-bottom: 3px;
}
.sortButton{
    &:hover,
    &.hover,
    &:focus {
        .sortButtonText{
            box-shadow: inset 0 -2px 0 $color-deep-blue-100;
        }
    }
    &:focus {
        color: $color-black;
    }
}
.sortButtonIconContainer{
    margin-left: 10px;
    align-self: center;
    display: flex;
    width: 16px;
}
.sortButtonIcon{
    
    transition: all 0.3s ease-in-out;
    

    .descending &{
        transform: rotate(180deg);
    }
}
.count {
    border-bottom: solid 1px #bcbcbc;
    font-size: 30px;
    margin: 0;
    padding: 10px 0;
}

.noresults {
    td {
        padding: 0;

        > div {
            margin: 0;
        }
    }
}
