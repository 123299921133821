@import "../../style/vars/_all";

$self: '.cancel-button';

:global {
    .cancel-button {
        $self: &;
        color: $color-deep-blue-100;
        box-shadow: none;
        border: none;
        font-size: 16px;
        line-height: 1.45;
        cursor: pointer;
        background: none;
        text-align: left;

        @media all and ($breakpoint-framework-width) {
            font-size: 18px;
        }

        
        &:hover,
        &:focus-visible {
            border-bottom: none;
            box-shadow: none;

            #{$self}__text {
                border-color: transparent !important;
            }
        }

        &__text {
            display: inline;
            border-bottom-width: 2px;
            border-bottom-style: solid;
            border-color: $color-blue-border;
            transition: border-color 0.3s ease-in-out;
            vertical-align: middle;
    }

        &__icon {
            display: inline-block;
            vertical-align: middle;
            width: 15px;
            height: 15px;
            margin: 2px 0 0 8px;
            fill: $color-deep-blue;
        }
    }
}