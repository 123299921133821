@import "../../style/vars/_all";

.title {
    margin: 0 0 $spacing-m;
}

.image {
    display: block;
    border: solid 2px $color-white;
    box-shadow: 0 15px 23px rgba($color-box-shadow, .46);
    max-width: 100%;
    margin: 0 auto $spacing-m;

    @media all and (min-width: 550px) {
        float: right;
        margin: 0 0 $spacing-l $spacing-l;
        max-width: 150px;
    }

    @media all and ($breakpoint-framework-width) {
        max-width: 260px;
        margin: 0 0 $spacing-xl $spacing-xl;
    }
}

.tags,
.intro {
    margin-bottom: $spacing-m;
}

.contacts {
    h4 {
        margin: 0 0 $spacing-s;
    }
}

.attachment {
    margin: $spacing-m (-$spacing-s-mobile) 0;

    > * {
        margin: $spacing-s-mobile;
    }

    button,
    a {
        min-width: 0;
    }

    @media all and (min-width: 800px) {
        display: inline-flex;
        align-items: center;
        margin: $spacing-l (-$spacing-s) 0;

        > * {
            margin: 0 $spacing-s;
        }
    }
}
