@import "../../style/vars/_all";

:global {
    .zoom-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        cursor: pointer;
        background: $color-black-transparent-50;
        border: none;
        transition: all 200ms;

        &:hover {
            transform: scale(1.1);
        }

        &__icon {
            max-height: 30px;
            max-width: 30px;
            fill: $color-white;
        }
    }
}
