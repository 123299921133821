@import "../../style/vars/_all";

$filter-item-indent: 40px;

:global {
    .select-filters-panel {
        $self: &;

        &__list {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 0 30px;
            margin-top: 45px;

            @media all and ($breakpoint-framework-width) {
                flex-direction: row;
                margin-top: 0;
                margin-bottom: -30px;
            }

            &_hidden {
                display: none;

                @media all and ($breakpoint-framework-width) {
                    display: flex;
                }
            }
        }

        &__expander {
            min-width: 144px;
            font-size: 16px;
            padding: 14px 16px;
            background: none;
            text-align: left;
            color: $color-deep-blue-100;
            @include shadowed-box;

            &:hover,
            &.hover,
            &:focus {
                border-color: $color-deep-blue-100 !important;
            }
        }

        &__item {
            &:not(:last-child) {
                margin-bottom: 20px;
            }

            @media all and ($breakpoint-framework-width) {
                width: calc((100% - #{$filter-item-indent}) / 2);
                margin-bottom: 30px !important;

                #{$self}_in-customs-quotas & {
                    width: calc((100% - #{$filter-item-indent}) / 4);
                }
            }
        }
    }
}