.employee-block-large {
    color: $color-white;
    padding: 0 $spacing-s;
    margin: 0 0 $spacing-l;

    > .image {
        display: none;
    }

    .employee {
        padding: $spacing-m $spacing-sm;
        background-color: $color-sea-blue-100;

        .intro,
        .contact-text,
        .employee-card {
            margin: 0 0 $spacing-m;
        }
    }

    .actions {
        max-width: 100%;
        margin: (-$spacing-s) (-$spacing-s) 0;

        a {
            margin: $spacing-s $spacing-s 0;

            &.shortcut {
                display: inline-block;
                white-space: nowrap;
            }
        }
    }

    @media all and (min-width: $breakpoint-card-single) {
        display: flex;
        padding: 0 $spacing-l;
        max-width: $framework-max-width + ($spacing-l * 2);
        margin: 0 auto $spacing-l;

        .employee {
            flex: 1 0 50%;
            max-width: 50%;
            padding: $spacing-l;

            .employee-card {
                margin: 0 0 $spacing-l;
            }
        }

        > .image {
            display: block;
            flex: 1 0 50%;
            max-width: 50%;
            height: auto;
        }
    }
}
