$card-mobile-size: 6.25rem;
$hover-transition: 350ms cubic-bezier(0.165, 0.84, 0.44, 1);

.external-site-link-block {
    height: 100%;
    position: relative;
    display: flex;
    color: $color-deep-blue-100;
    text-decoration: none;
    margin-bottom: $spacing-l;

    .content {
        width: 100%;
        display: flex;
        order: 2;
        padding: $spacing-s;
        flex-direction: column;
        justify-content: space-between;
        font-weight: 300;

        header {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            h2 {
                margin: 0;
                @include list-item-header();
            }

            p {
                margin: 0;
            }

            .text {
                display: none;
            }
        }

        span {
            display: inline-block;
            margin: $spacing-s 0 0;
            word-wrap: break-word;

            svg {
                fill: $color-deep-blue-100;
                width: 10px;
                height: 10px;
                margin-left: 7px;
                margin-bottom: 1px;
                vertical-align: middle;
            }
        }
    }

    .image {
        position: relative;
        flex: 0 0 auto;
        height: $card-mobile-size;
        width: $card-mobile-size;
        background-color: $color-mountain-gray-50;

        .fluid-image {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    &:not(.no-medium) {
        @media all and (min-width: $breakpoint-card-single) {
            border-right: none;

            .content {
                position: relative;
                order: 0;
                flex: 0 0 auto;
                width: 50%;
                padding: $spacing-sm;

                h2 {
                    margin-bottom: $spacing-s;
                }

                .text {
                    display: block;
                }
            }

            .image {
                flex: 0 0 auto;
                width: 50%;
                height: auto;
                z-index: 0;
                overflow: hidden;
                min-height: 11.875rem; // IE11 Fix
            }

            .fluid-image {
                transform: scale(1.05);
                transition: transform $hover-transition;
            }

            &.hover,
            &:hover,
            .list-item a:focus & {
                z-index: $z-index-card-hover;

                .content {
                    &:after {
                        border-left-color: $color-sea-blue-100;
                    }
                }

                .fluid-image {
                    transform: translateZ(0) scale(1.01);
                }

                &:after {
                    opacity: 1;
                }
            }
        }

        @include hoverable-card();
    }

    &:not(.no-large) {
        @media all and (min-width: $breakpoint-card-small) {
            .content {
                padding: $spacing-m;
            }

            .image {
                min-height: 18.125rem;
            }
        }
    }

    @media all and (max-width: $breakpoint-card-single-max) {
        h2 {
            @include typo(body);
        }
    }

    aside & {
        min-height: $spacing-l !important;

        .image {
            order: 1 !important;
            flex: 0 0 auto !important;
            width: $spacing-xl !important;
            min-height: $spacing-xl !important;
        }

        .content {
            order: 2 !important;
            flex: 1 !important;
            max-width: 100% !important;
            padding: $spacing-sm !important;

            &:after {
                display: none;
            }
        }

        h2 {
            @include typo(body);
            margin-bottom: 5px !important;
        }

        .text {
            display: none !important;
        }

        span {
            margin-top: 0 !important;
        }
    }
}
