@import "../../style/vars/_all";
@import "../../style/shared-components-styles/market-insight-styles";

:global {
    .market-insight-link-with-description-block {
        $self: &;
        margin-bottom: 50px;

        @media all and ($breakpoint-framework-width) {
            margin-bottom: 70px;
        }

        &__heading {
            @include market-insight-sidebar-item-heading;
        }

        &__intro {
            @include market-insight-sidebar-item-intro;
        }

        &__link-holder {
            margin-top: $spacing-sm;
        }

        &__link {
            body & {
                font-size: 16px;
                font-weight: 300;

                @media all and ($breakpoint-framework-width) {
                    font-size: 18px;
                }
            }
        }
    }
}
