@import "../shared-components-styles/market-insight-styles";
@import "../shared-components-styles/list-page-styles";

.market-insight-report-list-page {
    $self: &;

    &__header {
        @include list-page-header;
    }
    
    &__heading {
        @include list-page-heading;

        #{$self}__header & {
            margin: 0!important;
            padding: 0!important;
        }
    }

    &__intro {
        @include container;
        margin: 10px 0 24px;

        @media all and ($breakpoint-framework-width) {
            margin: 35px 0 65px;
        }
    }

    &__content {
        @include container;
        margin-bottom: 50px;

        @media all and (min-width: $breakpoint-mobile) {
            margin-bottom: 90px;
        }
    }
}