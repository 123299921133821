@import "../../style/vars/_all";

:global {
    .market-insight-qa-group-block {
        @include container-narrow;
        margin-bottom: 40px;

        &__heading{
            font-size: 24px;
            line-height: 1.55;
            font-weight: 500;
            margin-bottom: 16px;
        }

        &__list{
            margin: 0;
            list-style: none;
            padding: 0;

            &-item{
                margin: 0;
                padding: 0;
                border-bottom: 2px solid $color-sea-blue-45;

                &:last-child{
                    border-bottom: none;
                }
            }
        }
    }
}
