@import "../../style/vars/_all";

.card {
    padding: 10px $spacing-s;
    background-color: $color-cool-gray-25;
    color: $color-deep-blue-100;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.expired {
        background-color: $color-white;
        box-shadow: inset 0 0 0 2px $color-cool-gray-25;
    }

    header {
        word-break: break-word;
        margin: 0 0 $spacing-s;
    }

    h2 {
        @include list-item-header();

        @media all and (max-width: 619px) {
            h2 {
                font-size: 1rem;
            }
        }
    }

    @media all and (min-width: 620px) {
        padding: $spacing-m;
        min-height: 290px;

        header {
            margin-bottom: $spacing-m;
        }
    }
}

.expiredTitle {
    text-decoration: line-through;
}

a.card {
    @include hoverable-card();
}

.meta {
    margin-top: auto;
}

.date {
    font-weight: 500;
}
