@import "../../style/vars/_all";

%small-image {
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.card {
    display: flex;
    align-items: center;

    header {
        order: 2;
    }

    &.negative {
        color: $color-white;

        a {
            @extend %link-white;
        }
    }

    .image {
        position: relative;
        order: 1;
        border-radius: 50%;
        overflow: hidden;
        background-color: rgba($color-mountain-gray-100, 0.25);
    }

    .readMoreLink {
        margin-top: 10px;
        display: inline-block;
    }
}

.email {
    word-break: break-all;
}

.employee {
    h2, h3, h4 {
        font-size: inherit;
        line-height: inherit;
        font-weight: 500;
        margin: 0;

        a {
            font-weight: normal;
        }
    }

    .image {
        @extend %small-image;

        @media all and (min-width: 50rem) {
            flex: 0 0 $spacing-l;
            width: $spacing-l;
            height: $spacing-l;
        }

        aside &,
        :global(.latest-chronicles-block) & {
            @extend %small-image;
        }
    }
}

.marketgroup {
    h2 {
        margin: 0;
    }

    .image {
        flex: 0 0 60px;
        width: 60px;
        height: 60px;
        margin-right: 10px;
    }

    @media all and ($breakpoint-framework-width) {
        h2 {
            margin: 0;
        }

        .image {
            flex: 0 0 120px;
            width: 120px;
            height: 120px;
            margin-right: $spacing-m;
        }
    }
}

.boardMemberHeader {
    h2 {
        margin: 0;
    }
}

.unorderedlist {
    margin: 0 -10px;
    display: flex;
}

.contactList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.list {
    margin: 10px 20px 0 0;
}

.pressTopContact {
    display: flex;
    flex-direction: column;

    @media all and (min-width: 768px) {
        flex-direction: row;
    }

    .image {
        flex: 0 0 100%;
        width: 100%;
        height: auto;
        align-self: stretch;
        border-radius: 0;
        
        @media all and (max-width: 767px) {
            &:before{
                width: 100%;
                content: "";
                padding: 62% 0 0;
                display: block;
            }
        }
        

        @media all and (min-width: 768px) {
            flex: 0 0 50%;
            width: 50%;
            min-height: 200px;
        }
    }
    .header_pressTopContact {
        align-self: flex-start;
        flex: 0 0 50%;
        padding: 20px;

        @media all and ($breakpoint-framework-width) {
            padding: 70px 75px 55px;
        }
    }

    .pressTopContactName {
        font-size: 24px;
        line-height: 34px;
        font-weight: 400;
        margin: 0;

        @media all and ($breakpoint-framework-width) {
            font-size: 29px;
            line-height: 34px;
        }
    }

    .pressTopContactTitle {
        margin-top: 10px;
        @media all and ($breakpoint-framework-width) {
            font-size: 20px;
            line-height: 34px;
        }
    }

    .intro_pressTopContact {
        margin-top: 15px;
        font-size: 17px;
        line-height: 28px;
        max-height: 84px;
        min-height: 56px;
        overflow: hidden;
        position: relative;

        &:after {
            position: absolute;
            display: block;
            content: "";
            height: 28px;
            top: 56px;
            left: 0;
            width: 100%;
            background: linear-gradient(rgba($color-white, 0), $color-white);
        }

        @media all and ($breakpoint-framework-width) {
            min-height: 84px;
            max-height: 134px;
            margin-top: 30px;

            &:after {
                top: 106px;
            }
        }
    }

    .pressTopContactButtons{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-top: 20px;
        justify-content: center;

        @media all and (min-width: 768px) {
            padding-top: 20px;
            justify-content: flex-start;
        }

    }

    .downloadImageLink_pressTopContact,
    .readMoreLink_pressTopContact {
        @include tag;
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 15px;
        border: 0;
        box-shadow: none;
        background: $color-deep-blue-100;
        color: white;
        align-self: flex-start;
        

        @media all and (max-width: 1200px) {
            min-width: 145px;
            margin-left: 15px;
            margin-right: 15px;
        }

        @media all and ($breakpoint-framework-width) {
            margin-top: 30px;
        }
    }
    .downloadImageLink_pressTopContact{
        border: 2px solid $color-deep-blue-100;
        background: white;
        color: $color-deep-blue-100;
        
        @media all and (min-width: 768px) {
            margin-right: 0;
            margin-left: 0;
        }
    }

    .readMoreLink_pressTopContact {
        @media all and (min-width: 768px) {
            margin-left: 0;
            margin-right: 30px;
        }
    }
}
