@import "../../style/vars/_all";

$rootClass: ".link-tabs";

:global {
    #{$rootClass} {
        margin-bottom: 34px;

        @media all and ($breakpoint-framework-width) {
            margin-bottom: 20px;
        }

        &__container {
            @include container;
        }

        &__list {
            font-size: 16px;
            display: flex;
            flex-wrap: wrap;
            border-bottom: 1px solid $color-cool-gray-50;

            @media all and ($breakpoint-framework-width) {
                font-size: 20px;
            }
        }

        &__item {
            &:not(:last-child) {
                margin-right: 20px;

                @media all and ($breakpoint-framework-width) {
                    margin-right: 75px;
                }
            }
        }

        &__link {
            position: relative;
            display: block;
            padding: 8px 0;

            @media all and ($breakpoint-framework-width) {
                padding: 11px 0;
            }

            &:after {
                content: '';
                display: none;
                position: absolute;
                left: 0;
                right: 0;
                bottom: -2px;
                height: 3px;
                background-color: $color-deep-blue;
            }

            &_active {
                font-weight: 700;
            }

            &:hover,
            &:focus,
            &_active {
                &:after {
                    display: block;
                }
            }

            &:focus {
                outline: auto;
            }

            body & {
                color: $color-deep-blue;

                &, &:hover {
                    box-shadow: none;
                }
            }
        }
    }
}