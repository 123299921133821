@import "../../style/vars/_all";

.item {
    > td {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.reports {
    display: flex;
    margin: 0 (-$spacing-s-mobile);
    padding: 0 0 $spacing-s-mobile;
    flex-wrap: wrap;
}

.report {
    margin: 0 $spacing-s-mobile $spacing-sm;
}

.title {
    margin: 0 0 $spacing-s-mobile;

    span {
        font-weight: 400;

        &:before {
            content: "- ";
        }
    }
}

.noAccessReportsDescription {
    margin: 0 0 $spacing-m;
}
