@import "../../style/vars/_all";
$height: 40px;

:global {
    .link,
    a.link {
        &_theme_external {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: $height;
            border-radius: calc($height / 2);
            background-color: rgba($color-modal, 0.7);
            color: $color-white !important;
            padding: 0 50px 0 $spacing-sm;
            cursor: pointer;
            position: relative;
            box-shadow: none !important;

            &:after {
                position: absolute;
                top: 50%;
                right:  20px;
                content: "";
                display: inline-block;
                background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjxzdmcgd2lkdGg9IjIwcHgiIGhlaWdodD0iMjBweCIgdmlld0JveD0iMCAwIDIwIDIwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPg0KICAgIDx0aXRsZT5FeHRlcm5hbDwvdGl0bGU+DQogICAgPGcgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+DQogICAgICAgIDxwb2x5Z29uIGZpbGw9IiNmZmZmZmYiIHBvaW50cz0iMTcgNCA4IDQgMTAuNSA2LjUgMiAxNSAxMCAxNSAxNC40NyAxMC40NyAxNyAxMyAxNyA0IiAvPg0KICAgIDwvZz4NCjwvc3ZnPg0K");
                background-size: contain;
                width: 20px;
                height: 20px;
                transform: translateY(-50%);
            }

            &:hover,
            &:focus{
                text-decoration: none;
                border: none;
            }
        }

        &_theme_shortcut {
            color: $color-deep-blue;
            text-decoration: none;
            border: none;
            box-shadow: none;
            font-size: 18px;
            outline: none;

            &:after {
                content: "⟶";
                margin-left: $spacing-s;
            }
            
            .link__text{
                @include link-underlined;
            }

            &:hover,
            &:focus-visible {
                border: none;
                box-shadow: none;
                .link__text{
                    @include link-underlined-hover;
                }
            }
        }
    }
}
