:global {
    .fluid-image {
        position: relative;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        overflow: hidden;

        > figure {
            display: none !important;
        }

        > img {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
        }
    }
}
