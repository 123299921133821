.editorial-block {
    margin: 0 0 $spacing-m;

    header {
        margin: 0 0 $spacing-s;
    }

    aside & {
        position: relative;
        padding: $spacing-sm;
        background-color: $color-sea-blue-65;
        margin: 0 0 ($spacing-m + $decal-small-height);

        header {
            h2 {
                @include typo(h4);
            }
        }

        @include typo("small-nf");

        > *:first-child { margin-top: 0; }
    }
}
