@import "../shared-components-styles/market-insight-styles";

.statistics-page {
    &__heading {
        @include market-insight-page-heading;

        @media all and ($breakpoint-framework-width) {
           margin-bottom: 45px;
        }
    }
}