.cta-block {
    color: $color-white;
    margin: $spacing-m 0;

    @media print {
        display: none;
    }

    &.has-text {
        padding: $spacing-sm;
        background-color: $color-sea-blue-100;
        text-align: center;

        @media all and ($breakpoint-article-large) {
            padding: $spacing-l;
        }
    }

    p, h3 {
        margin-bottom: $spacing-m;
    }


    aside & {
        @include typo("small-nf");

        &.has-text {
            padding: $spacing-m $spacing-sm $spacing-sm;
        }

        margin: 0 0 $spacing-l;

        h3 {
            @include typo(h4);
        }

        a.button {
            @include typo(h4);
            width: 100%;
            min-width: initial;
            min-height: 50px;
        }
    }
}
