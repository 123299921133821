@import "../../style/vars/_all";

:global{
    .country-page-sign-up-for-newsletters-block{
        @include container-wide;

        &__container{
            background: $color-sea-blue-15;
            padding: 24px;
            display: flex;
            flex-direction: column;

            @media all and ($breakpoint-framework-width) {
                padding: 52px 72px;
            }
        }

        &__intro{
            text-align: center;
            font-size: convert-to-rem(18px);
            line-height: convert-to-rem(26px);
            font-weight: 400;
            color: $color-deep-blue;
        }

        &__link{
            margin: 16px auto 0;
            align-self: center;
            text-align: center;
            @media all and ($breakpoint-framework-width) {
                margin-top: 23px;
            }
        }
    }   
}