@import "../../style/vars/_all";
@import "../../style/shared-components-styles/market-insight-styles";

$list-item-indent: 40px;
$list-item-indent-tablet: 25px;
$list-item-indent-mobile: 15px;

:global {
    .market-insight-links-block {
        $self: &;
        margin: 35px 0;
        padding: 25px 0 55px;
        background-color: $color-sea-blue-15;

        @media all and ($breakpoint-framework-width) {
            padding: 60px 0 133px;
            margin: 60px 0 62px;
        }

        &__container {
            @include container;
            overflow: hidden;
        }

        &__content {
            margin-top: 32px;

            @media all and ($breakpoint-framework-width) {
                margin-top: 48px;
            }
        }

        &__heading {
            @include market-insight-section-heading;
        }

        &__cta-links {            
            margin-bottom: 32px;

            @media all and ($breakpoint-framework-width) {
                margin-bottom: 48px;
            }
        }

        &__link {
            text-decoration: underline;

            #{$self}__cta-item:hover & {
                text-decoration: none;
            }
        }

        &__columns {
            @media all and ($breakpoint-framework-width) {
                display: flex;
                flex-wrap: wrap;
                margin-right: -#{$list-item-indent};
                margin-bottom: -#{$list-item-indent};
            }
        }

        &__column {
            @media all and ($breakpoint-framework-width-max) {
                &:not(:last-child) {
                    margin-bottom: 25px;
                }
            }

            @media all and ($breakpoint-framework-width) {
                margin-right: $list-item-indent;
                margin-bottom: 42px;
                flex: 0 0 calc((100% / 3) - #{$list-item-indent} - 15px);
                font-size: 22px;
            }
            
            &-heading {
                font-size: 18px;
                line-height: 1.4;
                font-weight: 500;
                margin-bottom: 20px;

                @media all and ($breakpoint-framework-width) {
                    font-size: 24px;
                    margin-bottom: 27px;
                }
            }

            &-item {
                color: $color-deep-blue;
                &:not(:last-child) {
                    margin-bottom: 29px;

                    @media all and ($breakpoint-framework-width) {
                        margin-bottom: 31px;
                    }
                }
            }

            &-link {
                font-size: 16px;
                line-height: 1.7;
                color: inherit!important;

                @media all and ($breakpoint-framework-width) {
                    font-size: 20px;
                }
            }
        }
    }
}
