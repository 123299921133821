.fm-s  { @include fluid((margin: ($spacing-s-mobile, $spacing-s))) }
.fm-m  { @include fluid((margin: ($spacing-m-mobile, $spacing-m))) }
.fm-l  { @include fluid((margin: ($spacing-l-mobile, $spacing-l))) }
.fm-xl { @include fluid((margin: ($spacing-xl-mobile, $spacing-xl))) }

.fmx-s  { @include fluid((margin-left: ($spacing-s-mobile, $spacing-s), margin-right: ($spacing-s-mobile, $spacing-s))) }
.fmx-m  { @include fluid((margin-left: ($spacing-m-mobile, $spacing-m), margin-right: ($spacing-m-mobile, $spacing-m))) }
.fmx-l  { @include fluid((margin-left: ($spacing-l-mobile, $spacing-l), margin-right: ($spacing-l-mobile, $spacing-l))) }
.fmx-xl { @include fluid((margin-left: ($spacing-xl-mobile, $spacing-xl), margin-right: ($spacing-xl-mobile, $spacing-xl))) }

.fmy-s  { @include fluid((margin-top: ($spacing-s-mobile, $spacing-s), margin-bottom: ($spacing-s-mobile, $spacing-s))) }
.fmy-m  { @include fluid((margin-top: ($spacing-m-mobile, $spacing-m), margin-bottom: ($spacing-m-mobile, $spacing-m))) }
.fmy-l  { @include fluid((margin-top: ($spacing-l-mobile, $spacing-l), margin-bottom: ($spacing-l-mobile, $spacing-l))) }
.fmy-xl { @include fluid((margin-top: ($spacing-xl-mobile, $spacing-xl), margin-bottom: ($spacing-xl-mobile, $spacing-xl))) }

.fmt-s  { @include fluid((margin-top: ($spacing-s-mobile, $spacing-s))) }
.fmt-m  { @include fluid((margin-top: ($spacing-m-mobile, $spacing-m))) }
.fmt-l  { @include fluid((margin-top: ($spacing-l-mobile, $spacing-l))) }
.fmt-xl { @include fluid((margin-top: ($spacing-xl-mobile, $spacing-xl))) }

.fmb-s  { @include fluid((margin-bottom: ($spacing-s-mobile, $spacing-s))) }
.fmb-m  { @include fluid((margin-bottom: ($spacing-m-mobile, $spacing-m))) }
.fmb-l  { @include fluid((margin-bottom: ($spacing-l-mobile, $spacing-l))) }
.fmb-xl { @include fluid((margin-bottom: ($spacing-xl-mobile, $spacing-xl))) }

.fml-s  { @include fluid((margin-left: ($spacing-s-mobile, $spacing-s))) }
.fml-m  { @include fluid((margin-left: ($spacing-m-mobile, $spacing-m))) }
.fml-l  { @include fluid((margin-left: ($spacing-l-mobile, $spacing-l))) }
.fml-xl { @include fluid((margin-left: ($spacing-xl-mobile, $spacing-xl))) }

.fmr-s  { @include fluid((margin-right: ($spacing-s-mobile, $spacing-s))) }
.fmr-m  { @include fluid((margin-right: ($spacing-m-mobile, $spacing-m))) }
.fmr-l  { @include fluid((margin-right: ($spacing-l-mobile, $spacing-l))) }
.fmr-xl { @include fluid((margin-right: ($spacing-xl-mobile, $spacing-xl))) }

.fp-s  { @include fluid((padding: ($spacing-s-mobile, $spacing-s))) }
.fp-m  { @include fluid((padding: ($spacing-m-mobile, $spacing-m))) }
.fp-l  { @include fluid((padding: ($spacing-l-mobile, $spacing-l))) }
.fp-xl { @include fluid((padding: ($spacing-xl-mobile, $spacing-xl))) }

.fpx-s  { @include fluid((padding-left: ($spacing-s-mobile, $spacing-s), padding-right: ($spacing-s-mobile, $spacing-s))) }
.fpx-m  { @include fluid((padding-left: ($spacing-m-mobile, $spacing-m), padding-right: ($spacing-m-mobile, $spacing-m))) }
.fpx-l  { @include fluid((padding-left: ($spacing-l-mobile, $spacing-l), padding-right: ($spacing-l-mobile, $spacing-l))) }
.fpx-xl { @include fluid((padding-left: ($spacing-xl-mobile, $spacing-xl), padding-right: ($spacing-xl-mobile, $spacing-xl))) }

.fpy-s  { @include fluid((padding-top: ($spacing-s-mobile, $spacing-s), padding-bottom: ($spacing-s-mobile, $spacing-s))) }
.fpy-m  { @include fluid((padding-top: ($spacing-m-mobile, $spacing-m), padding-bottom: ($spacing-m-mobile, $spacing-m))) }
.fpy-l  { @include fluid((padding-top: ($spacing-l-mobile, $spacing-l), padding-bottom: ($spacing-l-mobile, $spacing-l))) }
.fpy-xl { @include fluid((padding-top: ($spacing-xl-mobile, $spacing-xl), padding-bottom: ($spacing-xl-mobile, $spacing-xl))) }

.fpt-s  { @include fluid((padding-top: ($spacing-s-mobile, $spacing-s))) }
.fpt-m  { @include fluid((padding-top: ($spacing-m-mobile, $spacing-m))) }
.fpt-l  { @include fluid((padding-top: ($spacing-l-mobile, $spacing-l))) }
.fpt-xl { @include fluid((padding-top: ($spacing-xl-mobile, $spacing-xl))) }

.fpb-s  { @include fluid((padding-bottom: ($spacing-s-mobile, $spacing-s))) }
.fpb-m  { @include fluid((padding-bottom: ($spacing-m-mobile, $spacing-m))) }
.fpb-l  { @include fluid((padding-bottom: ($spacing-l-mobile, $spacing-l))) }
.fpb-xl { @include fluid((padding-bottom: ($spacing-xl-mobile, $spacing-xl))) }

.fpl-s  { @include fluid((padding-left: ($spacing-s-mobile, $spacing-s))) }
.fpl-m  { @include fluid((padding-left: ($spacing-m-mobile, $spacing-m))) }
.fpl-l  { @include fluid((padding-left: ($spacing-l-mobile, $spacing-l))) }
.fpl-xl { @include fluid((padding-left: ($spacing-xl-mobile, $spacing-xl))) }

.fpr-s  { @include fluid((padding-right: ($spacing-s-mobile, $spacing-s))) }
.fpr-m  { @include fluid((padding-right: ($spacing-m-mobile, $spacing-m))) }
.fpr-l  { @include fluid((padding-right: ($spacing-l-mobile, $spacing-l))) }
.fpr-xl { @include fluid((padding-right: ($spacing-xl-mobile, $spacing-xl))) }
