.notification {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    padding: calc(#{$spacing-s} / 2) 0;
    color: $color-deep-blue-100;

    &.wysiwyg {
        @extend %typo-framework;
    }

    &.notification-low {
        box-shadow: inset 0 -1px 0 $color-cool-gray-25;
    }

    &.notification-medium {
        background-color: $color-sea-green-50;
    }

    &.notification-high {
        background-color: $color-seafood-50;
    }

    .notification-link,
    .notification-text {
        padding: calc(#{$spacing-s} / 2) $spacing-sm;
    }

    .notification-text {

        p:last-child {
            margin-bottom: 0;
        }

        a {
            color: $color-deep-blue-100;
            box-shadow: inset 0 -2px 0 $color-deep-blue-100;
        }
    }

    @media all and ($breakpoint-framework-width) {
        .notification-link,
        .notification-text {
            padding-left: $spacing-l;
            padding-right: $spacing-l;
        }
    }

    @media all and ($breakpoint-wide-l) {
        $width: $framework-max-width + ($spacing-l*2);
        $padding: calc(50% - (#{$width} / 2));
        padding-left: $padding;
        padding-right: $padding;
    }
}
