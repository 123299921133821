@import "../../style/vars/_all";

:global {
    .popup {
        &__header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;

            @media all and ($breakpoint-framework-width) {
                margin-bottom: 30px;
            }
        }
    
        &__container {
            display: inline-block;
            vertical-align: middle;
            max-width: 100%;
            padding: 15px;
            position: relative;
            text-align: left;
        
            @media all and ($breakpoint-framework-width) {
                padding: 50px;
            }
        }
    
        &__close-btn {
            display: flex;
            align-items: center;
            margin-left: auto;
            border: none;
            background: none;
            cursor: pointer;
            color: $color-white;
            position: relative;
    
            &-icon {
                fill: $color-white;
                width: 20px;
                height: 20px;

                @media all and ($breakpoint-framework-width) {
                    width: 28px;
                    height: 28px;
                }
            }
        }
    
        &__content {
            width: $popup-container-width;
            max-width: 100%;
        }
    }

    .popup__root .ReactModal {
        &__Body--open {
            overflow: hidden!important;
        }
    
        &__Overlay {
            overflow-y: auto;
            background-color: $color-black-transparent!important;
            z-index: $z-index-modal;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow-y: auto;
            text-align: center;
            opacity: 0;
            transition: all 500ms ease-in-out;
    
            &--after-open {
                opacity: 1;
            }
    
            &--before-close {
                opacity: 0;
            }
    
            &:before {
                content: '';
                display: inline-block;
                vertical-align: middle;
                height: 100%;
            }
        }
    
        &__Content {
            display: inline-block;
            vertical-align: middle;
            max-width: 100%;
            padding: 25px;
            position: relative;
            text-align: left;
            margin: auto;
            position: static!important;
            border: none!important;
            padding: 0!important;
            overflow: visible!important;
            border-radius: 0!important;
            outline: none;
            border: none;
        }
    }
}




