@import "../../style/vars/_all";

:global {
    .prioritized-markets-block {
        $self: &;
        z-index: 1;
        @include list-items;

        &__heading {
            @include list-item-header;
            margin-bottom: 15px;
        }

        &__card {
            flex: 0 0 100%;
            position: relative;
        }

        #{$self}__intro {
            height: auto !important;
            max-height: none!important;
            margin-bottom: 10px;
            line-height: 1.1875rem;

            @media all and (min-width: $breakpoint-card-single) {
                margin-bottom: 15px;
            }

            @media all and (min-width: 1300px) {
                padding-top: 10px;
                font-size: 18px;
                margin-bottom: 28px;
                line-height: 1.8rem;
            }

            &:after {
                bottom: auto;
                top: 1.1875rem;

                @media all and (min-width: $breakpoint-card-single) {
                    top: calc(1.1875rem * 3);
                }

                @media all and (min-width: 1300px) {
                    top: calc(1.8rem * 4);
                    height: 1.8rem !important;
                }
            }
        }

        #{$self}__text-content {
            position: static !important;

            @media all and (min-width: $breakpoint-card-single) {
                &:after {
                    left: calc(50% - 1px) !important;
                }
            }

            @media all and (min-width: 1300px) {
                padding: 60px 100px 60px 60px !important;
            }
        }

        &__link {
            font-size: 12px;
            color: inherit !important;
            text-decoration: none !important;

            @media all and (min-width: $breakpoint-card-single) {
                font-size: 14px;
            }

            @media all and (min-width: 1300px) {
                font-size: 18px;
            }

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                content: "";
                z-index: 10;
            }

            &:focus {
                text-decoration: underline;
            }
        }

        &__card:hover &__link {
            color: white;
        }
    }
}
