@import "../../style/vars/_all";

.ctaBlock {
    padding: 25px 0;
    background-color: $color-cool-gray-25;

    @media all and (min-width: 768px) {
        padding: 45px 0;
    }
}

.heading {
    font-size: 24px;
    line-height: 34px;
    font-weight: 500;
}

.ctaBlockWrapper {
    padding: 0 20px;
    box-sizing: content-box;
    margin: auto;

    @media all and ($breakpoint-framework-width) {
        padding: 0 $spacing-l;
    }

    @media all and ($breakpoint-wide-l) {
        $padding: calc(50% - (#{$framework-max-width} / 2));

        padding-left: $padding;
        padding-right: $padding;
    }
}

.ctaBlockContainer {
    max-width: 700px;
}

.buttonsHolder {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
}

.button {
    margin-bottom: 15px;
    &:not(:last-child) {
        margin-right: 20px;
    }
}
