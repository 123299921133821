@import "../../style/vars/_all";

$tooltip-icon-size: 16px;

:global {
    .tooltip {
        position: relative;
        cursor: pointer;

        &__content {
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            background-color: $color-deep-blue;
            font-size: 16px;
            line-height: 1.5;
            padding: 16px 40px 16px 16px;
            width: 325px;
            max-height: 128px;
            overflow-y: auto;
            margin-top: 3px;
            color: $color-white;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            z-index: 1;

            &_position-top {
                bottom: calc(100% + 5px);
                top: auto;
            }
        }

        &__close-btn {
            position: absolute;
            top: 16px;
            right: 16px;
            width: $tooltip-icon-size;
            height: $tooltip-icon-size;
            background: none;
            border: none;
            cursor: pointer;
        }

        &__close-icon {
            width: 100%;
            height: 100%;
            fill: $color-white;
        }

        &__trigger {
            background: none;
            border: none;
            cursor: pointer;

            &:focus-visible {
                opacity: 0.6;
            }
        }
    }
}

