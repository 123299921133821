%heading {
    .subscription {
        margin-top: 10px;
    }

    @media all and ($breakpoint-framework-width) {
        display: flex;

        .subscription {
            margin-top: 5px;
            margin-left: $spacing-s;
        }
    }
}

.list-page-header .heading,
body.article > main > article .title .title-wrapper .heading {
    @extend %heading;
}

