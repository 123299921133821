@import "../../style/vars/_all";

.title {
    display: block;
    font-weight: 500;
    margin: 0 0 $spacing-s;
}

.employee {
    margin: 0 0 $spacing-s;
}

.seeAllLinkHolder {
    margin: 0 0 $spacing-s;
}

.seeAllLink {
    &, &:hover {
        box-shadow: none !important;
    }
}
