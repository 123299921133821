@import "../../style/vars/_all";

$rootClass: ".image-block";

:global {
    #{$rootClass} {
        @include page-block;
        padding: 0;
        max-width: $medium-container-width;

        &__caption {
            margin: 10px auto 0;
            max-width: $content-width;
            padding: 0 $spacing-sm;
            box-sizing: content-box;

            @media all and ($breakpoint-framework-width) {
                margin-top: 20px;
            }
        }
    }

    #{$rootClass}_in-rich-text {
        @include block-indents-inside-rich-text;
        #{$rootClass}__caption {
            padding: 0;
        }
    }
}
