@import "../../style/vars/_all";
@import "../../style/shared-components-styles/country-page-styles";

:global {
    .country-page-webinars-block {
        $self: &;
        background-color: $color-background-light;
        padding: 48px 0;
        margin-bottom: 80px;

        @media (min-width: $breakpoint-ipad) {
            padding: 56px 0;
        }

        &__columns{
            @include container-wide;
            display: flex;
            flex-direction: column;
            margin-bottom: 0;

            @media (min-width: $breakpoint-ipad) {
                flex-direction: row;
                justify-content: space-between;
            }
        }

        &__column{
            margin-bottom: 46px;

            &:last-child{
                margin-bottom: 0;
            }

            @media (min-width: $breakpoint-ipad) {
                flex: 0 0 calc(50% - 26px);
                margin-bottom: 0;
                
                &_single{
                    flex: 0 0 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    #{$self}__column-group{
                        flex: 0 0 50%;
                    }

                    #{$self}__column-group + .country-page-webinars-block__column-group{
                        padding-left: 40px;
                    }

                    #{$self}__item-date{
                        margin-bottom: 20px;
                    }

                    #{$self}__see-all-link{
                        margin-top: 24px;
                    }
                }
            }
        }

        &__heading{
            font-size: convert-to-rem(18px);
            line-height: convert-to-rem(26px);
            font-weight: 400;
            margin: 0 0 16px;
        }

        &__item{
            &-heading{
                font-size: convert-to-rem(32px);
                line-height: convert-to-rem(40px);
                font-weight: 700;
                margin: 0 0 10px;
            }

            &-video,
            &-image{
                margin-bottom: 30px;
                @media (min-width: $breakpoint-ipad) {
                    margin-bottom: 16px;
                    #{$self}__column_single &{
                        margin-bottom: 0;
                    }
                }
            }

            &-date,
            &-location{
                display: block;
                font-size: convert-to-rem(16px);
                line-height: convert-to-rem(24px);
                font-weight: 300;
                margin: 0 0 20px;

                @media (min-width: $breakpoint-ipad) {
                    margin-bottom: 6px;
                }
            }

            &-intro{
                margin-top: 14px;
            }

            &-link{
                color: $color-deep-blue;

                &:hover,
                &:focus-visible{
                    text-decoration: underline;
                }
            }
        }

        &__see-all-link{
            margin: 30px 0 0;
            display: inline-flex;

            @media (min-width: $breakpoint-ipad) {
                margin-top: 16px;
            }
        }
    }
}