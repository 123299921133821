@import "../../style/vars/_all";

.title {
    margin: 0 0 $spacing-m;
}

.image {
    display: block;
    border: solid 2px $color-white;
    box-shadow: 0 15px 23px rgba($color-box-shadow, .46);
    max-width: 100%;
    margin: 0 auto $spacing-m;

    @media all and (min-width: 550px) {
        float: right;
        margin: 0 0 $spacing-l $spacing-l;
        max-width: 150px;
    }

    @media all and ($breakpoint-framework-width) {
        max-width: 260px;
        margin: 0 0 $spacing-xl $spacing-xl;
    }
}

.species,
.intro {
    margin-bottom: $spacing-m;
}

.attachment {
    margin: $spacing-l 0 0;

    a {
        margin: 0 100% $spacing-s 0;
    }

    @media all and (min-width: 700px) {
        a {
            margin-right: 0;

            + a {
                margin: 0 0 0 $spacing-m;
            }
        }
    }
}
