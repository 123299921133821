@import "../../style/vars/_all";

.pagination {
    display: flex;
    max-width: $framework-max-width;
    margin: 0 auto $spacing-sm;
    flex-wrap: wrap;

    &.negative {
        .page {
            background-color: $color-white;
        }
    }

    @media all and (min-width: $breakpoint-card-single) {
        margin-bottom: $spacing-l;
    }
}

.paginationIcon {
    max-width: 20px;
    max-height: 15px;
    margin-left: 5px;

    &.reverse {
        margin-left: 0;
        margin-right: 5px;
        transform: rotate(180deg);
        transform-origin: center;
    }
}

.hide {
    display: none;
}

.button {
    min-width: auto !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.page {
    min-width: 200px;

    @media all and ($breakpoint-framework-width) {
        order: 2;
    }

    @media all and ($breakpoint-framework-max) {
        flex: 1 0 100%;
    }
}

.prev {
    margin: 10px 10px 0 0;

    span {
        display: none;

        &:before {
            content: " ";
        }
    }

    @media all and ($breakpoint-framework-width) {
        order: 1;
        margin-top: 0;
    }

    @media all and ($breakpoint-framework-max) {
        &.noNext {
            flex: 1;
            margin-right: 0;

            span {
                display: inline;
            }
        }
    }
}

.next {
    order: 3;
    margin-left: 10px;

    @media all and ($breakpoint-framework-max) {
        margin: 10px 0 0;
        flex: 1;
    }
}
