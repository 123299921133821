@import "../../style/vars/_all";
@import "../../style/shared-components-styles/_customs-quotas-styles";
@import "../../style/shared-components-styles/_list-page-styles";

:global {
    .customs-quotas-table {
        $self: &;
        @include customs-quotas-panel;

        position: relative;
        min-height: 300px;

        @media all and ($breakpoint-framework-width) {
            padding-bottom: $spacing-l;
        }

        &__container {
            @include container;
        }

        &__content {
            @include customs-quotas-panel-content;
        }

        &__heading {
            @include customs-quotas-panel-heading;
        }

        &__filters {
            margin-top: $spacing-l;
        }

        &__table {
            margin-top: $spacing-m;
            text-align: left;
            border-collapse: collapse;
            background-color: $color-white;
            min-width: 1300px;

            @media all and ($breakpoint-framework-width) {
                min-width: 0;
            }

            &-container {
                overflow-x: auto;
            }

            &-row {
                #{$self}__table-row {
                    &:last-child {
                        #{$self}__table-cell {
                            padding-bottom: 0;
                        }
                    }
                }
            }

            &-cell {
                padding: 16px 12px;
                vertical-align: top;
                font-weight: 400;
                font-size: 15px;
                line-height: 1.45;
                border-bottom: 1px solid $color-deep-blue;
                word-break: break-word;

                @media all and ($breakpoint-wide-l) {
                    font-size: 16px;
                }

                &:not(.customs-quotas-table__table-cell_inner) {
                    &:first-child {
                        padding-left: 16px;
                        width: 13%;
                    }
    
                    &:nth-child(2) {
                        width: 10.5%;
                    }
    
                    &:nth-child(3) {
                        width: 11.5%;
                    }
    
                    &:last-child {
                        width: 65%;
                        padding-right: 16px;
                    }
                }

                #{$self}__table-cell & {
                    padding-top: 0;
                    border-bottom: none;

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;
                    }
                }

                &_inner {
                    &:first-child {
                        width: 13.5%;
                    }
    
                    &:nth-child(2) {
                        width: 16.5%;
                    }
    
                    &:nth-child(3) {
                        width: 16%;
                    }

                    &:nth-child(4) {
                        width: 11%;
                    }

                    &:nth-child(5) {
                        width: 15%;
                    }

                    &:nth-child(6) {
                        width: 15%;
                    }
    
                    &:last-child {
                        width: 13%;
                        padding-right: 16px;
                    }
                }
            }

            &-head {
                background-color: $color-deep-blue;
                color: $color-sea-blue-15;

                #{$self}__table-cell_inner {
                    padding-bottom: 0;
                }
            }
        }

        &__credits {
            margin-top: $spacing-m;
            text-align: center;

            &-text {
                &:not(:last-child) {
                    margin-bottom: $spacing-s;
                }
            }
        }

        &__error{
            @include list-page-error;
        }

        &__loading{
            @include list-page-loading;
        }

        &__error{
            @include list-page-error;
        }

        &__icon {
            width: 16px;
            height: 16px;
  
        }

        &__info-icon {
            display: inline-block;
            vertical-align: middle;
            margin-right: 6px;
        }
    }
}
