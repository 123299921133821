$generate: (
'deep-blue-100': $color-deep-blue-100,
'deep-blue-75': $color-deep-blue-75,
'deep-blue-50': $color-deep-blue-50,
'deep-blue-25': $color-deep-blue-25,
'deep-blue': $color-deep-blue,
'sea-blue-100': $color-sea-blue-100,
'sea-blue-75': $color-sea-blue-75,
'sea-blue-50': $color-sea-blue-50,
'sea-blue-25': $color-sea-blue-25,
'sea-blue': $color-sea-blue,
'sky-blue-100': $color-sky-blue-100,
'sky-blue-75': $color-sky-blue-75,
'sky-blue-50': $color-sky-blue-50,
'sky-blue-25': $color-sky-blue-25,
'sky-blue': $color-sky-blue,
'sea-green-100': $color-sea-green-100,
'sea-green-75': $color-sea-green-75,
'sea-green-50': $color-sea-green-50,
'sea-green-25': $color-sea-green-25,
'sea-green': $color-sea-green,
'seafood-100': $color-seafood-100,
'seafood-75': $color-seafood-75,
'seafood-50': $color-seafood-50,
'seafood-25': $color-seafood-25,
'seafood': $color-seafood,
'mountain-gray-100': $color-mountain-gray-100,
'mountain-gray-75': $color-mountain-gray-75,
'mountain-gray-50': $color-mountain-gray-50,
'mountain-gray': $color-mountain-gray
);

@mixin generate-color-classes {
    @each $name, $color in $generate {
        .color-#{$name} { color: $color; }
        .bg-#{$name} { background-color: $color; }
    }
}

@include generate-color-classes;
