@import "../../style/vars/_all";

:global {
    .media-caption {
        font-size: 14px;
        line-height: 1.5;
    
        @media all and ($breakpoint-framework-width) {
            font-size: 16px;
        }
    }
}
