@import "../../style/vars/_all";

:global{
    .country-page-section-heading-block{
        @include container-wide;
        margin: 45px auto;

        @media (min-width: $breakpoint-ipad) {
            margin: $spacing-l auto;
        }

        &__heading{
            font-size: convert-to-rem(40px);
            line-height: convert-to-rem(48px);
            margin: 0;
            font-weight: 700;
        }

        &__intro{
            margin-top: 10px;
            font-size: convert-to-rem(18px);
            line-height: convert-to-rem(26px);
        }

        &__heading,
        &__intro{
            max-width: 650px;
        }
    }
}