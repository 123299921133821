@import "../shared-components-styles/market-insight-styles";
@import "../shared-components-styles/list-page-styles";

.market-insight-thematic-report-list-page {
    $self: &;

    &__preamble {
        background-color: $color-background-light;
        padding: 24px 0 45px;
        margin-bottom: 25px;

        @media all and ($breakpoint-framework-width) {
            padding: 90px 0 80px;
            margin-bottom: 40px;
        }
    }

    &__header {
        @include list-page-header;
    }

    &__heading {
        @include list-page-heading;

        body & {
            margin-top: 0;
        }
    }

    &__intro {
        @include container;
        margin: 10px 0 24px;

        @media all and ($breakpoint-framework-width) {
            margin: 35px 0 65px;
        }
    }

    &__cta-links {
        @include container;
        margin-top: 40px;

        @media all and ($breakpoint-framework-width) {
            margin-top: 80px;
        }
    }

    &__content {
        @include container;
        margin-bottom: 50px;

        @media all and (min-width: $breakpoint-mobile) {
            margin-bottom: 90px;
        }
    }
}