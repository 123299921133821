@import "../../style/vars/_all";

$size: $spacing-l;
$arrow-size: $spacing-sm;

@keyframes hint {
    0% { transform: translateY(0px); }
    40% { transform: translateY(20px); }
    60% { transform: translateY(20px); }
    100% { transform: translateY(0px); }
}

.hint {
    font-family: sans-serif;
    position: fixed;
    bottom: $spacing-m;
    z-index: $z-index-scrollhint;
    left: 50%;
    margin-left: -#{calc($size / 2)};
    background-color: $color-sea-blue-65;
    border: none;
    border-radius: 50%;
    width: $size;
    height: $size;
    box-shadow: $shadow-icon;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    will-change: transform;
    cursor: pointer;
    animation: hint 1500ms ease-in-out;
    animation-delay: 6000ms;

    @media print {
        display: none;
    }

    svg {
        width: $arrow-size;
        height: $arrow-size;
        fill: $color-white;
        transform: rotate(180deg);
    }

    &:hover {
        transform: scale(1.16);
    }

    @include focus-outline(2px, 2px, $color-deep-blue);
    transition: all 200ms ease-in-out;
}
