@import "../../style/vars/_all";
@import "../../style/shared-components-styles/country-page-styles";

$self: '.country-page-link-button';

:global {
    .country-page-link-button {
        display: inline-flex;
        color: white !important;
        background: $color-deep-blue;
        padding: 14px 14px 12px;

        &:hover,
        &:focus-visible{
            #{$self}__text{
                border-color: white;
            }
        }
        
        @media all and ($breakpoint-framework-width) {
            padding: 14px 24px 12px;
        }

        &__text {
            display: inline;
            font-size: convert-to-rem(18px);
            line-height: convert-to-rem(28px);
            font-weight: 400;
            border-bottom-width: 2px;
            border-bottom-style: solid;
            transition: border-color 0.3s ease-in-out;
            padding: 0;
            border-color: transparent;
        }

        &__icon {
            display: block;
            width: 16px;
            height: 16px;
            margin-left: 8px;
            align-self: center;
        }
    }
}