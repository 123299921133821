@import "../../style/vars/_all";

:global {
    .latest-chronicles-block {
        $self: &;
        @include list-items(negative);
        justify-content: space-between;
        position: relative;

        &_theme_short-view {
            padding: 30px 0 30px;
        }

        &__heading {
            flex: 0 0 auto;
            width: 50%;
            margin-bottom: 30px;
            font-weight: 500;
        }

        &__see-all-link {
            @include see-all;
            align-self: flex-start;

            &:hover,
            &:focus {
                box-shadow: none !important;
            }

            #{$self}_theme_short-view & {
                position: absolute;
                float: left;
                bottom: 0;
                margin: 0;
                font-size: 14px;
            }
        }

        &__items {
            width: 100%;
            flex: 0 0 100%;
            flex-direction: row;
            display: flex;
            flex-wrap: wrap;
        }

        &__item {
            flex: 0 0 100%;
            border-left: 2px solid $color-cool-gray-25;
            padding: 0 20px;
            margin: 0 0 20px;
            overflow: hidden;

            #{$self}_theme_short-view & {
                padding: 0 10px;
                border-left-width: 1px;
            }

            @media all and (min-width: 480px) {
                flex: 0 0 50%;
            }

            @media all and (min-width: 1200px) {
                flex: 0 0 25%;
            }

            #{$self}_theme_short-view & {
                @media all and (min-width: 1200px) {
                    flex: 0 0 calc(100%/3);
                }
            }
        }

        &__chronicle {
            &-heading {
                margin: 0 0 5px;
                font-size: 18px;
                line-height: 1.4;
                font-weight: 500;

                #{$self}_theme_short-view & {
                    font-size: 14px;
                    font-weight: bold;
                }
            }
            &-intro {
                margin: 0 0 20px;
                font-size: 12px;
                line-height: 1.43;

                display: block;
                display: -webkit-box;
                max-width: 100%;
                line-clamp: 3;
                -webkit-line-clamp: 3;
                box-orient: vertical;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;

                #{$self}_theme_short-view & {
                    margin-bottom: 0;
                }
            }

            &-link {
                color: $color-deep-blue-100;

                &:hover,
                &:focus{
                    text-decoration: underline;
                }
            }
        }
    }
}
