@import "../../style/vars/_all";
@import "../../style/shared-components-styles/country-page-styles";

:global{
    .country-page-latest-reports-and-publications-block{
        @include container-wide;
        display: flex;
        flex-direction: column;

        &__heading{
            @include country-page-block-heading;
        }

        &__items{
            display: flex;
            flex-direction: column;
            margin: 0;
            padding: 0;

            @media (min-width: $breakpoint-ipad) {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }

        &__item{
            display: flex;
            flex-direction: column;
            margin: 0 0 54px;
            &:last-child{
                margin-bottom: 0;
            }

            @media (min-width: $breakpoint-ipad) {
                flex: 0 0 calc(100% / 3 - 20px);
                max-width: 380px;
                margin-bottom: 0;
            }

            @media all and ($breakpoint-framework-width) {
                flex: 0 0 calc(100% / 3 - 60px);
                max-width: none;
            }

            &-published-date{
                margin-top: 7px;
                
                @media (min-width: $breakpoint-ipad) {
                    margin-top: 12px;
                }
            }

            &-text{
                margin-top: 20px;

                @media (min-width: $breakpoint-ipad) {
                    margin-top: 20px;
                }
            }
        }

        &__previous-publications-link{
            align-self: flex-end;
            margin-top: 38px;

            @media (min-width: $breakpoint-ipad) {
                margin-top: 32px;
            }
        }
    } 
}