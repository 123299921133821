$item-half-width: calc(50% - 26px);

@mixin country-page-block-heading {
    color: $color-deep-blue;
    font-size: convert-to-rem(32px);
    line-height: convert-to-rem(40px);
    margin: 0 0 32px;
    font-weight: 700;

    @media (min-width: $breakpoint-ipad) {
        margin-bottom: 34px;
    }
}

