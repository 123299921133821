$article-width: 1030px;
$aside-width: 280px + $spacing-l;
$aside-margin: $spacing-l;

body.section-page > main {
    .employees-list {
        padding: $spacing-sm;

        @media all and (min-width: $breakpoint-card-single) {
            padding: $spacing-l;
        }
    }

    .employees-items {
        margin: 0 (-$spacing-sm);

        > div {
            padding: 0 $spacing-sm;
        }

        @media all and (min-width: 700px) {
            display: flex;
            flex-wrap: wrap;

            > div {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }

        @media all and (min-width: $breakpoint-card-single) {
            > div {
                flex: 0 0 calc(100% / 3);
                max-width: calc(100% / 3);
            }
        }
    }
}
