@import "../../style/vars/_all";

:global {
    .market-insight-scroll-to-reports-block {
        $self: &;
        @include container;
        margin-bottom: 15px;
        min-height: 66px;

        @media all and ($breakpoint-framework-width) {
            min-height: 38px;
        }

        &__panel {
            display: flex;

            @media all and ($breakpoint-framework-width) {
                justify-content: flex-end;
                margin-right: 8px;
            }
        }

        &__label {
            font-size: convert-to-rem(16px);
            line-height: 1.3;
            font-weight: 700;
            margin-right: 17px;
            white-space: nowrap;

            @media all and ($breakpoint-framework-width) {
                margin-right: 10px;
            }
        }

        &__items {
            margin: 0;
            list-style: none;
            padding: 0;

            @media all and ($breakpoint-framework-width) {
                display: flex;
                flex-wrap: wrap;
            }
        }

        &__item {
            &:not(:last-child) {
                margin-bottom: 10px;
            }

            @media all and ($breakpoint-framework-width) {
                &:not(:last-child) {
                    margin-right: 24px;
                    margin-bottom: 0;
                }
            }
        }

        &__link {
            #{$self} & {
                font-size: 16px;
                font-weight: 400;
            }
        }
    }
}
