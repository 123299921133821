@import "../shared-components-styles/market-insight-styles";

.market-insight-front-page {
    &__heading {
        @include market-insight-page-heading;
    }

    &__intro {
        @include market-insight-page-intro;
    }
}