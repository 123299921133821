@import "../../style/vars/_all";

.content {
    padding: $spacing-l $spacing-sm $spacing-sm;
    background: $color-white;

    header {
        margin: 0 0 $spacing-m;

        :global(.h2) {
            margin: 0 0 $spacing-sm;
        }
    }

    .steps {
        position: absolute;
        top: $spacing-sm;
        right: $spacing-sm;
    }

    @media all and (min-width: 560px) {
        padding: $spacing-sm;
    }

    @media all and ($breakpoint-framework-width) {
        padding: $spacing-l;

        .steps {
            top: $spacing-l;
            right: $spacing-l;
        }
    }
}

.image {
    background-color: $color-sea-blue-25;
    min-height: 352px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media (min-width: 960px) {
        background-size: cover;
    }

    img {
        display: block;
        margin: auto;
        opacity: 0;
    }
}

.orders {
    border-collapse: collapse;
    width: 100%;
    margin: 30px 0;

    tr {
        border-bottom: solid 2px $color-cool-gray-25;
    }

    th,
    td {
        padding: 20px 8px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    th {
        text-align: left;
        padding-top: 0;
        padding-bottom: 0;
    }


    td {
        &:first-child {
            text-align: center;
        }

        &:nth-child(2) {
            width: 99%;
        }
    }

    .amount {
        @media all and (min-width: 560px) {
            white-space: nowrap;

            :global(.wysiwyg) {
                margin-left: $spacing-s;
            }
        }
    }

    input[type=number] {
        width: 70px;

        @media all and (min-width: 560px) {
            width: 90px;
        }
    }

    img {
        display: block;
        max-width: 30px;
        max-height: 40px;
        margin: 0 auto;

        @media all and (min-width: 560px) {
            max-width: 50px;
            max-height: 60px;
        }
    }

    small {
        display: inline-block;
    }

    a {
        font-size: 14px;
    }
}

.actions {
    width: 100%;
    margin: -5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    > a,
    > button {
        margin: 5px;
    }

    @media all and (min-width: 560px) {
        > a:last-child {
            margin-left: auto;
        }
    }
}

.error {
    display: block;
    background: $color-seafood-50;
    padding: $spacing-s $spacing-sm;
    margin: 0 0 $spacing-sm;
}
