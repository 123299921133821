$input-border-width: 10px;

$select-input-bg: $color-white;
$select-input-border-width: 0px;
$select-input-border-radius: 0px;
$select-padding-horizontal: $spacing-sm;
$select-input-height: 50px;
$select-input-placeholder: $color-input-placeholder;

$select-text-color: $color-deep-blue-100;
$select-option-color: $color-deep-blue-100;
$select-option-focused-color: $color-white;
$select-option-focused-bg: $color-sea-blue-100;
$select-option-selected-color: $color-white;
$select-option-selected-bg: $color-sea-blue-100;

$select-item-color: $color-white;
$select-item-bg: $color-sea-blue-100;

$select-item-gutter: 5px;
$select-item-padding-horizontal: 8px;

.Select {
  $self: &;

  &__control {
    border-radius: 0 !important;
    border: none !important;

    &--is-focused {
      box-shadow: inset $input-border-width 0 0 $color-sea-blue-100 !important;
    }

    &--is-disabled{
      pointer-events:all !important;
      cursor: not-allowed !important;
    }

    .negative & {
      background-color: $color-cool-gray-25 !important;
    }
  }

  &__value-container {
    padding: 0px 20px !important;

    > * {
      padding: 0 !important;
      margin: 0 !important;
    }

    input {
      line-height: 17px !important;
      box-shadow: none !important;

      &:focus-visible {
        color: $color-deep-blue-100 !important;
      }
    }
  }

  &__multi-value {
    background: $color-sea-blue-100 !important;
    margin: 5px 10px 5px 0 !important;

    &,
    * {
      color: #fff !important;
    }
  }

  &__multi-value__remove {
    order: 1;
    border-right: 1px solid #005ba1;
    cursor: pointer;

    &:hover {
      background: #006abb !important;
    }
  }

  &__multi-value__label {
    order: 2;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  &__indicator-separator {
    display: none;
  }

  &__dropdown-indicator {
    background-image: url(../assets/input-dropdown.svg);
    background-size: 15px 8px;
    background-repeat: no-repeat;
    background-position: center;

    * {
      display: none;
    }
  }

  &__menu {
    margin: 0px !important;
    padding: 0px !important;
    border-radius: 0 !important;
    border: none !important;
    box-shadow: 0 15px 23px rgba($color-box-shadow, 0.46) !important;

    * {
      box-shadow: none !important;
      border: 0 !important;
    }

    &-list {
      box-shadow: none !important;
      border: 0 !important;
      padding: 0px !important;
    }
  }

  &__option {
    border-bottom: solid 2px $color-cool-gray-25 !important;

    &:last-child {
      border-bottom: none !important;
    }

    &--is-focused,
    &--is-selected {
      background: $color-sea-blue-100 !important;
      color: #fff !important;
    }

    .negative {
      background-color: $color-white !important;

      &--is-focused {
        background-color: $select-option-focused-bg !important;
        color: $select-option-focused-color !important;
      }

      &--is-selected {
        background-color: $select-option-selected-bg !important;
        color: $select-option-selected-color !important;
      }
    }
  }
}
