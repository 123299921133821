@import "../../style/vars/_all";

.employees {
    padding: ($spacing-sm - $spacing-s-mobile);
    background-color: $color-white;

    &.negative {
        background-color: $color-cool-gray-25;
    }

    :global(.press-contacts-block) &{
        background: none;
    }

    > li {
        padding: $spacing-s-mobile;
    }

    @media (min-width: 60rem) {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        padding: ($spacing-l - $spacing-s-mobile);

        > li {
            width: calc(100% / 2);
        }

        @media (min-width: 90rem) {
            &.multiple {
                > li {
                    width: calc(100% / 3);
                }
            }
        }
    }
}
