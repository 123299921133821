@mixin statistic-link {
    body & {
        color: $color-deep-blue;
        box-shadow: none;
        border-bottom: 1px solid $color-deep-blue;
        font-size: 12px;

        @media all and (min-width: $breakpoint-mobile) {
            font-size: inherit;
        }
        
        &:hover, 
        &:focus {
            border-bottom: none;
            box-shadow: none;
        }

        &_blue-border {
            padding-bottom: 2px;
            border-bottom-width: 2px;
            border-bottom-color: $color-blue-border;
        }
    }
}

@mixin statistic-cell {
    padding: 6px;
    word-wrap: break-word;

    @media all and ($breakpoint-framework-width) {
        padding: 12px;
    }

    &-heading {
        @media all and ($breakpoint-framework-width-max) {
            font-weight: 600;
            margin-bottom: 4px;
        }
    }

    &-item {
        &:not(:last-child) {
            margin-bottom: 7px;
        }
    }
}

@mixin statistic-icon {
    display: inline-block;
    vertical-align: middle;
    margin-left: 4px;
    width: 20px;
    height: 20px;
    color: $color-deep-blue;
    fill: $color-deep-blue;

    @media all and ($breakpoint-framework-width) {
        margin-left: 10px;
        width: 24px;
        height: 24px;
    }

    &_small {
        width: 15px;
        height: 15px;

        @media all and ($breakpoint-framework-width) {
            width: 18px;
            height: 18px;
        }
    }

    &_right-indent {
        margin-left: 0;
        margin-right: 4px;

        @media all and ($breakpoint-framework-width) {
            margin-right: 10px;
        }
    }
}