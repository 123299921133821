@import "../../style/vars/_all";
@import "../../style/shared-components-styles/country-page-styles";

:global{
    .country-page-rich-text-block{
        @include container-wide;

        &__heading{
            @include country-page-block-heading;
        }

        &__text{
            @media (min-width: $breakpoint-ipad) {
                columns: 2;
                column-gap: 40px;
            }
        }
    } 
}