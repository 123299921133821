@import "../../style/vars/_all";

.name {
    margin: 0 0 $spacing-m;
}

.info {
    .info {
        margin: 0 0 $spacing-m;
    }

    .details {
        > div {
            margin: 0 0 $spacing-m;
        }
    }

    @media all and (min-width: 1024px) {
        display: flex;
        flex-wrap: wrap;

        .description {
            flex: 1;
            margin-bottom: 0;
            margin-right: 90px;
        }

        .details {
            flex: 0 0 276px;
        }
    }

}
