.skip-link {
    opacity: 0;
    transition: opacity .25s cubic-bezier(.2,.6,.3,1), transform .25s cubic-bezier(.2,.6,.3,1);
    position: fixed;
    top: 3px;
    left: 3px;
    z-index: 1000;
    transform: translateY(-150%);
    background-color: $color-sea-blue-100;
    color: $color-white;
    padding: 10px;

    &:focus {
        opacity: 1;
        transform: none;
    }
}