@import "../../style/vars/_all";

$predefined-width: 140px;
$daterange-width: 300px;

.label {
    color: $color-input-placeholder;
}

.dropdownWrapper {
    position: absolute;
    right: 0;
    z-index: $z-index-daterange;

    @media all and (min-width: 1440px) {
        right: $spacing-s;
    }
}

.dropdown {
    padding: $spacing-sm;
    box-shadow: 0 15px 23px rgba($color-box-shadow, .46); // TODO: make variable
    background-color: $color-white;
    display: flex;
    flex-wrap: nowrap;

    > div {
        margin-right: $spacing-sm;
        min-width: $daterange-width;
    }
}

.predefined {
    order: 2;
    flex: 0 0 $predefined-width;
    min-width: $predefined-width;

    li + li {
        margin: 10px 0 0;
    }

    a {
        display: inline-flex;
        height: 29px;
        padding: 8px;
        background-color: $color-cool-gray-25;
        border-radius: 2px;
        align-items: center;
        cursor: pointer;

        &.activePreset,
        &.hover,
        &:hover {
            background-color: $color-sea-blue-100;
            color: $color-white;
        }
    }
}

.actions {
    display: flex;
    margin: $spacing-sm 0 0;
    align-items: center;

    a, button {
        flex: 0;
    }
}

.cancel {
    margin-left: 20px;
    font-weight: 500;
}

:global {
/*    .DateRangePicker__picker--portal {
        z-index: $z-index-modal;
        background-color: rgba($color-modal, .8);
    }

    .DateRangePicker {
        display: block;
    }

    .DateRangePickerInput {
        border: none;
        display: block;
        background-color: $color-cool-gray-25;
    }

    .DateInput {
        background-color: $color-cool-gray-25;
    }

    .DateInput__display-text--focused {
        background-color: $color-sea-blue-100;
        color: $color-white; 
        border: none;
    }

    .DateRangePickerInput__clear-dates {
        position: absolute;
        top: 50%;
        right: $spacing-sm;
        padding: 0;
        margin: 0;
        display: block;
        line-height: 1em;
        transform: translateY(-50%);

        svg {
            fill: $color-mountain-gray-100;
            width: 15px;
            height: 15px;
        }
    }

    .DateRangePickerInput__clear-dates:focus, .DateRangePickerInput__clear-dates--hover {
        background: none;
    }

    .CalendarDay {
        border: none;    
        height: 29px;
    }
  
    .CalendarDay--hovered-span,
    .CalendarDay--selected-span.CalendarDay--hovered-span,
    .CalendarDay--selected-start,
    .CalendarDay--selected-end {
        background: $color-sea-blue-100;
        color: $color-white;
        border: none;
    }

    .CalendarDay--selected-span {
        background: $color-deep-blue-100;
        color: $color-white;
        border: none;
    }*/



    /*
    .CalendarMonth__day--selected-start {
       border-radius: 4px 0 0 4px; 
    }
    .CalendarMonth__day--selected-end {
        border-radius: 0 4px 4px 0;
    }
    .js-CalendarMonth__grid {
        tr {
            border-bottom: solid 2px $color-white;
        }
    }
    */
}

// :global {
//     .DateRangePicker {
//         flex: 1;
//         position: relative;
//         user-select: none;
//         min-width: $daterange-width;
//         margin-right: $spacing-sm;
//     }
//
//     .rdr-DateRange {
//         z-index: $z-index-daterange;
//     }
//
//     .DateRangePicker__PaginationArrow {
//         border: 0;
//         cursor: pointer;
//         display: block;
//         height: 35px;
//         outline: none;
//         overflow: hidden;
//         padding: 0;
//         position: absolute;
//         text-align: center;
//         top: 0;
//         white-space: nowrap;
//         width: 35px;
//         z-index: 1;
//     }
//
//     .DateRangePicker__PaginationArrow--previous {
//         left: ($spacing-sm / 2);
//
//         &:after {
//             color: $color-sea-blue-100;
//             content: "←";
//             line-height: 35px;
//         }
//     }
//
//     .DateRangePicker__PaginationArrow--next {
//         right: ($spacing-sm / 2);
//
//         &:after {
//             color: $color-sea-blue-100;
//             content: "→";
//             line-height: 35px;
//         }
//     }
//
//     .DateRangePicker__PaginationArrow:hover {
//         background-color: #ccc;
//     }
//
//     .DateRangePicker__PaginationArrowIcon {
//         display: none;
//     }
//
//     .DateRangePicker__PaginationArrowIcon--is-disabled {
//         opacity: .25;
//     }
//
//     // .DateRangePicker__PaginationArrowIcon--previous {
//     //     right: 11px;
//     // }
//     //
//     // .DateRangePicker__PaginationArrowIcon--next {
//     //     left: 11px;
//     // }
//
//     .DateRangePicker__Month {
//         display: inline-block;
//         position: relative;
//         user-select: none;
//         width: 275px;
//         margin: 0 ($spacing-sm / 2);
//     }
//
//     .DateRangePicker__MonthHeader {
//         font-size: 14px;
//         font-weight: bold;
//         height: 35px;
//         line-height: 35px;
//         position: relative;
//         text-align: center;
//     }
//     .DateRangePicker__MonthHeaderLabel {
//         display: inline-block;
//         position: relative;
//     }
//
//     .DateRangePicker__MonthHeaderSelect {
//         background: #e4e4e4;
//         border: 0;
//         cursor: pointer;
//         display: inline-block;
//         height: 100%;
//         left: 0;
//         margin: 0;
//         opacity: 0;
//         position: absolute;
//         top: 0;
//         width: 100%;
//         z-index: 5;
//     }
//
//     .DateRangePicker__MonthDates {
//         border-collapse: separate;
//         border-spacing: 0 1px;
//         margin: 0;
//         width: 100%;
//     }
//
//     .DateRangePicker__WeekdayHeading,
//     .DateRangePicker__Date {
//         font-size: $typo-small;
//         line-height: 1;
//         padding: 10px 0;
//         text-align: center;
//         width: 14.285714285714286%;
//     }
//
//     .DateRangePicker__WeekdayHeading {
//         font-weight: bold;
//     }
//
//     .DateRangePicker__WeekdayHeading abbr[title] {
//         border-bottom-width: 0;
//         cursor: pointer;
//         font-size: inherit;
//         text-decoration: none;
//     }
//
//     .DateRangePicker__Date {
//         cursor: pointer;
//         overflow: hidden;
//         position: relative;
//     }
//
//     .DateRangePicker__Date:first-child {
//         border-left-width: 1px;
//     }
//
//     // .DateRangePicker__Date--weekend {}
//
//     .DateRangePicker__Date--otherMonth {
//         color: $color-input-placeholder;
//     }
//
//     .DateRangePicker__Date--is-disabled {
//         color: #cdcdd1;
//         cursor: default;
//     }
//
//     .DateRangePicker__Date--is-selected {
//         color: $color-white;
//     }
//
//     .DateRangePicker__Date--is-highlighted {
//         color: $color-white;
//     }
//
//     .DateRangePicker__CalendarDatePeriod {
//         bottom: 0;
//         position: absolute;
//
//         top: 0;
//     }
//
//     .DateRangePicker__CalendarDatePeriod--am {
//         left: 0;
//         right: 50%;
//     }
//
//     .DateRangePicker__CalendarDatePeriod--pm {
//         left: 50%;
//         right: 0;
//     }
//
//     .DateRangePicker__CalendarSelection {
//         background-color: $color-deep-blue-100;
//         bottom: 0;
//         left: 0;
//         position: absolute;
//         right: 0;
//         top: 0;
//     }
//
//     .DateRangePicker__CalendarSelection--inOtherMonth {
//         opacity: .5;
//     }
//
//     .DateRangePicker__CalendarSelection--start {
//         border-bottom-left-radius: 4px;
//         border-right-width: 0;
//         border-top-left-radius: 4px;
//         left: 0;
//
//         background-color: $color-sea-blue-100;
//     }
//
//     .DateRangePicker__CalendarSelection--end {
//         border-bottom-right-radius: 4px;
//         border-left-width: 0;
//         border-top-right-radius: 4px;
//         right: 0;
//
//         background-color: $color-sea-blue-100;
//     }
//
//     .DateRangePicker__CalendarSelection--segment {
//         border-left-width: 0;
//         border-right-width: 0;
//     }
//
//     .DateRangePicker__CalendarSelection--single {
//         border-radius: 4px;
//         left: 0;
//         right: 0;
//     }
//
//     .DateRangePicker__CalendarSelection--is-pending {
//         background-color: $color-sea-blue-100;
//         border-width: 0;
//     }
//
//     .DateRangePicker__CalendarHighlight {
//         bottom: 0;
//         left: 0;
//         position: absolute;
//         right: 0;
//         top: 0;
//     }
//
//     .DateRangePicker__CalendarHighlight--inOtherMonth {
//         opacity: .5;
//     }
//
//     .DateRangePicker__CalendarHighlight--start {
//         border-bottom-left-radius: 4px;
//         border-right-width: 0;
//         border-top-left-radius: 4px;
//         left: 0;
//     }
//
//     .DateRangePicker__CalendarHighlight--end {
//         border-bottom-right-radius: 4px;
//         border-left-width: 0;
//         border-top-right-radius: 4px;
//         right: 0;
//     }
//
//     .DateRangePicker__CalendarHighlight--segment {
//         border-left-width: 0;
//         border-right-width: 0;
//     }
//
//     .DateRangePicker__CalendarHighlight--single {
//         background-color: $color-sea-blue-100;
//         border-radius: 4px;
//         left: 0;
//         right: 0;
//     }
//
//     .DateRangePicker__HalfDateStates {
//         bottom: -50px;
//         left: -50px;
//         position: absolute;
//         right: -50px;
//         top: -50px;
//         -webkit-transform: rotate(30deg);
//         transform: rotate(30deg);
//     }
//
//     .DateRangePicker__FullDateStates {
//         bottom: 0;
//         left: 0;
//         position: absolute;
//         right: 0;
//         top: 0;
//     }
//
//     .DateRangePicker__DateLabel {
//         display: block;
//         position: relative;
//         text-align: center;
//         width: 100%;
//         z-index: 1;
//     }
// }
