@import "../../style/vars/_all";

.topMediaBlock {
    display: flex;
    flex-direction: column;
    max-width: $banner-image-width;
    margin: auto;

    @media all and ($breakpoint-framework-width) {
        align-items: flex-end;
    }
}

.topMediaBlockHolder {
    position: relative;
    width: 100%;
    min-height: 450px;
    [data-edit-mode] & {
        min-height: 300px !important;
    }
}

.topMediaBlockBtn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color: $color-white;
    border: none;
    z-index: 1;

    &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0px;
        height: 0px;
        border-top: 14px solid transparent;
        border-bottom: 14px solid transparent;
        border-left: 18px solid #1D5CA8;
        transition: border-left-color 200ms;
        margin-left: 3px;
    }
}

.bannerImageCaption {
    @extend %typo-sub;
    position: static;
    padding: 10px 20px 0;

    @media all and ($breakpoint-framework-width) {
        padding: 10px 50px 0 0;
    }
}