.center {
    width: 100%;

    .loader {
        margin: auto;
    }
}

.loader {
    display: block;
    animation: rotate 1000ms linear infinite;
    will-change: transform;
}

@keyframes rotate {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(-360deg) }
}
