@mixin fluid-responsive-image() {
    padding: 0px;
    @include full-size;
    background-position: center;
    background-size: cover;
    max-width: none;

    @media print {
        background-image: none!important;
    }

    .responsive-image__img {
        opacity: 0;

        @media print {
            opacity: 1;
            height: 100%;
            object-fit: cover;
        }
    }
}

@mixin fixed-proportion-image($width, $height) {
	height: 0px;
	padding-bottom: calc($height / $width) * 100%;
	//background: none !important;
	position: relative;

	.image__img {
		@include object-fit(cover, center);
		position: absolute;
	}
}

@mixin original-proportions-image($width, $height) {
    padding: 0;
    height: auto;
	max-width: ($width)+px;

    .image__img{
        position: relative;
        height: auto;
        width: auto;
        max-width: 100%;
        max-height: ($height)+px;
        @include object-fit(null);
    }
}