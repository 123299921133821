body.market-insights-page {
    .header-wrapper {
        max-width: 800px;
        padding: 0 20px;
        box-sizing: content-box;
        overflow: hidden;
        margin: 30px 0 40px;
               
        @media all and (min-width: 768px) {
            margin: 60px 0;
        }   

        @media all and ($breakpoint-framework-width) {
            padding: 0 $spacing-l;
        }
    
        @media all and ($breakpoint-wide-l) {
            $padding: calc(50% - (#{$framework-max-width} / 2));
    
            padding-left: $padding;
            padding-right: $padding;
        }
      
    }

    .intro {
        flex: 1;
        padding-right: 40px;
        font-size: 20px;
        line-height: 30px;
    
        @media all and (min-width: 768px) {
            font-size: 28px;
            line-height: 38px;
            padding-right: 90px;
        }
    
        a {
            text-decoration: underline;
            color: inherit;
            
            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }
    
    .heading {
        margin-bottom: 20px;
        @media all and (min-width: 768px) {
            margin-bottom: 50px;
        }
    }
}

