h1, .h1 {
    @include typo(h1);
}

h2, .h2 {
    @include typo(h2);
}

h3, .h3 {
    @include typo(h3);
}

h4, .h4, th {
    @include typo(h4);
}

.lead {
    @include typo(lead);
}

small,
.typo-small {
    @include typo(small);
}

.typo-small-nf {
    @include typo(small-nf);
}

.weight-medium {
    font-weight: 500;
}

.weight-strong {
    font-weight: 700;
}
