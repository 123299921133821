@import "../../style/vars/_all";
@import "../../style/shared-components-styles/market-insight-styles";

:global {
    .market-insight-contact-persons-block {
        $self: &;
        max-width: 386px;
        margin-bottom: 50px;

        @media all and ($breakpoint-framework-width) {
            margin-bottom: 75px;
        }

        &__heading {
            @include market-insight-sidebar-item-heading;
        }

        &__intro {
            @include market-insight-sidebar-item-intro;
        }

        &__see-all-link-holder {
            margin-top: $spacing-sm;
        }
    }

    .market-insight-contact-persons-block-contact {
        $self: &;
        display: flex;

        &:not(:last-child) {
            margin-bottom: 25px;
        }

        &__image {
            flex: 0 0 40px;
            border-radius: 50%;
            overflow: hidden;
        }

        &__info {
            flex: 1;
            padding-left: $spacing-s-mobile;
            font-size: 14px;
            line-height: 1.35;
            font-weight: 300;
            font-style: italic;
        }

        &__position {
            display: block;
        }
    }
}
