.attachment-block {
    margin: 0 0 $spacing-l;

    h2,
    h3 {
        margin: 0 0 $spacing-s;
    }

    li {
        margin: 0 0 $spacing-s;
    }

    a {
        color: $color-sea-blue-100;
        box-shadow: inset 0 -2px 0 $color-sea-blue-25;

        &:hover {
            box-shadow: inset 0 -2px 0 $color-sea-blue-100;
        }
    }

    aside & {
        @include typo(small);

        h3 {
            @include typo(h4);
        }
    }
}
