@import "../../style/vars/_all";

:global {
    .lightbox {
        $self: &;
        position: relative;

        @media all and ($breakpoint-framework-width) {
            &:hover {
                #{$self}__zoom-btn {
                    opacity: 1;
                }
            }
        }
    
        &__zoom {
            &-btn {
                display: none;
                opacity: 0;
                position: absolute;
                right: 30px;
                bottom: 30px;
                @include focus-outline(2px, 2px);

                &:focus-visible {
                    opacity: 1;
                }

                @media all and ($breakpoint-framework-width) {
                    display: inline-flex;
                }

                &_in-popup {
                    display: inline-flex;
                    right: 15px;
                    bottom: 15px;
                    opacity: 1;
                }
            }
        }
    
        &__popup-content-container {
            background-color: $color-cool-gray-25;
            @include framed-block(false);
    
            body & {
                width: 1900px;
            }
        }
    }
}
