@import "../../style/vars/_all";
@import "../../style/vars/full-page-blocks-mixins";

$text-bg: rgba(#fff, 0.8);

:global {
    .full-page-image-block {
        $self: &;
        width: 100%;
        height: 100vh;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        z-index: 2;
        justify-content: center;

        &__text-container {
            position: relative;
            z-index: 2;
            align-self: flex-start;
            align-self: center;
            text-align: center;
            max-width: 1000px;
            padding: 0 20px;
        }

        &__text {
            font-size:16px;
            line-height: 33px;
            color: $color-content-text;
            white-space: pre-line;

            @media all and ($breakpoint-framework-width) {
                font-size: 22px;
                line-height: 50px;
            } 
            &-background{
                @include inline-text-with-bg($text-bg, 6px, 5px, 8px);

                @media all and ($breakpoint-framework-width) {
                    @include inline-text-with-bg($text-bg, 10px, 8px, 10px);
                }
            }
        }
    }
}
