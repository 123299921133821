.quote-block {
    padding-left: $spacing-sm;
    margin: 0 0 $spacing-m (-$spacing-sm);
    box-shadow: inset 8px 0 0 $color-orange;

    cite {
        display: block;
        margin: $spacing-m 0 0;
        font-style: normal;
    }

    @media all and ($breakpoint-article-large) {
        box-shadow: inset 4px 0 0 $color-orange;
        padding-left: $spacing-m;
        margin-left: 0;
    }
}

:global {
    .quote-text {
        font-style: italic;
    }
}
