@import "../../style/vars/_all";

@keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

$easing: 400ms linear;

:global {
    .search-overlay-container {
        position: relative;
        z-index: $z-index-modal + 1;
    }
}

.overlay {
    display: none;
    opacity: 0;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgba($color-modal, .8);
}

.header,
.results {
    z-index: $z-index-modal + 1;
}

.header {
    height: $header-height-s;
    background: $color-white;
    padding: 10px $spacing-sm;

    @media all and ($breakpoint-framework-width) {
        height: $header-height;
        padding: 25px $spacing-l;
    }
}

.wrapper {
    max-width: 1320px;
    margin: auto;
    display: flex;
}

.label {
    position: absolute;
    left: -999em;
}

.input {
    flex: 1;
    display: flex;
    align-items: center;

    svg {
        fill: $color-sea-blue-100;
        margin: 0 1px 0 2px; // To make SVG same width as loader icon
    }

    input {
        font: inherit;
        height: $spacing-m;
        line-height: $spacing-m;
        border: none;
        appearance: none;
        font-size: 18px;
        margin-left: $spacing-s;
        color: $color-deep-blue-100;

        &:focus {
            outline: none;
        }
    }
}

.close {
    flex: 0 0 auto;
    white-space: nowrap;
    text-transform: uppercase;
    margin-left: $spacing-s;
    color: $color-cool-gray-75;
    cursor: pointer;
    background: transparent;
    border: none;

    span {
        display: none;
        margin-right: $spacing-s;
    }

    svg {
        height: 23px;
        width: 23px;
        fill: $color-cool-gray-75;
    }

    &:focus {
        color: $color-deep-blue-100;

        svg {
            fill: $color-deep-blue-100;
        }
    }

    @media all and ($breakpoint-framework-width) {
        span {
            display: inline;
        }
    }
}

.error {
    background-color: $color-cool-gray-25;
    padding: $spacing-m $spacing-sm;

    @media (min-width: 960px) {
        padding: $spacing-l;
    }

    > * {
        max-width: 1320px;
        margin: auto;
    }
}

.searchBtn {
    background: none;
    border: none;

    &:focus,
    &.focus {
        outline: auto!important;
    }
}
