$footer-breakpoint: 60rem;

// Add margin to last element in <main>
// so that the footer doesn't overlap any content
// body > main:after {
//     content: "";
//     display: block;
//     width: 100%;
//     height: $decal-small-height;

//     @media all and (min-width: $footer-breakpoint) {
//         height: $decal-large-height;
//     }
// }



footer.main-footer {
    // @include top-decal($color-deep-blue-100, $breakpoint-header-width);
    position: relative;
    background-color: $color-deep-blue-100;
    color: $color-white;
    font-weight: 300;
    padding-top: 30px;

    @media print {
        display: none;
    }

    @media all and ($breakpoint-framework-width) {
        padding-top: 40px;
    }

    a, .wysiwyg a {
        font-weight: 300;
        @extend %link-white-deep-blue;
        @include focus-outline(2px, 2px);

        &:hover {
            box-shadow: inset 0 -1px 0px $color-white;
        }
    }

    section.top {
        @include container-wide;
        margin-bottom: 0;
        padding-top: $spacing-sm !important;
        padding-bottom: $spacing-sm !important;
        position: relative;
    }

    .to-the-top {
        position: absolute;
        top: 7px;
        right: 0;
        display: flex;
        width: 60px;
        height: 60px;
        background-color: $color-sea-blue-65;
        border-radius: 50%;
        text-align: center;
        align-items: center;
        justify-content: center;
        color: $color-sea-blue-100;
        font-weight: bold;
        transition: transform 200ms linear;
        box-shadow: 0 7px 14px rgba($color-modal, .32);
        border: none;
        cursor: pointer;
        @include focus-outline(2px, 2px);

        &.hover,
        &:hover,
        &:focus-visible {
            transform: scale(1.16);
            box-shadow: none;
        }

        svg {
            height: 19px;
            width: 18px;
            fill: $color-white;
        }
    }

    .footer-content {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .bottom-container{
       background-color: #00184d; 
    }

    section.bottom {
        @include container-wide;
        margin-bottom: 0;
        padding-top: $spacing-s !important;
        padding-bottom: $spacing-s !important;

        .copyright,
        .cookie-information {
            margin-bottom: $spacing-s;
        }
    }

    .logo {
        width: 100%;
        position: relative;
        padding-bottom: $spacing-sm;
        margin-bottom: $spacing-m;
        border-bottom: solid 2px $color-deep-blue-75;
        display: flex;
        align-items: center;

        .image {
            flex: 1;

            img {
                height: 40px;
            }
        }

        .to-the-top {
            flex: 0 0 60px;
        }

        @media all and (min-width: $footer-breakpoint) {
            width: 100%;
            margin-bottom: 35px;
        }
    }

    .language {
        a {
            color: $color-white;
            text-decoration: none;
            &:after { content: none; }
        }

        span {
            opacity: .4;
        }
    }

    .about {
        width: 100%;
        margin: 0 0 $spacing-m;
    }

    .contact {
        order: 2;
        margin: 0 0 $spacing-m;
        width: 100%;

        .button {
            margin-top: $spacing-s;
            display: block;
            @include focus-outline(2px, 2px);

            &:focus-visible {
                background-color: $color-sea-blue-65;
                box-shadow: none;
            }

            @media all and (min-width: 1230px) {
                display: inline-flex;
            }
        }

        a[href^='tel'] {
            color: $color-white;
            box-shadow: none;
            @include focus-outline(2px, 2px);

            &:hover {
                box-shadow: inset 0 -1px 0px $color-white;
            }
        }
    }

    .address {
        order: 4;
        width: 100%;
        p {
            margin-bottom: $spacing-s;

            &.address-info {
                margin-bottom: 25px;
            }
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    .link-list {
        strong {
            display: block;
            margin-bottom: $spacing-s;
            font-family: $font-family;
        }

        li {
            margin: 0 0 7px;
            line-height: 1.2;
        }

        a {
            @extend %link-reverse-shortcut-negative;
            color: $color-white !important;
            box-shadow: none !important;
            @include focus-outline(2px, 2px);
        }
    }

    @media all and (min-width: 620px) {
        section.top {
            display: flex;
            flex-wrap: wrap;

            .about {
                width: 100%;

                ul {
                    display: flex;
                    flex-wrap: wrap;
                }

                li {
                    flex: 0 0 50%;

                    &:nth-child(even) {
                        padding-right: 70px;
                    }
                }

            }

            .contact,
            .address {
                flex: 0 0 50%;
            }

            .about {
                order: 2;
            }

            .address {
                order: 3;
            }

            .contact {
                order: 4;
            }
        }
    }

    @media all and (min-width: 1230px) {
        section.top {
            .logo {
                width: 100%;
                flex: 0 0 100%;
            }

            .about {
                flex: 0 0 50%;
                order: 4;
                margin: 0;
            }

            .contact {
                flex: 0 0 25%;
                order: 3;
                margin: 0;
                padding-right: 10px;
            }

            .address {
                flex: 0 0 25%;
                order: 2;
                margin: 0;
            }
        }

        section.bottom {
            padding-top: 11.5px !important;
            padding-bottom: 11.5px !important;
            display: flex;

            .copyright {
                flex: 0 0 50%;
                margin: 0;
            }

            .cookie-information,
            .language {
                flex: 0 0 25%;
                margin: 0;
            }
        }
    }

}

.environment-friendly-logo{
    clear: both;
    padding: 20px 0 0;
    max-width: 90px;

    img{
        max-height: 60px;
    }

    &__link{
        &,
        &:hover{
            text-decoration: none !important;
            border: none;
            display: inline;
            box-shadow: none !important;
        }

        @include focus-outline(2px, 2px);
    }
}