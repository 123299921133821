.shortcuts-block {
    font-size: 17px;
    line-height: 1.56;

    @media all and ($breakpoint-wide-l) {
        font-size: 18px;
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        padding: $spacing-m;
        padding: $spacing-m $spacing-sm;

        @media all and ($breakpoint-framework-width) {
            padding: $spacing-l $spacing-l $spacing-m;
        }
    
        @media all and ($breakpoint-wide-l) {
            $width: $framework-max-width;
            $padding: calc(50% - (#{$width} / 2));
    
            padding-left: $padding;
            padding-right: $padding;
        }
    }
    &__col {
        width: 100%;
        padding-right: $spacing-sm;
        margin-bottom: $spacing-m;

        @media all and (min-width: 768px) {
            width: 50%;
        }

        @media all and ($breakpoint-framework-width) {
            padding-right: 40px;
            width: 25%;
        }
    }
    &__heading {
        margin-bottom: $spacing-sm;
    }
    &__list {
        &-item {
            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }
}