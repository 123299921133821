@import "../../style/vars/_all";
@import "../../style/vars/_image-mixins";

$rootClass: ".video-block";

:global {
    #{$rootClass} {
		@include page-block;
		padding: 0;		
        max-width: $medium-container-width;

		&__container {
			position: relative;
            box-shadow: $shadow-block;
			background-color: $color-white;
			@include fixed-proportion-image(1200, 630);
    
            @media all and ($breakpoint-framework-width) {
				@include framed-block;
            }

			@media all and ($breakpoint-framework-width) {
				&:hover {
					#{$rootClass}__zoom-btn {
						opacity: 1;
					}
				}
			}
		}

		&__frame-container {
			height: 0;
			position: relative;
			padding-bottom: 56.25%;

			& > div, & > div > div { // Wrapper blocks generated by React-player module https://www.npmjs.com/package/react-player
				width: auto!important;
				height: auto!important;
				@include full-size;
				@include object-fit(cover, center);
			}
		}

		&__popup-content-container {
			background-color: $color-cool-gray-25;
			@include framed-block(false);
		}

		&__play-btn {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			padding: 15px 30px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border-radius: 10px;
			background-color: $color-black-transparent-50;
			border: none;
			cursor: pointer;
			transition: all 200ms;
			height: 64px;
			box-shadow: $shadow-block;
			@include focus-outline(5px, 4px, $color-white, true);

			@media all and ($breakpoint-framework-width) {
				height: 100px;
				padding: 24px 35px;
			}

			&-icon {
				width: 30px;
				height: 34px;

				@media all and ($breakpoint-framework-width) {
					width: 45px;
					height: 52px;
				}
			}

			&-label {
				color: $color-white;
				text-transform: uppercase;
				font-size: 24px;
				font-weight: 400;
				margin-left: 10px;

				@media all and ($breakpoint-framework-width) {
					font-size: 32px;
					margin-left: 15px;
				}
			}
		}

		iframe { // generated by React-player module https://www.npmjs.com/package/react-player
			@include full-size;
		}
    
        &__zoom {
            &-btn{
                opacity: 0;
                position: absolute;
                right: 30px;
                bottom: 30px;
            }
        }

		&__caption {
            margin: 10px auto 0;
            max-width: $content-width;
			padding: 0 $spacing-sm;
			box-sizing: content-box;
  
            @media all and ($breakpoint-framework-width) {
                margin-top: 20px;
            }
        }
	}

	#{$rootClass}_in-rich-text {
        @include block-indents-inside-rich-text;
        #{$rootClass}__caption {
            padding: 0;
        }
    }
}