@import "../../style/vars/_all";
@import "../../style/vars/_mixins";


:global {

    .marketInsight-useful-pages-block{
        @include container;
        display: flex;
        flex-direction: column;
        margin-top: 35px;
        margin-bottom: 60px;

        @media all and (min-width: #{$breakpoint-ipad}) {
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            margin-bottom: 140px;
            margin-top: 80px;
        }

        &__item{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            align-self: flex-start;
            margin-bottom: 36px;
            &:last-child{
                margin-bottom: 0;
            }
            
            @media all and (min-width: #{$breakpoint-ipad}) {
                width: calc((100% - 60px)/3);
                margin: 0 15px;
                &:first-child{
                    margin-left: 0;
                }
                &:last-child{
                    margin-right: 0;
                }
            }

            @media all and ($breakpoint-wide-l) {
                width: calc((100% - 200px)/3);
                margin: 0 15px;
            }

            &-title{
                text-align: center;
                font-weight: 700;
                font-size: 20px;
                line-height: 1.4;
                margin: 0 0 16px;
                color: $color-deep-blue-100;

                @media all and ($breakpoint-framework-width) {
                    font-size: 24px;
                }
            }

            &-intro{
                margin: 0 0 20px;
                @media all and ($breakpoint-framework-width) {
                    margin-bottom: 16px;
                }
            }

            &-icon{
                max-width: 100%;
                max-height: 100%;
                min-width: 10px;
            }

            &-icon-container{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 72px;
                height: 72px;
                overflow: hidden;
                margin: 0 0 16px;

                @media all and ($breakpoint-framework-width) {
                    margin-bottom: 24px;
                }

                &_no-image{
                    display: none;
                    @media all and (min-width: #{$breakpoint-ipad}) {
                        display: flex;
                    }
                }
            }
        }
    }
}