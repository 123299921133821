@import "../../style/vars/_all";

$banner-vertical-padding: 80px;
$banner-vertical-padding-mobile: $spacing-l;
$banner-play-btn-size: 48px;

.banner {
    display: flex;
    position: relative;
    flex: 1;
    max-width: $banner-image-width;
    min-height: 300px;
    margin: auto;
    background-color: $color-deep-blue;
    background-size: cover;
    background-position: center;
    color: $color-white;
    overflow: hidden;

    @media all and ($breakpoint-framework-width) {
        min-height: 448px;
    }
}

.bannerWithText{
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        background: black;
    }
}

.bannerImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.bannerContainer {
    position: relative;
    align-self: center;
    padding: $banner-vertical-padding-mobile $spacing-sm;
    z-index: 2;

    @media all and ($breakpoint-framework-width) {
        padding: $banner-vertical-padding $spacing-l;
        margin-right: auto;
        margin-left: 0;
    }

    .bannerWithVideoWithoutLink & {
        padding-bottom: $banner-vertical-padding-mobile + $banner-play-btn-size + 25px;
    
        @media all and ($breakpoint-framework-width) {
            padding-bottom: $banner-vertical-padding + $banner-play-btn-size + 25px;
        }
    }
}

.bannerContainerBtn {
    margin-top: 25px;
}

.tagline {
    max-width: 660px;
    text-shadow: 0 2px 4px rgba($color-modal, .41);
}

.intro{
    font-weight: 300;
    margin: 25px 0 0;
    max-width: $content-width;
    font-size: 20px;
    line-height: 30px;

    @media all and (min-width: 768px) {
        font-size: 28px;
        line-height: 38px;
        letter-spacing: -0.74px;
    }
    
    &:global(.wysiwyg){
       *{
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

.bannerPlayBtn {
    position: relative;
    z-index: 2;
    width: $banner-play-btn-size;
    height: $banner-play-btn-size;
    position: absolute;
    bottom: $banner-vertical-padding-mobile;
    right: 20px;
    cursor: pointer;
    border: none;
    background: none;
    @include focus-outline(4px, 2px);
    transition: transform 200ms;

    &:hover {
        transform: scale(1.16);
    }

    @media all and ($breakpoint-framework-width) {
        left: 50%;
        right: auto;
        margin-left: -$banner-play-btn-size;
        bottom: $banner-vertical-padding;
    }
}

.bannerPlayBtnIcon {
    width: 100%;
    height: 100%;
}

.hidden {
    @include visually-hidden-no-space;
}