@import "../../style/vars/_all";

:global {
    .breadcrumbs {
        display: flex;

        @media print {
            display: none;
        }
    }

    #top-menu .breadcrumbs {
        @media all and ($breakpoint-header-width-max) {
            display: none;
        }
    }

    // Hide breadcrumbs in article headers on "desktop"
    body main .breadcrumbs {
        margin-bottom: $spacing-s;

        li {
            margin-bottom: $spacing-s;
        }

        @media all and ($breakpoint-header-width) {
            display: none;
        }
    }
}

.breadcrumbs {
    @extend %typo-framework;
    color: $color-white;
    align-self: flex-end;
    font-weight: 300;
    
    ol {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
    }

    &__separator {
        padding: 0 4px;
    }

    .breadcrumbs__link {
        color: $color-white;
        text-decoration: none;
        cursor: pointer;
        box-shadow: none;

        @include link-underlined;
        @include focus-outline(2px, 2px, $color-deep-blue);

        :global(.feature-article-header) & {
            @include link-underlined($color-white);
            @include focus-outline(2px, 2px, $color-white);
        }

        &.active,
        &:hover,
        &:focus-visible {
            @include link-underlined-hover;
        }
    }


    &.positive {
        color: $color-sea-blue-100;

        .breadcrumbs__link {
            color: $color-sea-blue-100;
        }
    }
}

.many {
    @media all and (max-width: 1024px) {
        li:first-child:after {
            content: "\00a0/\00a0";
        }

        li:nth-child(2),
        li:nth-child(3) {
            &:before { content: ""; display: none; }
        }

        li:nth-child(n+3):not(:last-child) {
            display: none;
        }

        .toggler {
            display: block;
        }
    }
}

.toggler {
    display: none;
}
