@import "../../style/vars/_all";
@import "../../style/shared-components-styles/country-page-styles";

:global {
    .country-page-image-video-text-block {
        $self: &;
        @include container-wide;
        margin: 0 auto 80px;

        &__content {
            display: flex;
            flex-direction: column;

            @media (min-width: $breakpoint-ipad) {
                flex-direction: row;
                justify-content: space-between;
            }
        }

        &__media-content{
            margin-bottom: 24px;
        }

        &__media-content,
        &__text-content {
            @media (min-width: $breakpoint-ipad) {
                flex: 0 0 50%;
                margin-bottom: 0;
            }
        }

        &__text-content {
            @media (min-width: $breakpoint-ipad) {
                padding-left: 40px;
            }
        }

        &__heading {
            @include country-page-block-heading;
        }

        &__expand-button {
            font-family: $font-family;
            border: none;
            background: none;
            padding: 0;
            box-shadow: none;
            border: none;
            font-size: convert-to-rem(18px);
            line-height: convert-to-rem(26px);
            cursor: pointer;
            color: $color-deep-blue;
            margin: 16px 0;

            &-text {
                display: inline;
                border-bottom: 2px solid $color-blue-border;
                transition: border-color 0.3s ease-in-out;
                padding: 0 0 2px;
                vertical-align: top;
            }

            &:hover,
            &:focus-visible {
                #{$self}__expand-button-text {
                    border-bottom-color: transparent;
                }
            }

            &-icon {
                width: 16px;
                height: 16px;
                display: inline-block;
                margin-left: 10px;
                margin-top: 6px;
                align-self: flex-start;
                fill: $color-deep-blue;
                transition: transform 0.4s;
                transform: rotate(180deg);
            }
        }

        #{$self}__expand-button_active {
            #{$self}__expand-button-icon {
                transform: rotate(0deg);
            }
        }

        &__expandable-panel {
            overflow: hidden;
            width: 100%;
            max-height: 0px;
            transition: max-height 0.3s;

            &_expanded {
                max-height: 100px;
            }
        }
    }
}