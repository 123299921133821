@import "../../style/vars/_all";

:global {
    .jump-to {
        padding: 0 $spacing-sm 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        font-size: 18px;
        line-height: 1.4;

        @media all and ($breakpoint-header-width) {
            padding: 0 $spacing-l 40px;
        }

        @media all and ($breakpoint-wide-l) {
            $width: $framework-max-width;
            $padding: calc(50% - (#{$width} / 2));

            padding-left: $padding;
            padding-right: $padding;
        }

        &__item{
            margin:0 20px 20px 0;
        }

        &__heading,
        &__link {
            font-size: inherit;
            line-height: inherit;
        }
        &__heading {
            font-weight: bold;
        }

        &__link {
            text-decoration: underline;

            &:hover,
            &:focus{
                text-decoration: none;
            }
        }
    }
}
