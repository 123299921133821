@import "../../style/vars/_all";

:global{
    .country-page-hero{
        position: relative;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        max-width: 1560px;
        margin: 0 auto 80px;

        @media (min-width: $breakpoint-ipad) {
            min-height: 450px;
            padding: 84px 92px;
        }

        &__image{
            position: relative;
            min-height: 293px;
            @media (min-width: $breakpoint-ipad) {
                position: absolute;
                @include full-size;
                z-index: 1;
            }
            
        }

        &__heading{
            margin: 0 0 22px;
            font-size: convert-to-rem(40px);
            line-height: convert-to-rem(48px);
            font-weight: 500;
            font-family: $font-family;

            @media (min-width: $breakpoint-ipad) {
                font-size: convert-to-rem(48px);
                line-height: convert-to-rem(56px);
                margin-bottom: 18px;
            }
        }

        &__intro{
            font-size: convert-to-rem(24px);
            line-height: convert-to-rem(32px);
        }

        &__see-all-link{
            margin: 32px 0 0;

            @media (min-width: $breakpoint-ipad) {
                margin-top: 16px;
            }
        }

        &__text-container{
            z-index: 2;
            position: relative;
            background: white;
            width: 100%;
            padding: 32px 20px 48px;

            @media (min-width: $breakpoint-ipad) {
                max-width: 730px;
                padding: 32px 24px;
            }
        }
    }
}