@import "../../style/vars/_all";

$double-cards: 800px;
$triple-cards: 1400px;

.wrapper {
    margin: 0 0 $spacing-m;
}

.title {
    margin: 0 0 $spacing-m;
}

.people {
    @media all and (min-width: $double-cards) {
        display: flex;
        flex-wrap: wrap;
        margin: 0 (-$spacing-s);
    }
}

.employeeCard {
    padding: $spacing-s 0;

    @media all and (min-width: $double-cards) {
        padding: $spacing-s $spacing-s;
        flex: 0 0 50%;
    }

    @media all and (min-width: $triple-cards) {
        padding: $spacing-m $spacing-s;
        flex: 0 0 calc(100% / 3);
    }
}
