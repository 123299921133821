@import "../../style/vars/_all";

.report {
    position: relative;
    display: block;
    box-shadow: inset 0 0 0 2px $color-sea-blue-25;
    padding: 10px 20px 10px 40px;
    color: $color-white;
    font-weight: 500;

    &:focus,
    &:hover {
        outline: none;
        box-shadow: none;
        background: $color-deep-blue-100;
    }

    svg {
        position: absolute;
        top: 24px;
        left: 24px;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 18px;
        fill: currentColor;
        margin-right: 5px;
    }

    &.closed {
        svg {
            width: 16px;
            height: 14px;
        }
    }
}

.details {
    font-weight: 400;

    &:before {
        content: " ";
    }

    @media all and (max-width: 375px) {
        display: block;
    }
}
