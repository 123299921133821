@import "../../style/vars/_all";
@import "../../style/shared-components-styles/_typography-styles";

:global {
    .poor-text {
        @include base-typography-styles;
        @include content-typography-styles;

        &_in-market-insight-page-intro {
            font-size: 16px;
            line-height: 1.25;
            color: $color-deep-blue;
            font-weight: 400;

            @media all and ($breakpoint-framework-width) {
                font-size: 28px;
                line-height: 1.35;
            }

            a {
                color: inherit;
            }
        }

        &_in-market-insight-calendar-page-intro{
            font-size: 16px;
            line-height: 1.25;
            color: black;
            font-weight: 400;

            @media all and ($breakpoint-framework-width) {
                font-size: 20px;
                line-height: 1.35;
            }

            a {
                color: inherit;
            }
        }

        &_in-market-insight-latest-list-item {
            font-size: 16px;
            line-height: 1.2;
            font-weight: 400;
            color: $color-deep-blue;
        }
        &_in-market-insight-useful-pages-block{
            font-family: $font-family;
            text-align: center;
            color: $color-primary-dark-blue;
            font-weight: 400;
            font-size: 16px;
            line-height: 1.55;

            @media all and ($breakpoint-framework-width) {
                font-size: 20px;
            }
        }

        &_in-market-insight-calendar-block,
        &_in-market-insight-last-publications,
        &_in-market-insight-latest-blog-posts-block,
        &_in-market-insight-links-block {
            font-size: 16px;
            font-weight: 400;
            line-height: 1.6;
            color: $color-deep-blue;

            @media all and ($breakpoint-framework-width) {
                font-size: 18px;
            }
            
             a {
                color: inherit;
            }
        }

        &_in-market-insight-report-list-page-intro,
        &_in-article-list-page-intro,
        &_in-market-insight-statistic-archive-page-intro {
            font-size: 20px;
            font-weight: 400;
            line-height: 1.2;
            color: $color-deep-blue;

            a {
                color: inherit;
            }
        }

        &_in-market-insight-thematic-report-list-page-intro {
            font-size: 20px;
            font-weight: 400;
            line-height: 1.2;
            color: $color-deep-blue;

            @media all and ($breakpoint-framework-width) {
                font-size: 28px;
            }

            a {
                color: inherit;
            }
        }

        &_in-market-insight-report-item,
        &_in-article-list-item {
            font-size: 14px;
            font-weight: 400;
            line-height: 1.2;
            color: $color-deep-blue;

            a {
                color: inherit;
            }

            @media all and ($breakpoint-framework-width) {
                font-size: 20px;
            }
        }

        &_in-country-page-text-cta-image-block,
        &_in-country-page-image-video-text-block{
            font-size: convert-to-rem(18px);
            line-height: convert-to-rem(26px);
            font-weight: 400;
            color: $color-deep-blue;

            a {
                color: inherit;
                box-shadow: inset 0 -1px 0 currentColor;
            }
        }
        
        &_in-market-insight-customs-quotas-intro,
        &_in-customs-quotas-terms-popup {
            font-size: 20px;
            line-height: 1.4;
            color: $color-deep-blue;
            letter-spacing: -0.74px;

            a {
                color: inherit;
                box-shadow: inset 0 -1px 0 currentColor;
            }

            @media all and ($breakpoint-framework-width) {
                font-size: 28px;
            }
        }

        &_in-market-insight-contact-persons-block,
        &_in-market-insight-link-with-description-block,
        &_in-customs-quotas-cta-block,
        &_in-customs-quotas-table {
            font-size: 16px;
            line-height: 1.44;
            color: $color-deep-blue;

            a {
                color: inherit;
                box-shadow: inset 0 -1px 0 currentColor;
            }

            p {
                &:not(:last-child) {
                    margin: 0 0 16px;
                }
            }

            @media all and ($breakpoint-framework-width) {
                font-size: 18px;
            }
        }

        &_in-market-insight-downloadable-statistics-block,
        &_in-market-insight-interactive-reports-block {
            font-size: convert-to-rem(18px);
            line-height: 1.45;
            font-weight: 400;
            color: $color-deep-blue;

            a {
                color: inherit;
                box-shadow: inset 0 -1px 0 currentColor;
            }

            @media all and ($breakpoint-framework-width) {
                font-size: convert-to-rem(20px);
            }
        }
    }
}
