@import "../../style/vars/_all";

$double-cards: 620px;

.searchblock {
    background-color: $color-deep-blue;
    background-size: cover;
    background-position: center;
    color: $color-white;
    height: 100%;
    display: flex;
}

.form {
    align-self: center;
    padding: $spacing-l $spacing-sm;

    @media all and ($breakpoint-framework-width) {
        padding: $spacing-xl $spacing-l;
        max-width: 720px;
        margin-right: 50%;
        margin-left: auto;
    }
}

.tagline {
    text-shadow: 0 2px 4px rgba($color-modal, .41);
    margin: 0 0 $spacing-l;
}

.light {
    color: $color-deep-blue-100;
    text-shadow: none;
}

.searchWrapper {
    @media all and (min-width: 50rem) {
        height: 60px;
    }
}

.searchContainer {
    position: relative;
    background: $color-white;
    z-index: $z-index-search-results;
}

.searchResults {
    position: absolute;
    width: 100%;
    overflow: hidden;
    background-color: $color-cool-gray-25;
    box-shadow: 0 15px 23px rgba($color-box-shadow, .46);
}

.seeAllResults {
    color: $color-deep-blue-100;
    display: inline-block;
    margin: $spacing-s $spacing-sm;
    text-decoration: none;

    &:after {
        content: " ⟶";
    }

    &.hover,
    &:hover {
        color: $color-sea-blue-100;
    }
}

.events {
    margin-left: -1px;
    margin-right: -1px;

    @media all and (min-width: $double-cards) {
        display: flex;
        flex-wrap: wrap;
    }

    > * {
        border: solid 1px $color-white;

        @media all and (min-width: $double-cards) {
            flex: 0 0 50%;
            max-width: 50%;
        }

        @media all and ($breakpoint-framework-width) {
            flex: 0 0 calc(100% / 3);
            max-width: calc(100% / 3);
        }

        @media all and ($breakpoint-wide) {
            flex: 0 0 25%;
            max-width: 25%;
        }
    }
}
