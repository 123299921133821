@import "../../style/vars/_all";

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-modal;
    z-index: $z-index-modal;
    color: $color-white;
    padding: $spacing-m;
    display: flex;
    flex-direction: column;
}

.header {
    flex: 0 0 50px;
    margin-bottom: $spacing-m;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close {
    position: absolute;
    top: $spacing-m;
    right: $spacing-m;
    height: 50px;
    width: 50px;
    border: solid 2px $color-white;
    border-radius: 50%;
    background-color: transparent;
    -webkit-appearance: none;
    cursor: pointer;

    svg {
        max-height: 23px;
        fill: $color-white;
    }

    &:hover,
    &:focus {
        border-color: $color-sea-blue-100;
        background-color: $color-sea-blue-100;
    }
}

.share {
    list-style: none;
}

.youtube {
    flex: 1 1 100%;
    width: 100%;
    height: 100%;
    border: none;
}
