@import "../../style/vars/_all";

$indent-size: 30px;

.navigationAreas {    
    padding: 0 20px;
    box-sizing: content-box;
    overflow: hidden;
    margin: 0 auto 50px;

    @media all and (min-width: 768px) {
        margin-bottom: 134px;
    }

    @media all and ($breakpoint-framework-width) {
        padding: 0 $spacing-l;
    }

    @media all and ($breakpoint-wide-l) {
        $padding: calc(50% - (#{$framework-max-width} / 2));

        padding-left: $padding;
        padding-right: $padding;
    }
}

.navigationAreasWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right: - #{$indent-size};
    margin-bottom: -40px;
}

.navigationAreasColumn {    
    width: 100%;
    padding-right: $indent-size;
    margin-bottom: 40px;

    @media all and (min-width: 560px) {
        width: calc(100% / 2);
    }

    @media all and (min-width: 768px) {
        width: calc(100% / 3);
    }
}