@import "../../style/vars/_all";
@import "../../style/shared-components-styles/market-insight-styles";

$box-size: 80px;
$box-size-rounded: 69px;

:global {
    .date-box {
        $self: &;
        padding: 0 8px;
        background-color: $color-blue-border;
        text-align: center;

        &_upcoming {
            background-color: $color-cool-gray-45;
        }

        &_today{
            background: $color-deep-blue;
        }

        &_view-square {
            width: $box-size;
            height: $box-size;
            line-height: $box-size;

            @media all and ($breakpoint-framework-width) {
                width: 111px;
                height: 95px;
                line-height: 95px;
                font-size: 20px;
            }

            &.date-box_upcoming{
                background: $color-cool-gray-50;
            }
        }

        &_view-rounded {
            border-radius: 50%;
            width: $box-size-rounded;
            height: $box-size-rounded;
            line-height: $box-size-rounded;
        }

        &__text {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
            color: $color-white;
            letter-spacing: -0.01em;
            font-weight: 400;

            #{$self}_upcoming:not(.date-box_view-square) & {
                color: $color-deep-blue;
            }

            @media all and ($breakpoint-framework-width) {
                font-size: 15px;

                #{$self}_view-square &{
                    font-size: 20px;
                }
            }
        }
    }

}

