@import "../../style/vars/_all";
@import "../../style/vars/_mixins";

:global {     
	.read-more {
		&__content {
			&_truncated {
				display: -webkit-box;
				max-width: 100%;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
			&_destroy-on-mobile {
				@media all and ($breakpoint-framework-width-max) {
					display: block!important;
				}
			}
		}

		&__expand-btn {
			font-weight: 500;
			font-size: inherit;
			color: inherit;
			text-decoration: underline;
			background: none;
			border: none;
			cursor: pointer;

			&:hover,
			&.hover,
			&:focus {
				text-decoration: none;
			}
		}
	}
}