@import "../../style/vars/_all";

.steps {
    display: inline-flex;
}

.step {
    display: block;
    width: $spacing-m;
    height: $spacing-m;
    line-height: $spacing-m;
    background: $color-cool-gray-25;
    color: $color-white;
    font-weight: 500;
    border-radius: 50%;
    text-align: center;
    margin: 0 5px 0 0;

    &:last-child {
        margin-right: 0;
    }

    &.active {
        background: $color-deep-blue-100;
    }
}
