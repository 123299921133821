$color-deep-blue-100: #001e64;
$color-deep-blue-75: #40568b;
$color-deep-blue-50: #808fb1;
$color-deep-blue-25: #bfc7d8;
$color-deep-blue: $color-deep-blue-100;

$color-sea-blue-100: #0078d4;
$color-sea-blue-75: #40a1ec;
$color-sea-blue-65: #40a5d8;
$color-sea-blue-50: #80c1f3;
$color-sea-blue-45: #85c9f0;
$color-sea-blue-25: #bfe0f9;
$color-sea-blue-15: #EAF4FA;
$color-sea-blue: $color-sea-blue-100;

$color-sky-blue-100: #64c8ff;
$color-sky-blue-75: #8bd6ff;
$color-sky-blue-50: #b1e3ff;
$color-sky-blue-25: #d8f1ff;
$color-sky-blue-15: #eef9ff;
$color-sky-blue-10: #f3fbff;
$color-sky-blue: $color-sky-blue-100;

$color-sea-green-100: #32C8B9;
$color-sea-green-75: #65d6ca;
$color-sea-green-50: #98e3dc;
$color-sea-green-25: #ccf1ed;
$color-sea-green: $color-sea-green-100;

$color-seafood-100: #E6D77D;
$color-seafood-75: #ece19e;
$color-seafood-50: #f3ebbe;
$color-seafood-25: #f9f5df;
$color-seafood: $color-seafood-100;

$color-mountain-gray-100: #9BA5AF;
$color-mountain-gray-75: #b4bbc3;
$color-mountain-gray-50: #cdd2d7;
$color-mountain-gray-25: #e6e9eb;
$color-mountain-gray: $color-mountain-gray-100;

$color-cool-gray-100: #606060;
$color-cool-gray-75: #595959;
$color-cool-gray-50: #BBBCBC;
$color-cool-gray-45: #D9D9D6;
$color-cool-gray-25: #ececea;
$color-cool-gray-0: #FAFAFA;
$color-cool-gray: $color-cool-gray-100;

$color-orange-100: #e87722;
$color-orange: $color-orange-100;

$color-sub: #696969;

$color-black: #000;
$color-black-transparent-100: rgba(0, 0, 0, 0.8);
$color-black-transparent-50: rgba(0, 0, 0, 0.6);
$color-black-transparent-25: rgba(0, 0, 0, 0.3);
$color-black-transparent: $color-black-transparent-100;
$color-white: #fff;

$color-modal: #02102b;

$color-shadow-icon: #021231;
$color-box-shadow: #141D2F;
$color-primary-dark-blue: #000E4A;

$color-transparent: transparent;

$color-facebook: #3c5a96;
$color-print: #748a8d;
$color-email: #415881;
$color-twitter: #2aa3ef;

$color-error: #FF4552;
$color-error-text: #C3353E;
$color-404-background: #011933;

$color-description-text: #555555;

$color-input-placeholder: $color-sub; // TODO !!

$color-button-disabled-background: $color-mountain-gray-50;
$color-button-disabled-text: #807f7d;

$color-content-text: #333333;
$color-blue-border: #0087CC;
$color-background-light: $color-sea-blue-15;
