@import "../../style/vars/_all";
@import "../../style/shared-components-styles/market-insight-styles";

$list-item-indent: 90px;
$list-item-indent-tablet: 25px;
$list-item-indent-mobile: 15px;

:global {
    .market-insight-calendar-date {
        $self: &;
        position: relative;
        display: flex;
        color: $color-deep-blue;

        &_in-calendar-list-page {
            color: $color-black;
        }

        &__box {
            margin-right: 8px;

            #{$self}_in-calendar-list-page & {
                margin-right: 32px;
            }
        }

        &__event {
            &-list {
                flex: 1;
            }

            &:not(:last-child) {
                margin-bottom: 22px;
            }

            #{$self}_in-calendar-list-page & {
                margin-bottom: 30px;
            }

            #{$self}_in-calendar-list-page &_upcoming {
                color: $color-black;
                margin-bottom: 24px;
            }

            &-link-heading,
            &-heading {

                &-row {
                    margin-bottom: 16px;

                    #{$self}_in-calendar-list-page & {
                        @media all and ($breakpoint-framework-width) {
                            margin: 0 0 24px !important;
                        }
                    }

                    @media all and ($breakpoint-framework-width) {
                        margin-bottom: 8px;
                    }
                }

                body & {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 1.6;
                    font-weight: 500;

                    @media all and ($breakpoint-framework-width) {
                        font-size: 20px;
                        line-height: 1.2;
                    }
                }

                #{$self}_in-calendar-list-page & {
                    @media all and ($breakpoint-framework-width) {
                        margin: 0 0 24px;
                        font-size: 24px;
                    }
                }

            }

            &-heading {
                display: block;

                &_upcoming {
                    font-style: italic;

                    @media all and ($breakpoint-framework-width) {
                        font-style: normal;

                        #{$self}_in-calendar-list-page & {
                            font-style: italic;
                        }
                    }
                }

            }


            #{$self}__event-heading_upcoming {
                display: block;

                #{$self}_in-calendar-list-page & {
                    font-style: italic;
                }
            }

            &-text {
                display: block;
                font-size: 16px;
                line-height: 1.2;

                &:not(:last-child) {
                    margin-bottom: 16px;
                }

                #{$self}_in-calendar-list-page & {
                    &:first-child {
                        margin-right: 34px;
                    }

                    @media all and ($breakpoint-framework-width) {
                        font-size: 18px;
                        font-weight: 400;
                    }
                }
            }

            &-text-row {
                #{$self}_in-calendar-list-page & {
                    display: flex;
                    flex-wrap: wrap;
                }
            }
        }
    }
}