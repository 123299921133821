$logo-height: 36px;

.top-menu-wrapper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color-modal, .8);
    z-index: $z-index-menu;
    overflow-y: auto;

    &.open {
        display: block;
    }

    &.user-menu {
        top: $header-height-s;

        @media all and ($breakpoint-header-width) and ($breakpoint-header-height) {
            top: $header-height;
        }
    }
}

#top-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: $z-index-header;
    background: $color-white;
    will-change: transform;

    @media print {
        position: static;
    }

    &.light-blue-header {
        background: $color-background-light;
    }

    &.feature-article-header {
        position: absolute;
        background: $color-black-transparent-50;

        .my-page-toggler {
            padding-left: $spacing-m;

            &.open {
                a {
                    box-shadow: inset 0 -2px 0 $color-white;
                }
            }
            
            a {
                color: $color-white;
                &:hover {
                    box-shadow: inset 0 -2px 0 $color-white;
                }
            }
        }
        
        & > .wrapper {
            .link-list {
                a, button {
                    @include focus-outline(4px, 2px, $color-white);

                    &, &.active, &:hover {
                        color: $color-white;
                    }
                    &.active, 
                    &:hover,
                    &:focus {
                        box-shadow: inset 0 -2px 0 $color-white;
                    }
                }
    
                .search {
                    svg {
                        fill: $color-white;
                    }
                    a:hover,
                    .search-button.active,
                    .search-button:hover, 
                    .search-button:focus-visible {
                        span {
                            box-shadow: inset 0 -2px 0 $color-white;
                        }
                    }

                    .search-button {
                        @include focus-outline(4px, 2px, $color-white);
                    }
                }
            }
            
            .menu {
                background: none;
                color: $color-white;

                svg {
                    fill: $color-white;
                }
            }

            .logo {    
                a {
                    @include focus-outline(4px, 2px, $color-white);
                }
            }
        } 

        .search-button{
            color: $color-white;
        }
    }

    .search-button{
        border: none;
        background: none;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        color: $color-deep-blue;
        font-weight: 200;
        cursor: pointer;
    }

    .my-page-toggler {
        padding-left: $spacing-m;

        &.open {
            a {
                box-shadow: inset 0 -2px 0 $color-deep-blue;
            }
        }

        @media all and ($breakpoint-framework-max) {
            display: none;
        }

        a {
            color: $color-deep-blue;

            &:hover {
                box-shadow: inset 0 -2px 0 $color-deep-blue;
            }
        }

        img {
            margin-left: 10px;
            width: $spacing-m;
            height: $spacing-m;
            border-radius: 50%;
            vertical-align: middle;
        }
    }

    > .wrapper {
        height: $header-height-s;
        max-width: 1560px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        @media all and ($breakpoint-header-width) and ($breakpoint-header-height) {
            height: $header-height;
        }

        .logo {
            padding: 0 $spacing-sm;

            a {
                display: inline-block;
                @include focus-outline(4px, 2px, $color-deep-blue);
            }

            img {
                display: block;
                height: 23px;
                width: auto;

                @media print {
                    height: 40px!important;
                }
            }

            @media all and ($breakpoint-framework-width) {
                padding: 0 $spacing-l;

                img {
                    height: 33px;
                }
            }
        }

        .link-list {
            display: flex;
            align-items: center;

            @media print {
                display: none;
            }

            li + li {
                margin-left: $spacing-m;
            }

            a {
                color: $color-deep-blue-100;
                font-weight: 300;
                @include focus-outline(4px, 2px, $color-deep-blue);

                &.active,
                &:hover,
                &:focus {
                    outline: none;
                    box-shadow: inset 0 -2px 0 $color-deep-blue-100;
                }
            }

            .desktop-only {
                display: none;

                @media all and ($breakpoint-framework-width) {
                    display: block;
                }
            }

            .search {
                span {
                    display: none;
                    margin-right: 4px;

                    @media all and ($breakpoint-framework-width) {
                        display: inline;
                        vertical-align: middle;
                    }
                }

                svg {
                    display: inline-block;
                    vertical-align: middle;
                    fill: $color-deep-blue-100;
                    width: 32px;
                    height: 32px;
                }

                .search-button {
                    @include focus-outline(4px, 2px, $color-deep-blue);

                    &.active,
                    &:hover,
                    &:focus-visible {
                        box-shadow: none;
    
                        span {
                            box-shadow: inset 0 -2px 0 $color-deep-blue-100;
                        }
                    }
                }

                
            }
        }

        .left,
        .right {
            @extend %typo-framework;
            display: flex;
            align-items: center;
            height: 100%;
        }

        @media all and ($breakpoint-menu-snapping) {
            .right {
                padding-right: $spacing-l;
            }
        }

        .menu {
            margin-left: $spacing-m;
            background-color: $color-deep-blue-100;
            color: $color-sea-blue-45;
            height: 100%;
            padding: 0 $spacing-sm;
            border: none;
            font-size: 1em;
            font-weight: 300;
            cursor: pointer;
            @include focus-outline(0, 2px);

            @media print {
                display: none;
            }

            @media all and ($breakpoint-framework-max) {
                font-size: 14px;
                margin-left: $spacing-s;
            }

            svg {
                height: 12px;
                width: 30px;
                fill: $color-sea-blue-45;
                margin-left: 10px;

                &.icon-menu {
                    height: 14px;
                    width: 22px;
                }

                &.icon-menu-close {
                    display: none;
                    height: 16px;
                    width: 16px;
                    margin-right: 7px; // 14px/2
                    margin-left: 12px;
                }
            }

            &.open {
                svg.icon-menu { display: none; }
                svg.icon-menu-close { display: block; }
            }

            @media all and ($breakpoint-framework-width) {
                padding: 0 $spacing-m;
            }
        }
    }
}


.top-menu-wrapper {
    @media all and ($breakpoint-framework-max) {
        position: fixed;
        overflow-y: auto;
        top: 50px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: $z-index-menu;
        display: none;
    }

    &.open {
        // display: block;

        // .primary-navigation {
        //     display: block;
        // }

        .user-menu {
            position: static;

            @media all and ($breakpoint-framework-max) {
                display: block;
            }

            @media all and ($breakpoint-framework-width) {
                padding-bottom: 0;
            }

            &.expanded {
                .menu-item-toggler {
                    transform: rotate(180deg);
                }
            }
        }

    }

    &.feature-article-top-menu {
        background-color: $color-black-transparent-100;

        .primary-navigation {
            background: none;
        }
        .menu-close {
            button {
                color: $color-white;

                &:focus-visible {
                    color: $color-sea-blue-45;
                }
            }
        }
        .menu-item-toggler {    
            svg.caret {
                fill: $color-white;
            }
        }
        .secondary-navigation {
            border-color: $color-white;
        }
        nav.user-menu {
            background: none;
            @media all and ($breakpoint-framework-width) {
                background-color: $color-black-transparent-50;
            }
            .user-info a {
                color: $color-white;
            }
        }
    }

    @media all and ($breakpoint-framework-width) {
        &.open {
            nav.user-menu {
                position: absolute;
                overflow: hidden;
            }
        }

    }

    nav.user-menu {
        z-index: $z-index-menu;
        right: 0;
        background-color: $color-deep-blue-100;
        padding: 20px;
        display: none;

        @media all and ($breakpoint-header-width) {
            width: 250px;
            font-size: 14px;
            padding: 10px 10px 0;
            box-shadow: 0 15px 23px rgba($color-modal, .46);

            &.open {
                display: block;
                position: fixed;
                left: 100%;
                margin-left: -250px;

                @media all and (min-width: 1360px) {
                    left: 50%;
                    margin: 0 0 0 430px;
                }
            }
        }

        .user-info {
            img {
                margin-left: 20px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                vertical-align: middle;
            }

            &.desktop-only {
                background-color: $color-cool-gray-25;
                margin: -10px -10px 0 -10px;
                padding: 15px;
                display: none;
                font-size: 12px;
                line-height: 16px;
                color: $color-sub;
                font-weight: 500;

                @media all and ($breakpoint-header-width) {
                    display: block;
                }
            }

            &.mobile-only {
                display: block;
                @media all and ($breakpoint-header-width) {
                    display: none;
                }
            }

            a {
                @extend %link-shortcut-blue;

                color: $color-sea-blue-45;
                font-size: 18px;
                line-height: 1.6;
                font-weight: 400;
            }

            svg.caret {
                fill: $color-sea-blue-45;
            }
        }


        &.expanded {
            padding-bottom: 0;

            .user-info {
                + ul {
                    display: block;
                }
            }
        }

        .user-info {
            .toggler {
                @media all and ($breakpoint-header-width) {
                    display: none;
                }
            }
        }

        ul {
            margin-left: $spacing-sm;
            display: none;
            margin-top: 20px;

            @media all and ($breakpoint-header-width) {
                display: block;
                margin: 0;
            }

            a {
                @extend %link-shortcut;

                &:hover {
                    text-decoration: underline;
                }
            }

            li {
                padding: 10px 0;

                &:last-child {
                    background-color: $color-cool-gray-25;
                    margin: 10px -40px 0 -40px;
                    padding: 20px 40px 20px 40px;
                    a:after {
                        content: '';
                    }
                }

                &:not(:last-child) a {
                    @extend %link-shortcut-blue;
                }

                @media all and ($breakpoint-header-width) {
                    margin: 0 -10px;
                    padding: 0;
                    display: block;

                    a {
                        display: block;
                        padding: 6px 15px;
                        &:after {
                            content: '';
                        }
                        &:hover {
                            background-color: $color-sea-blue-100;
                            color: $color-white;
                            text-decoration: none;
                        }
                    }
                    &:last-child {
                        padding: 0;
                        margin: 0 -10px;
                    }
                }

            }
        }

    }

    .menu-item-toggler {
        position: absolute;
        top: 17px;
        right: 40px;
        background: none;
        border: none;
        cursor: pointer;

        &:focus-visible {
            outline: auto !important;
        }

        @media all and ($breakpoint-header-width) {
            display: none;
        }

        svg.caret {
            width: 16px;
            height: 9px;
            fill: $color-sea-blue-45;
        }
    }
}

.primary-navigation {
    background-color: $color-deep-blue-100;
    padding-bottom: 1px;

    @media all and ($breakpoint-framework-width) {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: auto;
        z-index: $z-index-menu;
    }

    .menu-close {
        position: absolute;
        top: 0;
        right: 0;
        color: $color-white;
        max-width: 1440px;
        display: none;
        margin: auto;
        height: $header-height;
        text-align: right;
        margin-bottom: (110px - $header-height);

        button {
            text-transform: uppercase;
            width: 175px;
            margin-left: auto;
            height: 100%;
            margin-right: $spacing-m;
            border: none;
            font-size: 12px;
            font-weight: 300;
            background: transparent;
            color: $color-sea-blue-45;
            cursor: pointer;
            @include focus-outline(0, 2px);

            &:hover {
                span {
                    box-shadow: inset 0 -2px 0 $color-white;
                }
            }

            svg {
                height: 23px;
                width: 23px;
                fill: $color-white;
                margin-left: $spacing-s;
            }
        }

        @media all and ($breakpoint-framework-width) {
            display: block;
        }

        @media all and ($breakpoint-menu-snapping) {
            right: $spacing-l;
        }

    }

    &.open {
        display: block;
    }

    a {
        color: $color-sea-blue-45;
        font-size: 18px;
        font-weight: 300;

        &:not(.menu-item-toggler) {
            @extend %link-shortcut-blue;
            @include focus-outline(4px, 2px);

            .feature-article-top-menu & {
                @extend %link-shortcut-negative;
            }
        }

        @media all and ($breakpoint-framework-max) {
            font-weight: 400;
        }
    }

    .header {
        font-size: 18px;
        line-height: 1.6;
        font-weight: 400;

        @media all and ($breakpoint-framework-width) {
            box-shadow: inset 0 -2px 0 $color-sea-blue-25!important;
            font-size: 28px;

            .feature-article-top-menu & {
                box-shadow: inset 0 -2px 0 $color-white!important;
            }
            
            &:after {
                display: none!important;
            }
        }

    }


    > .wrapper {
        $icon-size: 18px;
        $text-line-height: 2.5;

        position: relative;
        max-width: 1440px;
        margin: 0 auto;

        @media all and ($breakpoint-header-width) {
            padding: 110px $spacing-l 0;

            > ul {
                margin: 0 calc(-#{$spacing-xl} / 2);
            }
        }

        li {
            position: relative;
        }

        svg.locked {
            position: absolute;
            width: 14px;
            height: 16px;
            fill: $color-sea-blue-45;
            top: 8px;
            left: -25px;

            .feature-article-top-menu & {
                fill: $color-white;
            }
        }

        .expanded {
            margin-bottom: 2px;

            @media all and ($breakpoint-framework-width) {
                background-color: transparent;
                margin-bottom: 0;
            }

            .menu-item-toggler {
                transform: rotate(180deg);
            }

            > ul {
                display: block;
            }
        }

        .navigation-link {
            padding: 16px $spacing-sm;

            a.shortcut {

                &:hover {
                    color: $color-white;
                    box-shadow: inset 0 -2px 0 $color-white;
                }
            }

            @media all and ($breakpoint-header-width) {
                display: none;
            }
        }


        > ul {
            display: flex;
            flex-wrap: wrap;
            list-style: disc;
            font-size: 0;
            line-height: 0;

            > li {
                flex: 0 1 100%;
                position: relative;
                padding: 16px $spacing-l 16px $spacing-sm;

                > svg.locked {
                    display: none;
                }

                @media all and ($breakpoint-header-width) {
                    flex: 0 0 auto;
                    width: 50%;
                    padding: 0 calc(#{$spacing-xl} / 2);
                    margin-bottom: 75px;
                }

                @media all and (min-width: 1200px) {
                    flex: 0 0 auto;
                    width: calc(100% / 3);
                }

                ul {
                    display: none;
                    margin-left: 25px;
                    margin-top: 17px;
                    list-style: disc;
                    font-size: 0;
                    line-height: 0;

                    @media all and ($breakpoint-header-width) {
                        display: block;
                        margin-top: 25px;
                        margin-left: 0;
                    }

                    li {
                        margin-bottom: $spacing-s;
                    }

                    a {
                        @extend %link-shortcut-blue;
                        font-size: 14px;
                        line-height: 1.6;

                        &:hover {
                            text-decoration: underline;
                        }

                        @media all and ($breakpoint-header-width) {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}

.secondary-navigation {
    padding: $spacing-s 0 0;
    position: relative;
    margin: $spacing-sm;
    border-top: solid 1px $color-sea-blue-45;

    @media all and ($breakpoint-header-width) {
        border-top: none;
        margin: 0;
        padding: $spacing-l 0 $spacing-xl;
    }

    > .wrapper {
        margin: 0 auto;

        a {
            @include typo("small-nf");
        }

        > ul {
            display: flex;
            flex-wrap: wrap;

            @media all and ($breakpoint-header-width) {
                margin: 0 (-$spacing-s);
            }

            a {
                @extend %link-shortcut;
                color: $color-white;

                &:hover {
                    color: $color-white;
                    text-decoration: underline;
                }
            }

            > li {
                flex: 0 1 100%;
                @media all and ($breakpoint-header-width) {
                    flex: 0 1 25%;
                    padding: 0 $spacing-s;
                }
            }
        }
    }

    h3 {
        color: $color-white;
        margin-bottom: $spacing-sm;
    }
}
