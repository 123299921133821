@import "../../style/vars/_all";

$breakpoint-fixed-sharing: 1415px;
$item-width: 40px;
$item-height: $item-width;
:global {
    @media all and (max-width: ($breakpoint-fixed-sharing - 1)) {
        .social-media-wrapper {
            width: 100%;
        }
    }

    .share-panel {
        @media print {
            display: none;
        }
        
        @media all and (min-width: $breakpoint-fixed-sharing) {
            opacity: 1;
            transition: opacity 100ms linear;

            &_faded {
                opacity: 0;
            }
        }

        &_has-sidebar {
            width: calc(100% - #{$spacing-xl} - #{$aside-width});
        }

        &__item {
            box-sizing: border-box;
            flex: 0 0$item-width;
            display: block;
            width:$item-width;
            height: $item-height;
            overflow: hidden;
            border-radius: 50%;
            color: $color-sea-blue-100;
            text-align: center;
            cursor: pointer;
            background-color: $color-white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;

            &:after {
                box-sizing: border-box;
                position: absolute;
                width:100%;
                height:100% ;
                top: 0;
                left: 0;
                content: '';
                display: block;
                border-radius: 50%;
                overflow: hidden;
                border: 2px solid $color-cool-gray-25;
                z-index: 2;
            }

            &:hover,
            &:focus-visible {
                &:after {
                    border-color: $color-sea-blue-100 !important;
                }
            }

            &-image {
                display: block;
                max-width: $item-width;
                max-height: $item-height;
                position: relative;
                z-index: 1;
            }
        }

        &_in-article {
            @media all and (max-width: $breakpoint-framework-max-px) {
                width: 100%;
            }

            @media all and (max-width: ($breakpoint-fixed-sharing - 1)) {
                padding-top: $spacing-s;
                border-top: solid 1px $color-cool-gray-25;

                .share-panel__items {
                    display: flex;
                }

                .share-panel__item+.share-panel__item {
                    margin-left: 10px;
                }
            }

            @media all and (min-width: 1415px) {
                position: absolute;
                top: 0;
                right: 100%;
                display: flex;
                flex-direction: column;
                border-top: 0;
                width: auto;

                .share-panel__item {
                    margin-bottom: 10px;
                }
            }
        }

        &_sticky {
            @media all and (min-width: 1415px) {
                position: fixed;
                top: $header-height + $spacing-l;
                right: calc(50% + 600px);
                will-change: transform;
            }
        }
    }
}