@import "../../style/vars/_all";
@import "../../style/shared-components-styles/_typography-styles";
@import "../../style/shared-components-styles/_list-page-styles";

:global {
    .market-insight-statistic-events-list {
        position: relative;
        min-height: 50px;
        &__item{
            margin: 0 0 40px;

            @media all and ($breakpoint-framework-width) {
                margin-bottom: 60px;
                &:last-child{
                    margin-bottom: 40px;
                }
            }
        }

        &__error{
            @include list-page-error;
        }

        &__loading{
            @include list-page-loading;
        }
    }
}
