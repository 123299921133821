@import "../../style/vars/_all";

.list {
    padding: $spacing-m $spacing-sm $spacing-l;

    @media all and (min-width: $breakpoint-card-single) {
        padding: $spacing-l;
    }
}

.loading {
    min-height: 20px;
}

@keyframes appear {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.loader {
    position: relative;
    z-index: 1;
    animation: appear 200ms linear 200ms forwards;
    opacity: 0;
    transition: transform 100ms linear;

    img {
        position: absolute;
        top: 100px;
        left: 0;
        right: 0;
        margin: auto;
    }
}

.wrapper {
    transition: opacity 200ms linear;
}

.searching {
    position: relative;
    opacity: .25;

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: transparent;
        z-index: 5;
    }
}

.helpText{
    margin-bottom: 54px;
    clear: both;

    a:link,
    a:hover,
    a:focus-visible{
        text-decoration: underline;
    }
}