@import "../../style/vars/_all";

.search {
    max-width: 576px;
    margin-bottom: $spacing-l;

    input,
    button {
        background-color: $color-cool-gray-25;
    }

    svg {
        fill: $color-mountain-gray-100;
    }
}
