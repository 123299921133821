@import "../../style/vars/_all";

:global {
    .market-insight-latest-list-item {
        $self: &;
        display: block;
        color: $color-deep-blue;

        &:hover .link-with-icon__text {
            border-bottom-color: transparent; 
        }

        &:focus {
            outline: auto;
        }

        &__heading {
            display: block;
            font-size: 18px;
            font-weight: 500;
            line-height: 1.6;
            margin-bottom: 16px;
        }

        &__time {
            display: block;
            margin-bottom: 16px;
            font-size: 12px;
            line-height: 1.15;

            @media all and ($breakpoint-framework-width) {
                font-size: 16px;
            }
        }

        &__image {
            margin-bottom: 18px;
        }
    }
}
