@import "../../style/vars/_all";
@import "../../style/shared-components-styles/country-page-styles";

:global {
    .country-page-employee {
        $self: &;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        &__image-container {
            flex: 0 0 86px;
            margin-right: 32px;
            height: 84px;
            height: 84px;
            overflow: hidden;
            border-radius: 50%;
        }

        &__text-container {
            padding: 6px 0 0;

            @media (min-width: $breakpoint-ipad) {
                padding: 12px 0 0;
            }
        }

        &__name {
            margin: 0 0 8px;
            display: inline-flex;

            @media (min-width: $breakpoint-ipad) {
                margin-bottom: 14px;
            }
        }

        &__position {
            font-size: convert-to-rem(16px);
            line-height: convert-to-rem(24px);
            font-weight: 400;
        }
    }
}