@import "../../style/vars/_all";

:global {
    .content-popup {
        $self: &;

        &__container {
            display: inline-block;
            vertical-align: middle;
            width: 100%;
            padding: 15px;
            position: relative;
            text-align: left;
        
            @media all and ($breakpoint-framework-width) {
                padding: 100px 50px;
            }
        }

        &__wrapper {
            position: relative;
            padding: $spacing-l $spacing-m $spacing-m;
            background-color: $color-white;
            border-radius: 6px;

            @media all and ($breakpoint-framework-width) {
                padding: 122px 50px;
            }
        }
    
        &__close-btn {
            position: absolute;
            right: 24px;
            top: 24px;
        }
    
        &__content {
            width: 656px;
            max-width: 100%;
            margin: auto;
        }

        &__page-body-when-opened {
            overflow: hidden !important;
        }
    }

    .content-popup__root .ReactModal {
        &__Overlay {
            overflow-y: auto;
            background-color: $color-black-transparent!important;
            z-index: $z-index-modal;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow-y: auto;
            text-align: center;
            opacity: 0;
            transition: all 500ms ease-in-out;
    
            &--after-open {
                opacity: 1;
            }
    
            &--before-close {
                opacity: 0;
            }
    
            &:before {
                content: '';
                display: inline-block;
                vertical-align: middle;
                height: 100%;
            }
        }
    
        &__Content {
            display: inline-block;
            vertical-align: middle;
            width: 1086px;
            max-width: 100%;
            padding: 25px;
            position: relative;
            text-align: left;
            margin: auto;
            position: static!important;
            border: none!important;
            padding: 0!important;
            overflow: visible!important;
            border-radius: 0!important;
            outline: none;
            border: none;
        }
    }
}




