@import "../../style/vars/_all";

.item {
    width: 100%;
    background-color: $color-sea-blue-100;
    color: $color-white;
    border-top: 2px solid $color-sea-blue-100;

    td {
        padding: $spacing-s $spacing-sm;

        @media all and (max-width: 1023px) {
            display: block;
        }

        @media all and (min-width: 1024px) {
            padding: $spacing-m;
        }
    }
}

.wrapper {
    word-break: break-word;
    word-wrap: break-word;
}


.centered {
    max-width: 988px;
    margin: auto;
}
