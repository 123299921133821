@import "../../style/vars/_all";

$easing: 200ms cubic-bezier(0.165, 0.84, 0.44, 1);

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $z-index-modal;
    padding: $spacing-l $spacing-sm $spacing-sm;
    display: flex;
    overflow-y: auto;
    transition: background $easing;
    animation: background $easing;
    animation-fill-mode: forwards;

    @media all and ($breakpoint-framework-width) {
        padding: $spacing-l;
    }

    &.hasImage {
        .content > div:first-child {
            flex: 0 0 41%;
            overflow: hidden;
        }
    }

    &.mini.hasImage {
        .content > div:first-child {
            flex: 0 0 252px;
        }
    }

    iframe {
        border: none;
        width: 100%;
        height: 100%;
        min-height: 550px;
    }

    &.employee{
        flex-direction: column;

        .content{
            display: block;
            align-self: center;
            width: 100%;
            max-width: 100%;
            
            @media all and ($breakpoint-framework-width) {
                max-width: 1140px;
                display: flex;
                flex-direction: row;        
            }
        }
    }
}



.content {
    background-color: $color-white;
    max-width: 1140px;
    margin: auto;
    box-shadow: 0 15px 23px rgba($color-box-shadow, .61);
    display: flex;
    transition: transform $easing, opacity $easing;
    animation: modal $easing;
    animation-delay: 100ms;
    animation-fill-mode: forwards;
    transform: scale(.5);
    opacity: 0;
    flex-direction: column;
    min-width: 100%;

    > div {
        flex: 1 1 auto;
    }

    @media all and ($breakpoint-framework-width) {
        min-width: 840px;
        flex-direction: row;
        //flex: 1 1 auto;
    }

    .mini & {
        max-width: 840px;
    }

    .video & {
        max-width: 1200px;
        min-width: 0;
        width: 100%;
    } 
}

.closing {
    animation: background-out $easing;
    animation-delay: 100ms;
    animation-fill-mode: backwards;

    .content {
        animation-delay: 0;
        animation: modal-out $easing;
        animation-fill-mode: none;
    }
}

.help {
    color: $color-deep-blue-25;
    font-size: 12px;
    text-transform: uppercase;
    position: absolute;
    top: $spacing-sm;
    right: $spacing-sm;
    cursor: pointer;
    background: transparent;
    border: none;

    svg {
        height: 23px;
        width: 23px;
        fill: $color-deep-blue-25;
        margin-left: $spacing-s;
    }

    &:hover,
    &:focus {
        color: $color-white;

        span {
            box-shadow: inset 0 -2px 0 $color-white;
        }

        svg {
            fill: $color-white;
        }
    }

    @media all and ($breakpoint-framework-width) {
        right: $spacing-l;
    }
}

@keyframes modal {
    0% { transform: scale(.5); opacity: 0; }
    100% { transform: scale(1); opacity: 1; }
}

@keyframes background {
    0% { background-color: rgba($color-modal, 0); }
    100% { background-color: rgba($color-modal, .9); }
}

@keyframes modal-out {
    0% { transform: scale(1); opacity: 1; }
    100% { transform: scale(.5); opacity: 0; }
}

@keyframes background-out {
    0% { background-color: rgba($color-modal, .9); }
    100% { background-color: rgba($color-modal, 0); }
}
