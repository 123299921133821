:global {
    .parallax-effect-block {
        &__item{
            z-index: 10;
        }

        &__block-media{
            
            &_fixed{
                position: fixed;
                display: none;
            }
        }
    }
}
