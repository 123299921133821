@mixin inline-text-with-bg($bg-color, $top-space, $bottom-space, $side-space) {
    display: inline;
    background-color: $bg-color;
    box-shadow: $side-space 0 0 $bg-color, -#{$side-space} 0 0 $bg-color;
    box-decoration-break: clone;
    padding: 3px 0;

    @media all and ($breakpoint-framework-width) {
        padding: 5px 0;
    }
  
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
        padding: $top-space 0 $bottom-space;  
    }
}
