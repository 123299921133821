@import "../../style/vars/_all";

:global {
    .filter-shortcuts {
        margin: 0 0 12px;
        &__heading{
            display: none;
            font-weight: 500;
            margin: 0 0 3px;
            
            @media (min-width: $breakpoint-ipad) {
                display: block;
            }
        }

        &__mobile-toggle{
            margin: 0 0 20px;
            width: 100%;

            @media (min-width: $breakpoint-ipad) {
                display: none !important;
            }
        }

        &__list {
            display: none;

            &-item{
                padding: 0 0 $spacing-s;  
            }
            
            @media (max-width: $breakpoint-ipad - 1) {
                 &_opened-in-mobile{
                    display: block;
                }
            }

            @media (min-width: $breakpoint-ipad) {
                display: flex;
                flex-wrap: wrap;
                width: 100%;

                &-item {
                    flex: 0 0 auto;
                    padding-right: 32px;
                }
            }
        }

        &__mobile-toggle{
            @media (min-width: $breakpoint-ipad) {
                display: none;
            }
        }
    }
}
