.embeddedFrameBlock {
    position: relative;
    width: 100%;
}

.embeddedFrame {
    max-width: 100%;
}

.embeddedFrameHeading {
    margin-bottom: 15px;
}

.embeddedFrameText {
    margin-bottom: 20px;
}