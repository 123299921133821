@import "../../style/vars/_all";
@import "../../style/shared-components-styles/_typography-styles";

:global {
    .feature-article-sources{
        $self: &;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        @include page-block;
        max-width: $content-width;
        margin-top: 35px;
        margin-bottom: 50px;

        @media all and ($breakpoint-framework-width) {
            margin-top: 35px;
        }

        @media all and ($breakpoint-wide-l) {
            margin-bottom: 100px;
        }

        &__container{
            border-left: 3px solid $color-content-text;
            padding-left: 17px;
            position: relative;
            
            @media all and ($breakpoint-framework-width) {
                padding-top: 1px;
                padding-bottom: 2px;
                margin-left: 120px;
                border-left-color: $color-blue-border;
            }
        }

        &__heading{
            font-size: 18px;
            line-height: 24px;
            margin: 0 0 11px;
            color: $color-content-text;
            font-weight: 500;

            @media all and ($breakpoint-framework-width) {
                margin: 0 0 19px;
                font-size: 22px;
                line-height: 32px;
            }
        }
    }
}