@mixin market-insight-reports-block-heading {
    font-size: convert-to-rem(24px);
    line-height: 1.33;
    font-weight: 500;
    margin-bottom: 15px;

    @media all and ($breakpoint-framework-width) {
        font-size: convert-to-rem(40px);
        line-height: 1.2;
    }
}