@import "../../style/vars/_all";

.content {
    padding: $spacing-sm;
    background: $color-white;
    max-height: 250px;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    position: relative;
    
    &.expandedInMobile{
        max-height: 1000px;
    }

    header {
        margin: 0 0 $spacing-m;
    }

    @media all and ($breakpoint-framework-width) {
        padding: $spacing-l;
        min-height: 677px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
       
        &,
        &.expandedInMobile{
            max-height: none;
        }
    }
}
.expandButtonContainer{
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 10;
    padding: 20px;
    background: #fff;
    width: 100%;

    @media all and ($breakpoint-framework-width) {
        display: none;
    }


    &:before{
        content: " ";
        position: absolute;
        bottom: 100%;
        left: 0;
        width: 100%;
        height: 50px;
        background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%);
    }
}

.expandButton{
    width: 100%;
}

.contacts{
    display: flex;
    flex-direction: column;

    @media all and ($breakpoint-framework-width) {
        flex-direction: row;
    }
}
.contact{
    width: 100%;
    margin-bottom: $spacing-m;

    @media all and ($breakpoint-framework-width) {
        width: 40%;
        margin:0 1% 0 0;
    }

    &:last-child{
        margin: 0;
    }

    a{
        font-weight: 300;
    }
}

.contactLabel{
    @include typo(small);
    font-weight: 500;
}
   
.intro {
    margin: $spacing-m 0;
}

.image {
    background-color: $color-cool-gray-25;
    padding-top: 50%;
    min-height: 300px;

    @media all and ($breakpoint-framework-width) {
        padding-top: 41%;
        
    }
}

.imageContainer{
    position: relative;
}

.downloadImageLink{
    position: absolute;
    z-index: 100;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba($color-modal, 0.4);
    text-align: center;
    display: block;
    padding: 20px 10px;
    transition: background-color 0.5s ease-in-out;
    color: #fff !important;
    text-decoration: underline;
    font-weight: 300;

    &:hover{
        background-color: rgba($color-modal, 0.8);
    }
}
