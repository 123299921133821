.video-button {
    background-color: transparent;
    border: none;
    -webkit-appearance: none;
    box-shadow: $shadow-icon;
    border-radius: 50%;
    cursor: pointer;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
        display: block;
    }

    span {
        display: none;
    }
}
