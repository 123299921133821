@import "../../style/vars/_all";

:global {
    .news-page-header {
        $self: &;
        @include page-header;

        &_has-background {
            @include page-header-with-bg;

            #{$self}__image-container {
                @include page-header-image;
            }
        }

        &__text-container {
            @include page-header-text;
        }

        @media all and ($breakpoint-header-width) {
            min-height: 450px - $decal-large-height;
        }

        &__heading {
            margin: 0 0 $spacing-s;
        }

        &__links-list{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            list-style: none;
            margin: 0;
            padding: 0;

            &-item{
                margin: 0 20px 20px 0;
            }
        }

        &__page-subscription {
            padding-right: 20px;
        }
    }
}
