@import "../../style/vars/_all";
@import "../../style/shared-components-styles/country-page-styles";

:global {
    .country-page-links-block {
        $self: &;
        margin: 0 0 120px;
        padding: 25px 0 55px;
        background-color: $color-sea-blue-15;

        @media all and ($breakpoint-framework-width) {
            padding: 64px 0 48px;
            margin: 0 0 150px;
        }

        &__container {
            @include container-wide;
        }

        &__heading {
            @include country-page-block-heading;
            margin: 0 0 24px ;
        }

        &__list {
            display: flex;
            flex-direction: column;

            @media all and ($breakpoint-framework-width) {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }

        &__list-item {
            display: block;
            padding: 32px 34px;
            background-color: $color-white;
            margin: 0 0 16px;
            position: relative;

            @media all and ($breakpoint-framework-width) {
                padding: 32px 34px;
                flex: 0 0 calc(50% - 26px);
            }
        }

        &__link-description{
            margin: 15px 0 0;
            font-weight: 400;
            font-size: convert-to-rem(18px);
            line-height: convert-to-rem(26px);
            color: $color-deep-blue;

            @media all and ($breakpoint-framework-width) {
                margin-top: 5px;
            }
        }

        &__link{
            &:before{
                @include full-size;
                content: "";
                display: block;
                border: 2px solid $color-cool-gray-50;
                z-index: 2;
            }

            &:hover:before,
            &:focus-visible:before{
                border-color: $color-black-transparent-50;
            }

        }

    }
}