@import "vars/all";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-family: $font-family;
    font-size: 1rem;
    line-height: 1.625rem;
    font-weight: 400;
    position: relative;
    z-index: 0;
}

body {
    @include typo(body);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-top: $header-height-s;
    color: $color-deep-blue-100;
    background-color: $color-white;

    @media all and ($breakpoint-header-width) and ($breakpoint-header-height) {
        padding-top: $header-height;
    }

    @media print {
        padding-top: 20px;
    }
}
:global {
    body.feature-article-page{
        padding-top: 0;
    }
}

ul {
    list-style: none;
}

hr {
    margin: 30px 0;
    border: none;
    height: 2px;
    background: $color-cool-gray-25;
}

a[disabled] {
    pointer-events: none;
}

:global {

    hr.divider {
        margin: $spacing-l 0;
        border: none;
        border-top: solid 1px $color-cool-gray-25;
        border-bottom: solid 1px $color-cool-gray-25;
    }

    a.link {
        color: $color-sea-blue-100;
        box-shadow: inset 0 -1px 0px rgba($color-sea-blue-100, .25);
        text-decoration: none;
        cursor: pointer;

        &.hover,
        &:hover,
        &:focus {
            box-shadow: inset 0 -1px 0px $color-sea-blue-100;
        }
    }

    main.no-header {
        margin-top: -#{$header-height-s};

        @media all and ($breakpoint-header-width) and ($breakpoint-header-height) {
            margin-top: -#{$header-height};
        }
    }

    #coiOverlay, 
    #coi-banner-categories,
    #Coi-Renew {
        @media print {
            display: none!important;
        }
    }

    .hidden-tablet {
        @media all and ($breakpoint-framework-width-max) {
            display: none!important;
        }
    }

    .hidden-desktop {
        @media all and ($breakpoint-framework-width) {
            display: none!important;
        }
    }

    #epi-quickNavigator {
        transform: translateY(-80%);
        transition: transform 0.3s, opacity 0.3s;
        opacity: 0.5;
        cursor: pointer;
    
        &:before {
            content: '';
            position: absolute;
            top: -10px;
            right: -10px;
            bottom: -10px;
            left: -10px;
        }
    
        &:hover {
            transform: none;
            opacity: 1;
        }
    
        .epi-quickNavigator-editLink {
            img {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
}

