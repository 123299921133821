@import "../../style/vars/_all";
$height: 40px;

$self: '.download-link';

:global {
    body {
        #{$self} {
            color: $color-deep-blue-100;
            box-shadow: none !important;
            border: none;
            font-size: 18px;
            line-height: 1.45;

            &:hover,
            &:focus-visible {
                border-bottom: none;
                box-shadow: none;

                #{$self}__text {
                    border-color: transparent !important;
                }
            }

            &:focus {
                outline: auto;
            }
        }

        #{$self}__text {
            display: inline;
            line-height: 1.3;
            border-bottom-width: 2px;
            border-bottom-style: solid;
            border-color: $color-blue-border;
            transition: border-color 0.3s ease-in-out;
            padding: 0 0 1px;
            vertical-align: top;
            border-color: $color-blue-border;
        }

        #{$self}__icon {
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-left: 10px;
            margin-top: 2px;
            align-self: flex-start;
            fill: currentColor;
        }
    }
}