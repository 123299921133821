body.list-page {
    .list-page-content {
        padding: $spacing-m $spacing-sm;

        @media all and ($breakpoint-header-width) {
            padding: $spacing-l;
        }

        @media all and ($breakpoint-wide-l) {
            $width: $framework-max-width;
            $padding: calc(50% - (#{$width} / 2));

            padding-left: $padding;
            padding-right: $padding;
        }
    }

    &-gray {
        background-color: $color-cool-gray-25;

        > main {
            .list-page-header.has-background {
                &:before {
                    border-right-color: $color-cool-gray-25;
                }

                &:after {
                    background-color: $color-cool-gray-25;
                }
            }

            .list-page-content {
                background-color: $color-cool-gray-25;

                &-white {
                    background-color: $color-white;
                }
            }
        }
    }
}
