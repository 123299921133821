@import "../../style/vars/_all";
@import "../../style/shared-components-styles/_typography-styles";

:global {
    .feature-article-author{
        $self: &;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        @include page-block;
        max-width: $content-width;
        margin-top: 35px;
        margin-bottom: 35px;

        @media all and ($breakpoint-framework-width) {
            margin-bottom: 35px;
        }


        &__image-container{
            width: 100px;
            height: 100px;
            border: 3px solid white;
            border-radius: 50px;
            box-shadow: $shadow-block;
            margin-right: 20px;
            position: relative;
            overflow: hidden;
        }

        &__text-container{
            align-self: center;
            font-size: 18px;
            line-height: 24px;
            display: flex;
            flex-direction: column;
            color: $color-content-text;
            letter-spacing: -0.74px;
            font-weight: 300;

            @media all and ($breakpoint-framework-width) {
                font-size: 22px;
                 line-height: 32px;
            }
        }

        &__name{
            align-self: flex-start;
            color: $color-content-text;
            font-weight: 500;
            display: inline-flex;

            &_clickable{
                text-decoration: underline;
                &:hover,
                &:focus{
                    text-decoration: none;
                }
            }
        }

        &__title{
            align-self: flex-start;
        }

        &__published-date{
            align-self: flex-start;
        }
        
    }
}
