.embeddedVideo {
    position: relative;
    padding-bottom: 56.25%;

    & > div {
        width: 100%!important;
        height: 100%!important;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%!important;
        width: 100%!important;
        min-height: 0!important;
    }
}