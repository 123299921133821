$article-width: 1080px;
$aside-margin: $spacing-xl;

article.article-page {
    @media print {
        .wysiwyg p, .wysiwyg address {
            margin: 0 0 20px;
        }
    }

    .article-content {
        position: relative;
        margin-top: $spacing-m;

        @media all and ($breakpoint-framework-width) {
            margin-top: $spacing-l;
            flex-wrap: wrap;
        }
    }

    .title {
        width: 100%;
        display: flex;
        align-items: flex-end;
        padding: $spacing-m $spacing-sm;

        .title-wrapper {
            width: $article-width;
            margin: 0 auto;
        }

        h1, span {
            display: block;
            max-width: 670px;
        }

        span {
            margin-top: $spacing-m;
        }

        @media all and ($breakpoint-framework-width) {
            padding: $spacing-m $spacing-l;
        }
    }

    .introduction {
        margin-bottom: $spacing-m;

        @media all and ($breakpoint-framework-width) {
            margin-bottom: $spacing-l;
        }
    }

    .article-body {
        flex: 1;
        margin: 0 0 $spacing-m;

        &.no-sidebar {
            flex: 1 1 auto;
        }

        img {
            max-width: 100%;
            height: auto;
            display: block;
        }

        @media print {
            font-size: 15px;
            line-height: 23px;
        }
    }

    aside {
        flex: 1 1 100%;

        .employees-list {
            margin: 0 0 $spacing-l;
        }

        img {
            max-width: 100%;
            height: auto;
        }

        .image-file {
            margin: 0 0 $spacing-l;
            display: block;
        }

        @media all and ($breakpoint-article-large) {
            flex: 0 0 $aside-width;
            margin-left: $aside-margin;
        }

        hr {
            border: none;
            border-top: solid 1px $color-cool-gray-25;
            border-bottom: solid 1px $color-cool-gray-25;
        }
    }

    > section {
        max-width: $article-width + ($spacing-l * 2);
        margin: 0 auto $spacing-m;
        padding: 0 $spacing-sm;
        display: flex;
        flex-direction: column;

        @media all and ($breakpoint-article-large) {
            flex-direction: row;
            padding: 0 $spacing-l;
        }
    }
}

body.article > main {
    > article {
        background-color: $color-white;
        color: $color-deep-blue-100;

        > header {
            &.has-image {
                position: relative;

                .photographer {
                    @extend %typo-sub;

                    span {
                        text-transform: uppercase;
                    }

                    @media all and ($breakpoint-framework-max) {
                        background-color: $color-white;
                        padding: 10px $spacing-sm 0;
                    }

                    @media all and ($breakpoint-framework-width) {
                        position: absolute;
                        top: calc(100% +  10px);
                        right: $spacing-l;
                    }

                    @media all and ($breakpoint-wide-l) {
                        right: 50%;
                        margin-right: calc(-#{$framework-max-width} / 2);
                    }
                }
            }

            .fluid-image {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                min-height: 450px;
                max-width: $banner-image-width;
                margin: auto;

                [data-edit-mode] & {
                    min-height: 300px !important;
                }
            }
        }
    }

    .related-content {
        margin-bottom: (-$decal-small-height);

        &:after {
            content: "";
            display: block;
            height: $decal-small-height;
            background-color: $color-cool-gray-25;
        }

        @media all and (min-width: $footer-breakpoint) {
            margin-bottom: (-$decal-large-height);

            &:after {
                height: $decal-large-height;
            }
        }
    }
}
