@import "../../style/vars/_all";

.filter {
    position: relative;
    width: 100%;

    &.disabled {
        .title {
            color: $color-sub;
        }
    }
}

.title {
    margin: 0 0 10px;
}
