@import "../../style/vars/_all";

$text-bg: rgba(#fff, 0.8);

:global {
    .background-media {
        $self: &;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;       
    }

    
}
