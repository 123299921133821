$decal-large-width: 60px;
$decal-large-height: 53px;
$decal-small-width: 20px;
$decal-small-height: 18px;

@mixin top-decal($color, $breakpoint) {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 0;
        border-top: solid $decal-large-height transparent;
        border-right: solid $decal-large-width $color;
        transform-origin: bottom left;
        transform: scale(.33333333333);

        @media all and ($breakpoint) {
            transform: none;
        }
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 100%;
        width: calc(100% - #{$decal-small-width});
        height: $decal-small-height;
        background: $color;
        left: $decal-small-width;

        @media all and ($breakpoint) {
            left: $decal-large-width;
            height: $decal-large-height;
            width: calc(100% - #{$decal-large-width});
        }
    }
}

@mixin bottom-decal($color, $breakpoint) {
    position: relative;

    &:after {
        content: "";
        z-index: 1;
        position: absolute;
        left: 0;
        top: 100%;
        border-left: solid $decal-large-width $color;
        border-bottom: solid $decal-large-height transparent;
        transform-origin: top left;
        transform: scale(.33333333333);
        transition: transform 100ms linear;

        @media all and ($breakpoint) {
            transform: none;
        }
    }
}

@mixin bottom-decal-inset($color, $breakpoint) {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        border-right: solid $decal-small-width $color;
        border-top: solid $decal-small-height transparent;
        z-index: 3;

        @media all and ($breakpoint) {
            border-right-width: $decal-large-width;
            border-top-width: $decal-large-height;
        }
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: $decal-small-width;
        right: 0;
        height: $decal-small-height;
        z-index: 3;
        background-color: $color;

        @media all and ($breakpoint) {
            left: $decal-large-width;
            height: $decal-large-height;
        }
    }
}

@mixin bottom-right-decal($color) {
    position: relative;

    &:after {
        content: "";
        z-index: 1;
        position: absolute;
        left: 100%;
        bottom: 0;
        border-left: solid $decal-small-width $color;
        border-top: solid $decal-small-height transparent;
    }
}

@mixin vertical-decal($position, $color, $size) {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: (-$size);
        left: 0;
        border-right: solid $size $color;
        border-top: solid $size transparent;
    }

    &:after {
        content: "";
        position: absolute;
        top: (-$size);
        left: $size;
        right: 0;
        height: $size;
        background: $color;
    }
}
