@import "../../style/vars/_all";
@import "../../style/shared-components-styles/_customs-quotas-styles";

:global {
    .customs-quotas-cta-block {
        @include customs-quotas-panel;

        &__container {
            @include container;
        }

        &__content {
            @include customs-quotas-panel-content;
        }

        &__heading {
            @include customs-quotas-panel-heading;

            &-icon {
                display: inline-block;
                vertical-align: middle;
                margin-left: $spacing-s;
                width: 21px;
                height: 28px;

                @media all and ($breakpoint-framework-width) {
                    margin-left: $spacing-sm;
                    width: 30px;
                    height: 35px;
                }
            }
        }

        &__link {
            &-holder {
                margin-top: $spacing-sm;
            }
        }

        &__actions {
            @media all and ($breakpoint-framework-width) {
                display: flex;
                align-items: center;
            }

            &:not(:empty) {
                margin-top: $spacing-sm;
            }
        }

        &__error-msg {
            margin-top: $spacing-sm;
            color: $color-error-text;

            @media all and ($breakpoint-framework-width) {
                margin-top: 0;
                margin-left: $spacing-m;
            }
        }
    }
}
