@import "../../style/vars/_all";
$height: 40px;

$self: '.scroll-to-link';

:global {
    body {
        #{$self} {
            color: $color-deep-blue-100;
            box-shadow: none;
            background: none;
            border: none;
            font-size: 16px;
            line-height: 1.45;
            font-weight: 300;
            cursor: pointer;
            text-align: left;

            @media all and ($breakpoint-framework-width) {
                font-size: 18px;
            }

            &:hover,
            &:focus-visible {
                border-bottom: none;
                box-shadow: none;

                #{$self}__text {
                    border-color: transparent !important;
                }
            }

            
            &:focus {
                outline: auto;
            }
        }

        #{$self}__text {
            display: inline;
            line-height: 1.3;
            border-color: $color-blue-border;
            border-bottom-width: 2px;
            border-bottom-style: solid;
            transition: border-color 0.3s ease-in-out;
            padding: 0 0 1px;
            vertical-align: top;
        }

        #{$self}__icon {
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-left: 8px;
            margin-top: 4px;
        }
    }
}