@import "../../style/vars/_all";
@import "../../style/shared-components-styles/_typography-styles";

$rootClass: ".info-block";

:global {
    #{$rootClass} {
        @include page-block;
        max-width: $content-width;

        &__container {
            padding: 18px;
            background-color: $color-sky-blue-15;
            box-shadow: $shadow-block;

            @media all and ($breakpoint-framework-width) {
                padding: 40px 50px 50px;
            }
        }

        &__heading {
            body & {
                font-size: 18px;
                line-height: 1.45;
                margin: 0 0 10px;
                color: $color-content-text;
                font-weight: 500;

                @media all and ($breakpoint-framework-width) {
                    font-size: 22px;
                    margin-bottom: 20px;
                }
            }
        }
    }

    #{$rootClass}_in-rich-text {
        @include block-indents-inside-rich-text;
    }
}
