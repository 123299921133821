@import "../shared-components-styles/market-insight-styles";

.market-insight-calendar-list-page {
    &__heading {
        @include market-insight-page-heading;
        font-weight: bold;
    }

    &__intro {
        @include container;
        margin: 10px 0 40px;

        @media all and ($breakpoint-framework-width) {
            margin: 45px 0 86px;
        }
    }

    &__content {
        @include container;
        margin-bottom: 50px;

        @media all and (min-width: $breakpoint-mobile) {
            margin-bottom: 90px;
        }
    }
}