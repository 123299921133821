@import "../../style/vars/_all";

.shortcutsBlock {
    position: relative;
    font-size: 17px;
    line-height: 1.56;
    background-color: $color-white;

    @media all and ($breakpoint-wide-l) {
        font-size: 18px;
    }
}

.shortcutsWrapper {
    display: flex;
    flex-wrap: wrap;
    padding: $spacing-m;
    padding: $spacing-m $spacing-sm;

    @media all and ($breakpoint-framework-width) {
        padding: $spacing-l $spacing-l $spacing-m;
    }

    @media all and ($breakpoint-wide-l) {
        $width: $framework-max-width;
        $padding: calc(50% - (#{$width} / 2));

        padding-left: $padding;
        padding-right: $padding;
    }
}

.shortcutsCol {
    width: 100%;
    padding-right: $spacing-sm;
    margin-bottom: $spacing-m;

    @media all and (min-width: 768px) {
        width: 50%;
    }

    @media all and ($breakpoint-framework-width) {
        padding-right: 40px;
        width: 25%;
    }
}

.shortcutsHeading {
    margin-bottom: $spacing-sm;
}

.shortcutsItem {
    &:not(:last-child) {
        margin-bottom: 15px;
    }
}

.shortcutsLink {
    box-shadow: none!important;
    color: $color-deep-blue-100!important;
    font-weight: 300;

    &:hover,
    &:focus {
        outline: none;
        box-shadow: inset 0 -1px 0px $color-deep-blue-100!important;
    }
}

.shortcutsScrollBtn {
    position: absolute;
    bottom: -30px;
}