%link-shortcut {
    color: $color-deep-blue-100;
    box-shadow: none;
    text-decoration: none;

    &,
    &.hover,
    &:hover,
    &:focus {
        color: $color-sea-blue-100;
    }

    &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        background-image: url('../assets/icon-arrow-right-dark.svg');
        width: 13px;
        height: 12px;
        margin-left: 8px;
        background-position: center;
        background-size: cover;
    }
}

%link-reverse-shortcut {
    position: relative;
    padding-left: 17px;
    color: $color-deep-blue-100;
    box-shadow: none;
    text-decoration: none;

    &.hover,
    &:hover,
    &:focus {
        color: $color-sea-blue-100;
    }

    &:before {
        position: absolute;
        top: 3px;
        left: 0;
        content: "";
        display: inline-block;
        vertical-align: middle;
        background-image: url('../assets/chevron-right-dark.svg');
        width: 8px;
        height: 12px;
        background-position: center;
        background-size: cover;
    }
}

%link-shortcut-blue {
    @extend %link-shortcut;

    &,
    &.hover,
    &:hover,
    &:focus {
        color: $color-sea-blue-45;
    }
    
    &:after {
        background-image: url('../assets/icon-arrow-right-blue.svg');
    }
}

%link-shortcut-negative {
    @extend %link-shortcut;

    &,
    &.hover,
    &:hover {
        color: $color-white;
    }
 
    &.hover,
    &:hover,
    &:focus {
        color: $color-sea-blue-45;
    }

    &:after {
        background-image: url('../assets/icon-arrow-right-white.svg');
    }
}

%link-reverse-shortcut-negative {
    @extend %link-reverse-shortcut;
    color: $color-white;
 
    &.hover,
    &:hover,
    &:focus {
        color: $color-sea-blue-45;
    }
    
    &:before {
        background-image: url('../assets/chevron-right-white.svg');
    }
}

a.shortcut {
    @extend %link-shortcut;

    &.negative {
        @extend %link-shortcut-negative;
    }

    &.blue {
        @extend %link-shortcut-blue;
    }
}

a.shortcut-reverse {
    @extend %link-reverse-shortcut;

    &.negative {
        @extend %link-reverse-shortcut-negative;
    }
}
