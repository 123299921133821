@import "../../style/vars/_all";
@import "../../style/vars/_mixins";

:global {     
	.date-range-filter {
        input[type="text"] {
            min-height: 45px;

            @media all and ($breakpoint-framework-width) {
                min-height: 55px;
            }
        }
        
        &__label {
            font-size: 16px;
            line-height: 1.2;
            font-weight: 700;
            margin-bottom: 10px;

            @media all and ($breakpoint-framework-width) {
                font-size: 20px;
                margin-bottom: 16px;
            }
        }

        .DateRangePicker {
            @include shadowed-box;

            .DateInput_input {
                &::placeholder,
                &::-webkit-input-placeholder,
                &:-moz-placeholder,
                &::-moz-placeholder,
                &:-ms-input-placeholder {
                  color: $color-deep-blue-100;
                }
            }

            .DateInput, .DateRangePickerInput {
                background: none;
            }

            .DateRangePickerInput_clearDates {
                width: 28px;
                height: 28px;
                padding: 0;
            }
        }
    }
}