@import "../../style/vars/_all";

.container {
    margin: 0 (-$spacing-sm);

    @media all and (min-width: $breakpoint-card-single) {
        margin: 0 (-$spacing-l);
    }
}

