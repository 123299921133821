$width: 650px;

body.my-page {
    nav.my-page-nav {
        margin: 0 0 $spacing-m;

        .logout {
            text-align: right;
        }

        .tags,
        .logout {
            margin-bottom: $spacing-sm;
        }

        @media all and ($breakpoint-framework-width) {
            margin: 0 0 $spacing-l;
            display: flex;
            align-items: center;

            .tags,
            .logout {
                margin-bottom: $spacing-sm;
            }

            .logout {
                order: 2;
                margin-left: auto;
            }
        }

    }

    .my-page-notifications {
        margin: $spacing-m 0 0;
        padding: $spacing-m 0 0;
        border-top: solid 2px $color-cool-gray-25;

        &__fieldset {
            border: none;
            margin: $spacing-sm 0 $spacing-s-mobile;

            &-legend {
                display: block;
                font-weight: 500;
                margin-bottom: $spacing-s-mobile;
            }
        }

        .available-notifications {
            li {
                margin: 0 0 10px;
            }

            @media all and ($breakpoint-framework-width) {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -10px;

                li {
                    width: 50%;
                    padding: 0 10px;
                }
            }
        }

        .available-intervals {
            margin: 0 0 $spacing-m;

            li {
                margin: 0 0 10px;
            }

            @media all and ($breakpoint-framework-width) {
                margin: 0 0 $spacing-l;
            }
        }

        @media all and ($breakpoint-framework-width) {
            margin: $spacing-l 0 0;
            padding: $spacing-l 0 0;
        }
    }

    .my-page-alert {
        padding: $spacing-sm;
        margin: 0 0 $spacing-m;

        &.success {
            background-color: $color-sea-green-50;
            color: $color-deep-blue-100;
        }

        &.error {
            background-color: $color-error;
            color: $color-white;
            font-weight: bold;
        }
    }

    main {
        display: block;
        max-width: $width + ($spacing-sm * 2);
        margin: auto;
        padding: $spacing-m $spacing-sm;

        header {
            margin: 0 0 $spacing-l;
        }

        h2,
        h3 {
            margin: 0 0 $spacing-s;
        }

        @media all and ($breakpoint-framework-width) {
            max-width: $width + ($spacing-l * 2);
            padding: $spacing-l;
        }

        [role=tabpanel] {
            display: none;
            opacity: 0;

            &.active {
                display: block;
                opacity: 1;
            }
        }
    }
}

.sub-text{
    clear: both;
    margin-top: 40px;
}
