.news-page-content {
    padding: $spacing-m 0 0;

    @media all and ($breakpoint-header-width) {
        padding: $spacing-l 0 0;
    }
    
}

.latest-articles-block,
.latest-chronicles-block,
.international-media-block,
.press-contacts-block,
.prioritized-markets-block{
    a[id]{
        pointer-events: none;
        position: relative;
        z-index: -1;

        &:before {
            content: '';
            display: block;
            height: $header-height-s + 30px;
            margin: -#{$header-height-s + 30px} 0 0;
            
            @media all and ($breakpoint-header-width) and ($breakpoint-header-height) {
                height: $header-height + 30px;
                margin-top: -#{$header-height + 30px};
            }
        }
    }
}