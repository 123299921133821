@import "../../style/vars/_all";
@import "../../style/vars/_full-page-blocks-mixins";

$text-bg: rgba(#fff, 0.8);

:global {
    .hero-block {
        width: 100%;
        height: 100vh;
        position: relative;
        overflow: hidden;
        z-index: 2;
        padding-top: 37vh;
        text-align: center;

        @media all and ($breakpoint-framework-width) {
            padding-top: 32vh;
        }

        &__text-container {
            position: relative;
            z-index: 2;
            align-self: flex-start;
            align-self: center;
            text-align: center;
            max-width: 1000px;
            margin: 0 auto;
            padding: 0 20px;
            box-sizing: content-box;
        }

        &__photographer{
            display: none;
            @media all and ($breakpoint-framework-width) {
                display: block;
            }
        }

        &__heading {
            margin: 0;
            font-size:34px;
            line-height: 67px;
            color: $color-content-text;
            font-weight: 500;
            display: block;

            @media all and ($breakpoint-framework-width) {
                font-size:60px;
                line-height: 100px;
            } 

            @media all and ($breakpoint-framework-width) and ($breakpoint-framework-height-large) {
                font-size:96px;
                line-height: 155px;
            } 

            &-wrapper {
                @include inline-text-with-bg($text-bg, 15px, 7px, 15px);

                @media all and ($breakpoint-framework-width) {
                    @include inline-text-with-bg($text-bg, 25px, 10px, 40px);
                }
            }

            & + .hero-block__text{
                margin-top:17px;
                @media all and ($breakpoint-framework-width) {
                    margin-top:40px;
                }
            }
        }

        &__text {
            font-size:18px;
            line-height: 41px;
            color: $color-content-text;
            font-weight: 500;
            white-space: pre-line;

            @media all and ($breakpoint-framework-width) {
                font-size:28px;
                line-height: 58px;
            } 
            &-wrapper {

                @include inline-text-with-bg($text-bg, 8px, 8px, 8px);

                @media all and ($breakpoint-framework-width) {
                    @include inline-text-with-bg($text-bg, 12px, 8px, 10px);
                }
            }

            
        }
    }
}
