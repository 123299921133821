@mixin customs-quotas-panel {
    padding: $spacing-m 0;
    background-color: $color-background-light;

    @media all and ($breakpoint-framework-width) {
        padding: $spacing-l 0 80px;
    }
}

@mixin customs-quotas-panel-heading {
    display: inline;
    vertical-align: middle;
    font-size: 24px;
    line-height: 1.25;
    letter-spacing: -0.1px;

    @media all and ($breakpoint-framework-width) {
        font-size: 32px;
    }

    &-holder {
        margin-bottom: $spacing-sm;
    }
}

@mixin customs-quotas-panel-content {
    max-width: 820px;
}