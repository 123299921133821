@import "swiper";
@import "../../style/vars/_all";

$rootClass: ".image-carousel-block";

:global {
  #{$rootClass} {
    margin: 25px auto;
    padding: 0;
    box-sizing: content-box;
    overflow: hidden;

    @media all and ($breakpoint-framework-width) {
      margin: 50px auto;
      padding: 0 $spacing-sm;
    }

    @media all and ($breakpoint-wide-l) {
      margin: 100px auto;
      padding-left: 50px;
      padding-right: 50px;
    }

    &__slide {
      max-width: calc(#{$medium-container-width} + #{$spacing-sm} * 2);
      margin: 0 auto;
      padding: 0 0 10px;

      @media all and (min-width: $content-width) {
        padding: 0 $spacing-sm 30px;
      }
    }

    &__slides {
      @media all and (min-width: $content-width) {
        margin: 0 -#{$spacing-sm};
      }
    }

    &__thumbnails,
    &__active-slide-caption {
      max-width: $content-width;
      margin-right: auto;
      margin-left: auto;
      padding: 0 $spacing-sm;
      box-sizing: content-box;

      @media all and (max-width: calc(#{$breakpoint-ipad} - 1px)) {
        overflow: hidden;
      }
    }

    &__thumbnails {
      @media all and (max-width: calc(#{$breakpoint-ipad} - 1px)) {
        overflow: hidden;
      }
    }

    &__thumbnails .swiper {
      overflow: visible;
      @media all and (min-width: $breakpoint-ipad) {
        overflow: hidden;
      }
      .swiper-wrapper {
        display: flex;

        .swiper-slide {
          align-self: center;

          @media all and ($breakpoint-framework-width) {
            align-self: flex-end;
          }
        }
      }

      .swiper-slide {
        @media all and (max-width: calc(#{$breakpoint-ipad} - 1px)) {
          width: 65px !important;

          &.swiper-slide-thumb-active {
            width: 85px !important;
          }
        }
      }
    }

    &__active-slide-caption {
      margin-top: 10px;
      @media all and ($breakpoint-framework-width) {
        margin-top: 30px;
      }

      &_single {
        margin-top: 0;
        @media all and ($breakpoint-framework-width) {
          margin-top: -10px;
        }
      }
    }

    &__thumbnail {
      display: block !important;
      position: relative;
      cursor: pointer;

      &:before {
        @include full-size;
        content: "";
        background: black;
        z-index: 2;
        opacity: 0.5;
        transition: opacity 0.5s ease-in-out;
      }

      &__image {
        transition: padding 0.5s ease-in-out;
      }
    }
  }

  .swiper-slide-active #{$rootClass}__thumbnail {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  }

  #{$rootClass}__thumbnail:hover:before,
  .swiper-slide-active #{$rootClass}__thumbnail:before {
    opacity: 0;
  }

  #{$rootClass}_in-rich-text {
    @include block-indents-inside-rich-text;
    padding-left: $spacing-sm;
    padding-right: $spacing-sm;

    &,
    #{$rootClass}__slides {
      margin-left: -#{$spacing-sm};
      margin-right: -#{$spacing-sm};
    }

    @media all and ($breakpoint-framework-width) {
      margin-left: -#{$spacing-sm};
      margin-right: -#{$spacing-sm};
      padding-left: $spacing-sm;
      padding-right: $spacing-sm;

      #{$rootClass}__slides {
        margin-left: -#{$spacing-sm};
        margin-right: -#{$spacing-sm};
      }
    }

    #{$rootClass}__thumbnails,
    #{$rootClass}__active-slide-caption {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
