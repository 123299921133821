@import "../../style/vars/_all";

:global {
    .customs-quotas-view-terms-and-conditions {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.44;
        color: $color-deep-blue;

        @media all and ($breakpoint-framework-width) {
            font-size: 18px;
        }

        &__button {
            background: none;
            border: none;
            font-size: inherit;
            line-height: inherit;
            color: inherit;
            cursor: pointer;
            box-shadow: inset 0 -1px 0 currentColor;

            &:hover {
                box-shadow: none;
            }

            &:focus-visible {
                box-shadow: none;
                outline: auto !important;
            }
        }
    }   
}
