@import "../../style/vars/_all";

$rootClass: ".image-text-block";

:global {
    #{$rootClass} {
        @include page-block;
        max-width: $content-width;
    
        &__container {
            position: relative;

            @media all and ($breakpoint-framework-width) {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            &_reversed {
                @media all and ($breakpoint-framework-width) {
                    flex-direction: row-reverse;
                }

                #{$rootClass}__content-col {
                    @media all and ($breakpoint-framework-width) {
                        padding-left: 0;
                        padding-right: $spacing-m;
                    }
                }
            }
        }
    
        &__image-col {
            box-shadow: $shadow-block;
            @media all and ($breakpoint-framework-width) {
                width: 50%;
            }
        }

        &__content-col {
            @media all and ($breakpoint-framework-width) {
                width: 50%;
                padding-left: $spacing-m;
            }
        }
    
        &__photo-label {
            position: absolute;
            left: 15px;
            bottom: 15px;
            padding: 5px 10px;
            background-color: $color-black-transparent-50;
            font-size: 14px;
            line-height: 1.5;
            color: $color-white;
    
            @media all and ($breakpoint-framework-width) {
                font-size: 16px;
                padding: 10px 15px;
            }
        }
    
        &__caption {
            margin: 10px auto 0;
            max-width: $content-width;
    
            @media all and ($breakpoint-framework-width) {
                margin-top: 20px;
            }
        }
    }

    #{$rootClass}_in-rich-text {
        @include block-indents-inside-rich-text;
    }
}
