@import "../../style/vars/_all";

.row {
    background: $color-cool-gray-25;
    border-top: solid 2px $color-white;
    cursor: pointer;
    position: relative;
    padding-right: 76px;

    td {
        padding: 0 $spacing-m;
        height: 80px;
        word-break: break-word;

        &:first-child {
            font-weight: 500;
        }

        &.TdWithIcon {
            @media all and (min-width: 1024px) {
                padding-right: 76px;
            }
        }
    }

    .toggleIcon {
        display: block;
        cursor: pointer;
    }

    &:focus-visible,
    &:hover {
        background: $color-sea-blue-100;
        color: $color-white;

        .toggleIcon {
            fill: $color-white;
        }
    }

    @media all and (max-width: 1023px) {
        display: block;
        width: 100%;
        padding: $spacing-s $spacing-sm;

        td {
            display: block;
            padding: 0;
            height: auto;

            &:first-child {
                padding-right: $spacing-m;
            }
        }
    }
}

.toggleIcon {
    width: 16px;
    white-space: nowrap;
    background: none;
    border: none;
    position: absolute;
    top: 18px;
    right: $spacing-sm;
    fill: $color-deep-blue-100;

    @media all and (min-width: 1024px) {
        top: 50%;
        right: 30px;
        margin-top: -8px;
    }
}

.open {
    background-color: $color-sea-blue-100;
    color: $color-white;

    .toggleIcon {
        transform: rotate(180deg);
        fill: $color-white;
    }
}