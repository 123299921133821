@import "../../style/shared-components-styles/_typography-styles";

$external-icon-width: 20px;
$external-icon-height: 20px;
$external-icon-margin: 5px;

a {
    text-decoration: none;
    cursor: pointer;

    &.external {
        @extend %link-blue;
        @include typo("small-nf");
        position: relative;
        margin-right: $external-icon-width + $external-icon-margin;

        &:after {
            position: absolute;
            top: 50%;
            right: -($external-icon-width + $external-icon-margin);
            content: "";
            display: inline-block;
            background-image: url(../assets/external.svg);
            background-size: contain;
            width: $external-icon-width;
            height: $external-icon-height;
            transform: translateY(-50%);
        }
    }

    &.external-negative {
        color: $color-white;
        box-shadow: inset 0 -2px 0 rgba($color-white, .25);

        &:after {
            background-image: url(../assets/external-negative.svg);
        }

        &:hover,
        &.hover {
            box-shadow: inset 0 -2px 0 $color-white;
        }
    }

    &.external-dark {
        color: $color-deep-blue-100;
        box-shadow: inset 0 -2px 0 $color-deep-blue-25;

        &:after {
            background-image: url(../assets/external-dark.svg);
        }

        &:hover,
        &.hover {
            box-shadow: inset 0 -2px 0 $color-deep-blue-100;
        }
    }
}

.wysiwyg {
    @include base-typography-styles;
}
