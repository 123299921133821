@import "../../style/vars/_all";



:global {
    .saving-progress-popup {
        &__close-btn {
            display: flex;
            align-items: center;
            margin-left: auto;
            border: none;
            background: none;
            cursor: pointer;
            color: $color-white;
            position: relative;
            margin-top: -30px;
            margin-right: -38px;
            margin-bottom: 20px;

            &-icon {
                fill: $color-white;
                width: 21px;
                height: 21px;
            }
        }

        &__content {
            display: inline-block;
            vertical-align: middle;
            width: 100%;
            max-width: 450px;
            padding: 50px 60px 60px !important;
            position: relative;
            text-align: left;
            margin: auto;
            position: static !important;
            border: none !important;
            overflow: visible !important;
            border-radius: 0 !important;
            background: $color-deep-blue;
            outline: none;
            border: none;
        }

        &__text {
            color: white;
            font-size: 26px;
            line-height: 31px;

            &_failed{
                color: $color-error-text;
            }
        }

        &__icon-container {
            display: block;
            position: relative;
            width: 90px;
            height: 90px;
            margin: 15px auto 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__loading-icon {
            & :local {
                animation: rotate 1000ms linear infinite;
            }
            display: block;
            will-change: transform;
            width: 90px;
            height: 90px;
            border: 20px solid $color-sea-blue-45;
            border-right-color: transparent;
            border-radius: 50%;
            position: absolute;
            top: 45px;
            margin: -45px auto 0 auto;
        }

        &__countdown {
            color: white;
            width: 90px;
            height: 90px;
            position: absolute;
            top: 0;
            left: 0;
            font-size: 26px;
            line-height: 90px;
            text-align: center;
            font-weight: 400;

            &:before {
                content: '';

                & :local {
                    animation: count 10s steps(10, start);
                    animation-fill-mode: forwards;
                }

            }
        }

        &__success-icon {
            width: 68px;
            height: 44px;
            position: relative;
            transform-origin: center;
            transform: rotate(-53deg);

            &-part1 {
                position: absolute;
                top: 0;
                left: 0;
                width: 20px;
                background-color: $color-sea-green-50;

                & :local {
                    display: block;
                    animation: animate-success-icon-part1 150ms linear;
                    animation-fill-mode: forwards;
                    animation-delay: 150ms;
                }

            }

            &-part2 {
                position: absolute;
                bottom: 0;
                left: 10px;
                height: 20px;
                background-color: $color-sea-green-50;

                & :local {
                    display: block;
                    animation: animate-success-icon-part2 200ms linear;
                    animation-delay: 300ms;
                    animation-fill-mode: forwards;
                }
            }
        }


        .ReactModal {
            &__Body--open {
                overflow: hidden !important;
            }

            &__Overlay {
                overflow-y: auto;
                background-color: rgba(black, 0.4) !important;
                z-index: $z-index-modal;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                overflow-y: auto;
                text-align: center;
                opacity: 0;
                transition: all 500ms ease-in-out;

                &--after-open {
                    opacity: 1;
                }

                &--before-close {
                    opacity: 0;
                }

                &:before {
                    content: '';
                    display: inline-block;
                    vertical-align: middle;
                    height: 100%;
                }
            }
        }
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(-360deg)
    }
}

@keyframes count {
    0% {
        content: '10';
    }
    10% {
        content: '9';
    }
    20% {
        content: '8';
    }
    30% {
        content: '7';
    }
    40% {
        content: '6';
    }
    50% {
        content: '5';
    }
    60% {
        content: '4';
    }
    70% {
        content: '3';
    }
    80% {
        content: '2';
    }
    90%{
        content: '1';
    }
    100% {
        content: '1';
    }
}

@keyframes animate-success-icon-part1 {
    0% {
        height: 0px
    }

    100% {
        height: 44px
    }
}

@keyframes animate-success-icon-part2 {
    0% {
        width: 0px
    }

    100% {
        width: 76px
    }
}