@import "../../style/vars/_all";
@import "../../style/vars/_image-mixins";

:global {     
  .responsive-image {
    display: block;
    width: 100%;
    background-repeat: no-repeat;
    overflow: hidden;
  
    &_fluid{
      @include fluid-responsive-image();
    }
  
    &__img{
      display: block;
      width: 100%;
    } 
    
    &_size {
      &_market-insight-useful-pages-block{
        @include original-proportions-image(72, 72);
      }
      &_image-with-popup,
      &_video-block {
        @include fixed-proportion-image(1200, 630);
      }
      &_feature-article-author {
        @include fixed-proportion-image(100, 100);
      }

      &_image-text-block {
        @include fixed-proportion-image(410, 300);
      }

      &_more-feature-articles{
        @include fixed-proportion-image(380, 214);
      }
      &_image-carousel-thumbnail{
        @include fixed-proportion-image(65, 50);

        @media all and ($breakpoint-framework-width) {
          @include fixed-proportion-image(150, 113);
        }

        &_active{
          @include fixed-proportion-image(85, 65);
          @media all and ($breakpoint-framework-width) {
            @include fixed-proportion-image(150, 118);
          }
        }
      }
      &_market-insight-latest-list-item {
        @include fixed-proportion-image(344, 242);
      }
      &_prioritized-markets-block {
        @media all and (min-width: $breakpoint-card-single) {
          @include fixed-proportion-image(1244, 898);
        }
      }
      &_market-insight-report-item,
      &_article-list-item {
        @include fixed-proportion-image(142, 116);
        @media all and ($breakpoint-framework-width) {
          @include fixed-proportion-image(223, 223);
        }
      }  
      
      &_market-insight-contact-persons-block {
        @include fixed-proportion-image(46, 46);
      }
      
       &_country-page-image-video-text-block{
        @include fixed-proportion-image(634, 356);
      }

      &_country-page-latest-news-block{
        @include fixed-proportion-image(380, 224);
      }

      &_country-page-text-cta-image-block{
        @include fixed-proportion-image(694, 390);
      }

      &_country-page-employee{
        @include fixed-proportion-image(86, 86);
      }

      &_country-page-webinars-block{
        @include fixed-proportion-image(634, 356);
      }
    }
  }
}