@import "../../style/vars/_all";

:global{
    .published-date{
        display: inline-flex;
        font-size: convert-to-rem(16px);
        line-height: convert-to-rem(24px);
        font-weight: 400;

        &__time{
            margin-left: 5px;
        }
    }
}