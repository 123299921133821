@import "../../style/vars/_all";
@import "../../style/shared-components-styles/market-insight-reports-blocks-styles";

$column-bottom-indent: 25px;

:global {
    .market-insight-downloadable-statistics-block {
        $self: &;
        padding: 48px 0;
        margin: 35px 0;
        background-color: $color-sea-blue-15;

        @media all and ($breakpoint-framework-width) {
            padding: 54px 0;
            margin: 60px 0 62px;
        }

        &__container {
            @include container-narrow;
        }

        &__heading {
            @include market-insight-reports-block-heading;
        }

        &__columns {
            margin-top: 47px;
           
            @media all and ($breakpoint-framework-width) {
                margin-top: 50px;
                margin-bottom: -$column-bottom-indent;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }

        &__column {
            margin-bottom: 20px;

            @media all and ($breakpoint-framework-width) {
                width: 32%;
                padding-right: 20px;
                margin-bottom: $column-bottom-indent;
            }
        }

        &__link {
            #{$self} & {
                font-size: 18px;

                @media all and ($breakpoint-framework-width) {
                    font-size: 20px;
                }
            }

            &-holder {
                margin-top: 43px;

                @media all and ($breakpoint-framework-width) {
                    margin-top: 40px;
                }
            }
        }
    }

    .market-insight-downloadable-statistics-block-item {
        &__heading {
            display: inline;
            font-size: convert-to-rem(20px);
            line-height: convert-to-rem(32px);
            font-weight: 500;

            @media all and ($breakpoint-framework-width) {
                font-size: convert-to-rem(21px);
                font-weight: 400;
            }

            &-row {
                display: flex;
                margin-bottom: 20px;

                @media all and ($breakpoint-framework-width) {
                    margin-bottom: 18px;
                }
            }
        }

        &__icon {
            display: inline-block;
            margin: 5px 0 -2px 10px;
            width: 16px;
            height: 21px;
            flex-shrink: 0;
            fill: $color-deep-blue;
        }

        &__link {
            &-item {
                margin-bottom: 2px;

                @media all and ($breakpoint-framework-width) {
                    margin-bottom: 3px;
                }
            }
        }

        &__columns {
            display: flex;

            @media all and ($breakpoint-framework-width) {
                display: block;
            }
        }

        &__column {
            &:not(:last-child) {
                padding-right: 8%;

                @media all and ($breakpoint-framework-width) {
                    padding-right: 0;
                }
            }
        }
    }
}
