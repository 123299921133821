@import "../../style/vars/_all";
@import "../../style/shared-components-styles/country-page-styles";

:global {
    .country-page-social-link {
        $self: &;
        color: white !important;
        display: flex;
        flex-direction: column;

        &:hover,
        &:focus-visible {
            box-shadow: inset 0 -1px 0px transparent !important;
        }

        &__text {
            align-self: center;
            box-shadow: inset 0 -2px 0px white !important;
            transition: box-shadow 0.3s;

            #{$self}:hover &,
            #{$self}:focus-visible & {
                box-shadow: inset 0 -1px 0px transparent !important;
                text-decoration: none;
            }
        }

        &__icon {
            align-self: center;
            width: 24px;
            height: 24px;
            margin: 0 0 2px;
        }
    }
}