.DateRangePicker {
  position: relative;
  display: block !important;
  background: $color-white;
}

.DateRangePicker_picker__portal{
  background-color: rgba(2, 16, 43, 0.8) !important;
  z-index: 10;
}

.DateRangePicker_picker,
.DateRangePicker {

  input[type="text"].DateInput_input {
    border: 10px solid #fff;
    background: $color-white;
    border-radius: 13px;
    padding: 0 10px;

    &:focus {
      background: $color-sea-blue-100;
      color: $color-white;
      box-shadow: none;

      &::placeholder,
      &::-webkit-input-placeholder,
      &:-moz-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder {
        color: $color-white ;
        opacity: 1 ;
      }
    }
  }
  .DateRangePickerInput,
  .DateRangePickerInput__withBorder,
  .DayPicker__withBorder {
    border: none;
  }
  .DateInput_fang {
    margin-top: 1px;
  }
  .DayPickerKeyboardShortcuts_show {
    display: none;
  }
  .CalendarDay{
    padding: 0;
    box-sizing: border-box;
    color: #001e64;
    cursor: pointer;
    width: 39px;
    height: 31px;
  }

  .CalendarDay__default{
    &,
    &:hover{
      border: none;
    }

    &:hover{
      background: $color-sea-blue-100;
      color: $color-white;
      border-radius: 4px;
    }
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: $color-sea-blue-100;
    color: $color-white;
    border: none;
    border-radius: 0;
  }

  .CalendarDay__hovered_span,
  .CalendarDay__selected_span {
    &,
    &:hover {
      color: $color-white;
      background: $color-deep-blue-100;
      border: none;
      border-radius: 0;
    }
  }

  .CalendarDay__selected_start{
    border-radius: 4px 0 0 4px;
  }

  .CalendarDay__selected_end{
    border-radius: 0 4px 4px 0;
  }

  .CalendarMonth_table tr{
    border-top: solid 1px #fff;
    border-bottom: solid 1px #fff;
  }
}