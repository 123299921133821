@import "../../style/vars/_all";

:global {
    .simple-tag {
        display: inline-flex;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.2;
        padding: 8px;
        color: $color-deep-blue-100;
        background-color: $color-sea-blue-15;
        border-radius: 4px;

        @media all and ($breakpoint-framework-width) {
            font-size: 14px;
        }
    }
}