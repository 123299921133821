%filterItem {
    background: $color-cool-gray-25;
    border-top: solid 2px $color-white;
    cursor: pointer;

    svg {
        display: block;
    }

    &:hover {
        background: $color-sea-blue-100;
        color: $color-white;

        svg {
            fill: $color-white;
        }
    }
}

%filterTable {
    width: 100%;
    border-collapse: collapse;

    td,
    th {
        padding: 20px 10px;
    }
}

%profileImage {
    height: 100px;
    width: 100px;
    overflow: hidden;
    border-radius: 50%;

    img {
        position: relative;
        left: 50%;
        top: 50%;
        min-width: 100%;
        max-height: 100%;
        transform: translate(-50%, -50%);
    }
}

@mixin center-padding($width) {
    padding-left: calc(49.99% - #{$width/2});
    padding-right: calc(49.99% - #{$width/2});
}

@mixin visually-hidden() {
    height: 0 !important;
    visibility: hidden;
}

@mixin visually-hidden-no-space {
    position: absolute;
    left: -999em;
    clip: rect(0, 0, 0, 0);
    opacity: 0;
    overflow: hidden;
}

@mixin fluid($properties, $min-vw: ($breakpoint-mobile + 1px), $max-vw: $breakpoint-desktop) {
    @each $property, $values in $properties {
        @if (length($values) > 1) {
            #{$property}: nth($values, 1);
        } @else {
            #{$property}: $values;
        }
    }

    @media screen and (min-width: $min-vw) {
        @each $property, $values in $properties {
            @if (length($values) > 1) {
                #{$property}: calc(
                    #{nth($values, 1)} +
                        #{strip-unit(nth($values, 2) - nth($values, 1))} *
                        (100vw - #{$min-vw}) /
                        #{strip-unit($max-vw - $min-vw)}
                );
            }
        }
    }

    @media screen and (min-width: $max-vw) {
        @each $property, $values in $properties {
            @if (length($values) > 1) {
                #{$property}: nth($values, 2);
            }
        }
    }
}

@function strip-unit($value) {
    @if unit($value) == "" {
        @return $value;
    }
    @return calc($value / 1);
}

@mixin typo($type: body) {
    @if map-has-key($types, $type) {
        @include fluid(map-get($types, $type));
    }
}

@mixin link($color) {
    cursor: pointer;
    color: $color-sea-blue-100;
    text-decoration: none;
    box-shadow: inset 0 -2px 0 $color-sea-blue-25;
    font-weight: 400;

    &:hover,
    &.hover,
    &:focus {
        box-shadow: inset 0 -2px 0 $color-sea-blue-100;
    }
}

@mixin object-fit($fit: cover, $position: null) {
    -o-object-fit: $fit;
    object-fit: $fit;
    @if $position {
        -o-object-position: $position;
        object-position: $position;
    }
    height: 100%;
    width: 100%;
    display: block;
    backface-visibility: hidden;
    transition: transform 0.35s;
}

@mixin full-size {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: block;
}

@mixin page-header {
    position: relative;
    display: flex;
}

@mixin page-header-with-bg {
    overflow: hidden;
    z-index: $z-index-list-header;
    min-height: 300px;
    text-shadow: 0 2px 4px $color-modal;
    background-color: $color-modal;

    @media all and ($breakpoint-header-width) {
        min-height: 450px !important;
    }
}

@mixin page-header-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    opacity: 0.4;
    filter: blur(5px);
    overflow: hidden;
    transform: scale(1.04);

    @media all and ($breakpoint-header-width) {
        opacity: 0.9;
        filter: none;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 57%;
        background: linear-gradient(-90deg, rgba(#030d22, 0), rgba(#030d22, 0.5));
    }

    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 64%;
        background: linear-gradient(rgba(#030d22, 0), rgba(#030d22, 0.5));
    }
}

@mixin page-header-text {
    position: relative;
    z-index: 2;
    padding: $spacing-m $spacing-sm;
    width: 100%;
    align-self: center;

    @media all and ($breakpoint-header-width) {
        padding: $spacing-m $spacing-l;
        margin: auto;
        max-width: $framework-max-width + ($spacing-l * 2);

        > * {
            max-width: 50%;
        }
    }
}

$tag-margin: 5px;

@mixin tags {
    margin: (-$tag-margin) (-$tag-margin) ($spacing-m - $tag-margin);

    @media all and ($breakpoint-framework-width) {
        margin-bottom: ($spacing-l - $tag-margin);
    }
}

@mixin tag {
    background-color: $color-cool-gray-25;
    padding: 0 $spacing-sm;
    color: $color-deep-blue-100;
    min-height: 50px;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    font-family: $font-family;
    margin: $tag-margin;
    text-align: center;
    display: inline-block;
    vertical-align: middle;

    &:hover,
    &:focus,
    &.active {
        background-color: $color-sea-blue-100;
        color: $color-white;
        outline: none;
    }

    &.active:focus {
        outline: 2px solid $color-sea-blue-100!important;
    }

    &.tag-large {
        min-height: 60px;
        line-height: 60px;
        font-size: inherit;
    }

    @media all and ($breakpoint-framework-width) {
        min-width: 197px;
    }
}

@mixin list-items($negative: false) {
    position: relative;
    background-color: $color-cool-gray-25;
    color: $color-deep-blue-100;
    padding: $spacing-m $spacing-sm $spacing-l;
    display: flex;
    flex-direction: column;

    @media all and (min-width: $breakpoint-card-single) {
        padding: $spacing-l;
        flex-direction: row;
        flex-wrap: wrap;
    }

    @media all and ($breakpoint-wide-l) {
        $padding: calc(50% - (#{$framework-max-width} / 2));
        padding-left: $padding;
        padding-right: $padding;
    }

    @if $negative {
        background-color: $color-white;
    }
}

@mixin see-all {
    position: absolute;
    bottom: $spacing-sm;
    left: $spacing-sm;

    @media all and (min-width: $breakpoint-card-single) {
        position: relative;
        bottom: auto;
        left: auto;
        flex: 0 0 auto;
        align-self: flex-end;
        margin-left: auto;
        margin-bottom: $spacing-m;
        text-align: right;
    }
}

@mixin truncate-text ($rows: 1) {
    display: -webkit-box;
    max-width: 100%;
    line-clamp: $rows;
    -webkit-line-clamp: $rows;
    box-orient: vertical;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin list-item-header {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.6px;
    font-weight: 400;

    @media all and ($breakpoint-framework-width) {
        font-size: 24px;
        line-height: 34px;
    }
}

@mixin hoverable-card {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        height: 100%;
        width: 100%;
        opacity: 0;
        box-shadow: 0 22px 34px rgba(#141D2F, .46);
        transition: opacity 300ms ease-in-out;
    }

    &:hover,
    &:focus,
    .list-items.negative &:hover,
    .list-items.negative &:focus {
        background-color: $color-sea-blue-65!important;
        &:after {
            opacity: 1;
        }
    }
}

@mixin framed-block($overlay-mode: true) {
    position: relative;
    overflow: hidden;

    @if $overlay-mode {
        &:after {
            content: "";
            @include full-size;
            box-shadow: inset 0 0 0 10px $color-white;
            z-index: 1;
            pointer-events: none;
            opacity: 0;
            transition: all 250ms;
    
            @media all and ($breakpoint-framework-width) {
                box-shadow: inset 0 0 0 15px $color-white;
            }
        }

        .responsive-image {
            transition: transform 250ms;
        }

        &:hover {
            &:after {
                opacity: 1;
            }
            .responsive-image {
                transform: scale(0.95);
            }
        }
    } @else {
        border: 10px solid $color-white;

        @media all and ($breakpoint-framework-width) {
            border-width: 15px;
        }
    }
}

@mixin page-block {
    margin: 25px auto;
    padding: 0 $spacing-sm;
    box-sizing: content-box;

    @media all and ($breakpoint-framework-width) {
        margin: 75px auto;
    }

    @media all and ($breakpoint-wide-l) {
        margin: 100px auto;
        padding-left: 50px;
        padding-right: 50px;
    }
}

@mixin block-indents-inside-rich-text() {
    padding-left: 0;
    padding-right: 0;
    margin-top: 20px;
    margin-bottom: 20px;

    @media all and ($breakpoint-framework-width) {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    @media all and ($breakpoint-wide-l) {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

@mixin no-break-inside() {
    display: inline-block;
    width: 100%;
    page-break-inside: avoid;
}

@mixin container-base($width: $container-width) {
    padding: 0 $spacing-sm;
    @media all and ($breakpoint-framework-width) {
        padding: 0 $spacing-l;
    }

    @media all and ($breakpoint-wide-l) {
        $padding: calc(50% - (#{$width} / 2));

        padding-left: $padding;
        padding-right: $padding;
    }
}

@mixin container() {
    @include container-base;
}

@mixin container-narrow() {
    @include container-base($container-width-narrow);
}

@mixin container-medium() {
    @include container-base($container-width-medium);
}

@mixin container-wide() {
    padding: 0 $spacing-sm;
    max-width: $container-width-wide;
    margin: 0 auto 80px;

    @media all and ($breakpoint-framework-width) {
        padding: 0 $spacing-l;
    }
}

@mixin shadowed-box() {
    border: 1px solid $color-cool-gray-50 !important;
    border-radius: 8px !important;
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.04);
}

@mixin focus-outline($outline-offset: 5px, $outline-width: 4px, $outline-color: $color-white, $useHoverEffect: false) {
    outline: none;
    transition: outline-offset 350ms;

    &:focus-visible {
        outline: $outline-width solid $outline-color !important;
        outline-offset: $outline-offset !important;
    }

    @if $useHoverEffect {
        &:hover {
            outline: $outline-width solid $outline-color !important;
            outline-offset: $outline-offset !important;
        }
    }
}


