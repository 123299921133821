.image-block {
    margin: 0 0 $spacing-l;

    img {
        display: block;
        max-width: 100%;
        height: auto;
    }

    figcaption {
        @extend %typo-sub;
        margin-top: 10px;

        .figure-photographer-label {
            text-transform: uppercase;
        }

        p {
            margin: 0;
        }
    }
}
