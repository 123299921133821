.list-items {
    @include list-items;

    .article & {
        @media print {
            display: none;
        }
    }

    > h2 {
        width: 100%;
        margin-bottom: $spacing-m;
    }

    > a.see-all {
        // width: 100%;
        position: absolute;
        bottom: $spacing-sm;
        left: $spacing-sm;
    }

    .list-items-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        @include list-item-header();
    }

    @media all and (min-width: $breakpoint-card-single) {
        > h2 {
            flex: 0 0 auto;
            width: 50%;
        }

        > a.see-all {
            position: relative;
            bottom: auto;
            left: auto;
            flex: 0 0 auto;
            align-self: flex-end;
            margin-left: auto;
            margin-bottom: $spacing-m;
            text-align: right;
        }
    }

    &.negative {
        background-color: $color-white;
    }
}

.list-item {
    & > a:focus,
    &:focus {
        outline: 2px solid $color-sea-blue-100;
    }
}

.list-items-content {
    > .list-item {
        display: block;
        text-decoration: none;
        color: $color-deep-blue-100;
        margin: 0 0 2px;
        background: $color-white;

        .list-items.negative &, 
        .list-items.negative & .card {
            background: $color-cool-gray-25;
        }

        &.employee {
            margin-bottom: $spacing-s;
        }

        .external-site-link-block {
            margin-bottom: 0;
        }

        &.marketgroup {
            margin-bottom: $spacing-m;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.list-items-spaced {
        @media all and ($breakpoint-framework-width) {
            margin: 0 (-$spacing-m);

            > .list-item {
                padding: 0 $spacing-m;
            }
        }
    }

    @media all and (min-width: $breakpoint-card-single) {
        display: flex;
        flex-wrap: wrap;
        flex: 1 0 100%;
        margin: 0 -1px;

        &:not(.three-grid) {
            order: 3;
        }

        > .list-item {
            flex: 0 0 auto;
            width: 50%;
            border-left: solid 1px transparent;
            border-right: solid 1px transparent;

            &.employee {
                flex: 0 0 auto;
                width: calc(100% / 3);
            }
        }
    }

    @media all and (min-width: 1380px) {
        .list-item.marketgroup {
            width: calc(100% / 3);
        }
    }

    &.grouped,
    &.section-page-content {
        > .list-item {
            &:nth-child(4n+5) {
                margin-top: $spacing-l;
            }

            @media(min-width: 60rem) {
                &:nth-child(4n+6) {
                    margin-top: $spacing-l;
                }
            }
        }
    }

    &.three-grid {
        > .list-item {
            &:nth-child(9n+10) {
                margin-top: $spacing-l;
            }

            @media all and (min-width: 60rem) {
                margin: 0 0 2px;

                &:nth-child(9n+9) {
                    margin-right: 50%;
                }

                &:nth-child(9n+10),
                &:nth-child(9n+11) {
                    margin-top: $spacing-l;
                }
            }

            @media all and (min-width: 90rem) {
                width: calc(100% / 3);

                &:nth-child(9n+9) {
                    margin-right: 0;
                }

                &:nth-child(9n+12) {
                    margin-top: $spacing-l;
                }
            }
        }
    }
}
