@import "_spacings";

$breakpoint-card-single-max: 959px; // 60rem * 16 - 1
$breakpoint-card-single: 60rem;

$breakpoint-card-small-max: 1299px; // 81.25rem * 16 - 1
$breakpoint-card-small: 81.25rem;

$breakpoint-card-image: 600px;

$breakpoint-decal-small: "min-width: 72.8125rem";

$breakpoint-mobile: 375px;
$breakpoint-desktop: 1440px;
$breakpoint-ipad: 768px;

$breakpoint-article-large: "min-width: 60rem";

// Breakpoint at which the menu and featured employee on the start page "snaps"
// into a right margin
$breakpoint-menu-snapping: "min-width: 1440px";  

/************/

$framework-max-width: 1320px;

$breakpoint-framework-max-px: 959px;
$breakpoint-framework-px: 960px;
$breakpoint-framework-height-px: 750px;
$breakpoint-framework-height-large-px: 900px;

$breakpoint-framework-height: "min-height: #{$breakpoint-framework-height-px}";
$breakpoint-framework-width: "min-width: #{$breakpoint-framework-px}";
$breakpoint-framework-width-max: "max-width: #{$breakpoint-framework-px - 1}";
$breakpoint-framework-max: "max-width: #{$breakpoint-framework-max-px}";
$breakpoint-framework-height-large: "min-height: #{$breakpoint-framework-height-large-px}";


$breakpoint-wide: "min-width: #{$framework-max-width}";
$breakpoint-wide-s: "min-width: #{$framework-max-width + ($spacing-s * 2)}";
$breakpoint-wide-sm: "min-width: #{$framework-max-width + ($spacing-sm * 2)}";
$breakpoint-wide-m: "min-width: #{$framework-max-width + ($spacing-m * 2)}";
$breakpoint-wide-l: "min-width: #{$framework-max-width + ($spacing-l * 2)}";
$breakpoint-wide-xl: "min-width: #{$framework-max-width + ($spacing-xl * 2)}";


$breakpoint-header-height: $breakpoint-framework-height;
$breakpoint-header-width: $breakpoint-framework-width;
$breakpoint-header-width-max: $breakpoint-framework-max;
