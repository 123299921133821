.last-week-stats-block {
    width: 100%;
    padding: $spacing-sm;
    border: solid 1px $color-cool-gray-25;
    margin: 0 1px $spacing-m; // Grid has 1px white border

    > ul {
        margin: 0 (-#{$spacing-s});
        display: flex;
        flex-wrap: wrap;

        > li {
            margin: $spacing-sm $spacing-s 0;
        }
    }

    @media screen and (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;

        .header {
            margin-right: auto;
        }

        > ul {
            > li {
                margin-top: 0;
            }
        }
    }

    @media screen and (min-width: 1025px) {
        display: none;
    }
}
