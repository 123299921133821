@import "../../style/vars/_all";

%open-header {
}

.block {
    position: relative;
    margin: 0 0 $spacing-m $spacing-xl;

    h4 {
        svg {
            display: none;
        }
    }

    .content {
        margin: $spacing-s 0 $spacing-m;
    }

    @media all and (max-width: 959px) {
        margin: 0 0 2px;

        .content {
            margin: $spacing-m 0;
        }

        h4 {
            cursor: pointer;
            position: relative;
            padding: $spacing-s $spacing-sm;
            background-color: $color-cool-gray-25;

            svg {
                display: block;
                float: right;
                fill: $color-deep-blue-100;
                margin-top: 7px;
                transform: rotate(180deg);
            }

            &.active,
            &.hover,
            &:hover {
                color: $color-white;
                background-color: $color-sea-blue-100;

                svg {
                    fill: $color-white;
                }
            }
        }

        &.closed {
            h4 svg {
                transform: rotate(0);
            }

            .content {
                display: none;
            }
        }
    }
}

.image {
    display: block;
    max-width: 85px;
    max-height: 85px;
    margin: 0 auto $spacing-m;

    @media all and (min-width: 60rem) {
        position: absolute;
        top: 0;
        left: -($spacing-xl - 10px);
        max-width: 50px;
        max-height: 50px;
    }
}
