@import "../../style/vars/_all";
@import "../../style/shared-components-styles/country-page-styles";

:global {
    .country-page-text-cta-image-block {
        @include container-wide;
        display: flex;
        flex-direction: column;

        @media (min-width: $breakpoint-ipad) {
            flex-direction: row-reverse;
            justify-content: space-between;
        }

        &__text-contaner,
        &__image-contaner {
                @media (min-width: $breakpoint-ipad) {
                    flex: 0 0 #{$item-half-width};
                }
        }

        &__image-contaner {
            margin-bottom: 56px;

            @media (min-width: $breakpoint-ipad) {
                margin-bottom: 0;
            }
        }

        &__text-contaner{
            align-self: center;
        }

        &__heading {
            @include country-page-block-heading;
        }

        &__intro {
            color: $color-deep-blue;
        }

        &__cta-button {
            margin-top: 26px;
            width: 100%;
            justify-content: center;
            
            @media (min-width: $breakpoint-ipad) {
                justify-content: flex-start;
                width: auto;
            }
        }
    }
}