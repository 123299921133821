@import "../../style/vars/_all";
@import "../../style/shared-components-styles/market-insight-styles";

$list-item-indent: 90px;
$list-item-indent-tablet: 25px;
$list-item-indent-mobile: 15px;

:global {
    .market-insight-calendar-block {
        $self: &;
        margin-bottom: 60px;

        &__container {
            @include container;
            overflow: hidden;
        }

        &__content {
            margin-top: 32px;

            &_small-indent {
                margin-top: 24px;
            }
        }

        &__heading {
            @include market-insight-section-heading;
        }

        &__link {
            body & {
                font-size: 16px;

                @media all and ($breakpoint-framework-width) {
                    font-size: 18px;
                }
            }

            &-holder {
                margin-top: 40px;

                @media all and ($breakpoint-framework-width) {
                    text-align: right;
                    margin-top: 28px;
                }
            }
        }

        &__list {
            display: flex;
            flex-wrap: nowrap;
            margin-left: -20px;
            margin-right: -20px;
            padding-left: 20px;
            overflow-x: auto;

            @media all and ($breakpoint-framework-width) {
                flex-wrap: wrap;
                padding-left: 0;
                margin-left: 0;
                margin-right: -#{$list-item-indent-tablet};
                margin-bottom: -#{$list-item-indent-tablet};
            }

            @media all and ($breakpoint-wide-sm) {
                margin-right: -#{$list-item-indent};
                margin-bottom: -48px;
            }
        }

        &__item {
            position: relative;
            margin-right: $list-item-indent-tablet;
            flex: 0 0 75vw;

            &:not(:last-child) {
                padding-right: 5%;

                @media all and ($breakpoint-framework-width) {
                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        right: 0;
                        top: 30px;
                        bottom: 30px;
                        width: 1px;
                        background-color: $color-cool-gray-45;
                    }
                }
            }

            @media all and ($breakpoint-framework-width) {
                margin-bottom: $list-item-indent-tablet;
                width: auto;
                flex: 0 0 calc(34% - #{$list-item-indent-tablet});

                &:last-child {
                    flex: 0 0 calc(32% - #{$list-item-indent-tablet});
                }
            }

            @media all and ($breakpoint-wide-sm) {
                flex: 0 0 calc(36% - #{$list-item-indent});
                margin-right: $list-item-indent;
                margin-bottom: 48px;

                &:last-child {
                    flex: 0 0 calc(28% - #{$list-item-indent});
                }
            }
        }
    }
}
