@import '../../style/vars/_all';

.title {
    position: relative;
    padding: 20px;
    margin: 0;
    cursor: pointer;

    &:after {
        content: '▲';
        position: absolute;
        top: 50%;
        right: 20px;
        font-size: 20px;
        transform: translateY(-50%);
    }

    .collapsed & {
        &:after {
            content: '▼';
        }
    }

    &:hover {
        background: rgba(#000, 0.1);
    }
}

.filters {
    background-color: $color-cool-gray-25;
    padding: 10px;

    .collapsed & {
        display: none;
    }

    &.negative {
        background-color: $color-white;

        :global {
            .Select__control,
            .DateRangePicker,
            .DateRangePicker input[type="text"].DateInput_input,
            .DateRangePickerInput,
            .DateInput,
            .DateInput_input {
                background-color: $color-cool-gray-25;
            }

            .DateRangePicker input[type="text"].DateInput_input{
                border-color: $color-cool-gray-25;

                &:focus {
                    background: $color-sea-blue-100;
                }
            }
        }
    }

    > div {
        padding: 10px;
    }

    > button {
        margin: 10px;

        &:first-child {
            margin-right: 0 !important;
        }
    }

    @media all and (min-width: 890px) {
        margin: $spacing-m 0 0;
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        position: relative;
        z-index: 3;

        > div {
            flex: 1 1 auto;
            min-width: 400px;
            max-width: 50%;
        }

        &[data-filters='4'] {
            > div {
                width: 50%;
            }
        }
    }

    // Add spacing at the same time as <FilterTableRow /> changes layout
    @media all and (min-width: 1024px) {
        padding: $spacing-s;

        > div {
            padding: $spacing-s;
        }

        > button {
            margin: $spacing-s;
        }
    }

    // On wide screens put the filter buttons on the same row as the filters
    // but only in lists that has less than 4 filters
    @media all and (min-width: 1440px) {
        &[data-filters='1'],
        &[data-filters='2'] {
            flex-wrap: nowrap;
        }

        &[data-filters='4'] > div {
            flex: 1 1 auto;
            width: 50%;
        }
    }
}

.itemCount {
    align-self: center;
    margin: 0;
}

.buttons {
    @media all and ($breakpoint-header-width-max) {
        > * {
            width: 100%;
            margin: 0 0 $spacing-m;
        }
    }

    @media all and (max-width: $breakpoint-card-single-max) {
        .externalLink {
            display: inline-block;
            margin-bottom: $spacing-sm;
            width: auto;
        }
    }

    @media all and ($breakpoint-header-width) {
        display: flex;

        &.hasExternalLink {
            justify-content: space-between;
            align-items: center;
        }

        .internalLink {
            order: 3;
            margin-left: $spacing-m;
        }

        .rssLink {
            order: 4;
            margin-left: auto;
            align-self: center;

            &.hasExternalLink {
                margin-right: $spacing-m;
            }
        }

        .externalLink {
            order: 4;
        }

        .toggleFilters {
            order: 1;
            align-self: flex-start;
        }

        .switches {
            order: 2;
            margin-left: $spacing-m;
        }

        .itemCount {
            order: 3;
            margin-left: auto;
        }
    }
}

.tabs {
    order: 5;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    @media all and ($breakpoint-header-width) {
        justify-content: flex-end;
    }

    li {
        display: inline-flex;
        margin-bottom: 15px;
        margin-right: 15px;

        @media all and ($breakpoint-header-width) {
            margin-left: 15px;
            margin-right: 0px;
        }
    }

    a {
        margin: 0;
    }
}

.rssLink {
    text-align: right;

    a {
        margin-right: 0;

        &::after {
            content: none;
        }
    }

    svg {
        width: 14px;
        height: 14px;
        margin-left: 4px;
        margin-bottom: -1px;
        fill: $color-deep-blue-100;
    }
}
