@import "../../style/vars/_all";
@import "../../style/shared-components-styles/country-page-styles";

:global {
    .country-page-contact-us-block {
        $self: &;
        background: $color-blue-border;
        padding-top: 64px;
        padding-bottom: 64px;
        color: white;

        &__container {
            @include container-wide;
            display: flex;
            flex-direction: column;
            margin-bottom: 0;

            @media (min-width: $breakpoint-ipad) {
                flex-wrap: wrap;
                flex-direction: row;
            }
        }

        &__heading {
            font-weight: 400;
            font-size: convert-to-rem(32px);
            line-height: convert-to-rem(40px);
            margin: 0 0 18px;
            color: inherit;
        }

        &__heading,
        &__intro {
            @media (min-width: $breakpoint-ipad) {
                flex: 0 0 100%;
                padding-right: $item-half-width;
            }
        }

        &__intro{
            margin: 0 0 64px;
        }

        &__intro,
        &__address {
            color: inherit;
        }

        &__phone{
            display: inline-flex;
            color: inherit;
            font-weight: 400;
            font-size: convert-to-rem(18px);
            line-height: convert-to-rem(26px);
            transition: border-color 0.3s ease-in-out;
            border-bottom-width: 2px;
            border-bottom-style: solid;
            border-color: transparent;
            align-self: flex-start;

            &:hover,
            &:focus-visible{
                border-color: currentColor;
            }
        }

        &__social-link-heading {
            color: inherit;
        }

        &__column {
            display: flex;
            flex-direction: column;
            
            &+.country-page-contact-us-block__column {
                margin-top: 46px;

                @media (min-width: $breakpoint-ipad) {
                    margin-top: 0;
                }
            }

            @media (min-width: $breakpoint-ipad) {
                flex: 0 0 #{$item-half-width};
            }
        }

        &__social-media {
           margin-top: auto;
           padding-top: 64px;

            &-list {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }

            &-list-item {
                margin-right: 32px;

                &:last-child {
                    margin-right: 0;
                }
            }

            &-heading {
                margin: 0 0 16px;
            }
        }


        &__employee-list {
            &-item {
                margin: 0 0 24px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &__employee {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;

            &-image-container {
                flex: 0 0 86px;
                margin-right: 32px;
                height: 84px;
                height: 84px;
                overflow: hidden;
                border-radius: 50%;
            }

            &-text-container {
                padding: 6px 0 0;

                @media (min-width: $breakpoint-ipad) {
                    padding: 12px 0 0;
                }
            }

            &-name {
                margin: 0 0 8px;
                display: inline-flex;

                @media (min-width: $breakpoint-ipad) {
                    margin-bottom: 14px;
                }
            }

            &-position {
                font-size: convert-to-rem(16px);
                line-height: convert-to-rem(24px);
                font-weight: 400;
            }
        }
    }
}