$shortcut-background: $color-white;
$employee-background: $color-sea-blue-100;

body.frontpage main {
    .shortcuts {
        padding: $spacing-m $spacing-sm $spacing-m;
        background: $shortcut-background;

        h2 {
            margin: 0 0 $spacing-sm;
        }

        ul {
            list-style: none;
            margin: 0 -15px;
        }

        li {
            display: block;
            padding: 0 $spacing-s $spacing-s;
        }

        a {
            color: $color-deep-blue;
            text-decoration: none;

            &:after {
                margin-left: $spacing-s;
            }
        }


        @media all and (min-width: 1440px) {
            ul {
                display: flex;
                flex-wrap: wrap;

                li {
                    flex: 0 0 auto;
                    width: 50%;
                }
            }
        }

        @media all and ($breakpoint-framework-width) {
            padding: $spacing-l;

            .shortcut-wrapper {
                max-width: 600px;
                margin-left: auto;
                flex: 1;
            }
        }
    }

    .featured-employee {
        padding: $spacing-m $spacing-sm;
        background: $employee-background;

        // TODO: Breakpoint variable _employee-block.scss
        @media all and (min-width: 600px) {
            padding-right: 0;
        }
    }

    @media all and ($breakpoint-framework-width) {
        .start {

            [data-edit-mode] & {
                display: flex;
                overflow: hidden;
                flex-wrap: wrap;
                height: 100% !important;

                > .epi-editContainer {
                    width: 100% !important;
                }
            }
        }

        .search-block {
            flex: 1 1 100%;
            width: 100%;
            display: flex;

            [data-edit-mode] & {
                height: 100% !important;
            }
        }
        
        .shortcuts-block {
            width: 100%;
        }

        .shortcuts {
            flex: 0 1 auto;
            width: 50%;

            ul {
                overflow-y: auto;
            }

            li {
                word-break: break-word;
            }
        }

        .featured-employee {
            position: relative;
            flex: 0 1 auto;
            padding: $spacing-l $spacing-m $spacing-l $spacing-l;
            display: flex;
            align-items: center;
            width: 50%;
            justify-content: center;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 100%;
                bottom: 0;
                width: 50vw;
                background-color: $shortcut-background;
            }

            @media all and ($breakpoint-menu-snapping) {
                max-width: 660px;
                width: calc(50% - 60px);
            }
        }
    }
}
