@import "../../style/vars/_all";

:global{
    .rich-text-links-block{
        list-style: none !important;
        margin: 16px 0!important;
        padding: 0!important;

        .rich-text-links-block__item{
            list-style: none !important;
            padding: 0;
            margin: 0 0 16px;

            &:before{
                display: none;
            }
        }
    } 
}