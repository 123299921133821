$card-mobile-size: 100px;
$hover-transition: 350ms cubic-bezier(0.165, 0.84, 0.44, 1);

.card {
    height: 100%;
    position: relative;
    display: flex;
    background-color: $color-white;
    color: $color-deep-blue-100;
    text-decoration: none;

    &.negative {
        background-color: $color-cool-gray-25;

        .content {
            .intro:after {
                background: linear-gradient(rgba($color-cool-gray-25, 0), $color-cool-gray-25);
            }
        }
    }
    

    @media all and (max-width: $breakpoint-card-small-max) {
        h2, .h2 {
            @include typo(body)
        }
    }

    .content {
        display: flex;
        order: 2;
        flex: 1;
        padding: 10px $spacing-s;
        flex-direction: column;
        justify-content: space-between;
        font-weight: 300;

        h2, .h2 {
            word-wrap: break-word;
            @include list-item-header();

            svg {
                height: 13px;
                width: 13px;
                fill: currentColor;
                margin-right: 10px;

                @media all and (min-width: $breakpoint-card-image) {
                    display: none;
                }
            }
        }

        // Make sure that content gets "text-overflow: ellipsis;" on mobile
        // If applied to all breakpoints this will hide the arrow decoration on desktop
        @media all and ($breakpoint-framework-max) {
            overflow-x: hidden;
        }

        .intro {
            position: relative;
            max-height: calc(1.1875rem * 2);

            display: block;
            @include truncate-text(2);
        }

        .meta {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-top: 10px;

            .categories {
                &:before {
                    content: " / ";
                }
            }
        }
    }

    .image {
        position: relative;
        flex: 0 0 auto;
        height: auto;
        min-height: $card-mobile-size;
        width: $card-mobile-size;
        background-color: $color-mountain-gray-50;

        .video-button {
            display: none;
        }

        > .fluid-image {
            position: absolute;
        }
    }

    .no-access {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba($color-modal, .8);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        span {
            color: $color-white;
            display: none;
        }

        svg {
            fill: $color-white;
            max-height: $spacing-s;
        }
    }

    .list-item:focus &,
    &:hover {
        background-color: $color-sea-blue-65;
        color: $color-white;

        .content .intro:after {
            background: linear-gradient(rgba($color-sea-blue-100, 0), $color-sea-blue-100);
        }
    }

    &:not(.no-medium) {
        @media all and (min-width: $breakpoint-card-single) {
            border-right: none;
            min-height: 11.875rem;

            .content {
                position: relative;
                order: 0;
                flex: 0 0 50%;
                padding: $spacing-sm;
                max-width: 50%; // IE11 fix

                h2, .h2 {
                    margin-bottom: $spacing-s;
                }
            }

            .intro {
                max-height: calc(1.1875rem * 3);
                @include truncate-text(3);
            }

            .image {
                flex: 0 0 50%;
                height: auto;
                width: auto;
                // opacity: .9; TODO: Make "fallback" images don't have any opacity
                transition: opacity $hover-transition;
                z-index: 0;
                overflow: hidden;
            }

            .fluid-image {
                position: absolute;
                top: 0;
                bottom: 0;
                width: 100%;
                transform: scale(1.05);
                transition: transform $hover-transition;
            }

            .no-access {
                top: calc(50% - 30px);
                left: calc(50% - 30px);
                height: 60px;
                width: 60px;
                border-radius: 50%;
            }

            .list-item:focus &,
            &.hover,
            &:hover {
                z-index: $z-index-card-hover;

                .content {
                    color: $color-deep-blue-100;

                    &:after {
                        border-left-color: $color-sea-blue-65;
                    }

                    .intro:after {
                        background: linear-gradient(rgba($color-sea-blue-65, 0), $color-sea-blue-65);
                    }
                }

                .image {
                    opacity: 1;
                }

                .fluid-image {
                    transform: translateZ(0) scale(1.01);
                }

                .no-access {
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    border-radius: 0;

                    svg {
                        display: none;
                    }

                    span {
                        display: block;
                    }
                }
            }
        }

        @include hoverable-card();
    }

    &.no-large {
        h2, .h2 {
            font-size: 18px;
            line-height: 28px;
        }
    }

    &:not(.no-large) {
        @media all and (min-width: $breakpoint-card-small) {
            min-height: 18.125rem;

            .content {
                padding: 37px $spacing-m $spacing-m;
            }
        }
    }
}

article.card.news {
    .intro {
        display: none;
    }

    &:not(.no-large) {
        @media all and (min-width: $breakpoint-card-small) {
            .intro {
                display: block;
                display: -webkit-box;
            }
        }
    }
}

article.card:not(.news) .meta {
    display: none;
}
