@import "../../style/vars/_all";

:global {
    .market-insight-qa-block {
        &__question {
            border: none;
            margin: 0;
            padding: 16px;
            width: 100%;
            display: flex;
            background: none;
            justify-content: space-between;
            cursor: pointer;

            &_active,
            &:hover,
            &:focus {
                background-color: $color-background-light;
            }

            &-text {
                max-width: calc(100% - 50px);
                font-size: 16px;
                line-height: 1.2;
                font-weight: 500;
                color: $color-deep-blue;
                align-self: center;
                text-align: left;

                @media all and ($breakpoint-framework-width) {
                    font-size: 18px;
                }
            }

            &-icon {
                width: 16px;
                height: 16px;
                fill: $color-deep-blue  !important;
                align-self: center;
                transition: all 0.4s ease;

                &_active{
                    transform: rotate(180deg);
                }
            }
        }

        &__answer {
            padding: 16px;
        }

        .ReactCollapse--collapse {
            transition: height 300ms;
        }
    }
}