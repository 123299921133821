@import "../../style/vars/_all";
@import "../../style/shared-components-styles/market-insight-reports-blocks-styles";

:global {
    .market-insight-interactive-reports-block {
        $self: &;
        margin: 48px 0;

        @media all and ($breakpoint-framework-width) {
            margin: 60px 0 62px;
        }

        &__container {
            @include container-narrow;
        }

        &__heading {
            @include market-insight-reports-block-heading;

            @media all and ($breakpoint-framework-width) {
                margin-bottom: 24px;
            }
        }

        &__columns {
            margin-top: 28px;
           
            @media all and ($breakpoint-framework-width) {
                margin-top: 37px;
                columns: 2;
            }
        }

        &__item {
            &:not(:last-child) {
                margin-bottom: 20px;
            }

            @media all and ($breakpoint-framework-width) {
                padding-right: 20px;
                margin-bottom: 26px !important;
            }
        }

        &__link {
            #{$self} & {
                font-size: 18px;

                @media all and ($breakpoint-framework-width) {
                    font-size: 20px;
                }
            }
        }
    }
}

