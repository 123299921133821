@import "../../style/vars/_all";
@import "../../style/vars/_mixins";

:global {     
	.select-filter {
        $self: &;

        input[type="text"] {
            min-height: 45px;

            @media all and ($breakpoint-framework-width) {
                min-height: 55px;
            }
        }

        &_size-large {
            input[type="text"] {
                @media all and ($breakpoint-framework-width) {
                    min-height: 70px;
                }
            }
        }

        &__label {
            font-size: 16px;
            line-height: 1.2;
            font-weight: 700;
            margin-bottom: 10px;

            &:not(.select-filter__label_small) {
                @media all and ($breakpoint-framework-width) {
                    font-size: 20px;
                    margin-bottom: 16px;
                }
            }
        }

        &__arrow {
            width: 16px;
            height: 8px;
            margin-right: 10px;
        }

        .Select__placeholder {
            color: $color-deep-blue-100;
        }

        &_rounded .Select__control {
            @include shadowed-box;
        }

        .Select__dropdown-indicator {
            background: none;

            * {
                display: block;
            }
        }

        .Select__value-container {
            padding: 0 15px !important;
        }

        .Select__multi-value {
            background: $color-sea-blue-15 !important;
            border-radius: 4px;

            * {
                color: $color-deep-blue-100 !important;
            }

            &__label {
                padding: 2px;
                font-size: 14px;
                font-weight: 500;
                padding-left: 4px !important;
            }

            &__remove {
                border: none;
                
                &:hover {
                    background: $color-sea-blue-25 !important;
                }
            }
        }

        .Select__control {
            &--is-focused {
                box-shadow: inset 7px 0 0 $color-sea-blue-100 !important;
            }
        }
    }
}