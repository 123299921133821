@import "../../style/vars/_all";

$self: '.link-button';

:global {
    .link-button {
        display: inline-block;
        background-color: $color-deep-blue-100;
        @include typo(h4);
        min-width: 176px;
        padding: 12px 20px;   
        font-family: $font-family;
        color: $color-white;
        text-align: center;
        -webkit-appearance: none;
        border: none;
        cursor: pointer;
        text-decoration: none;
        box-shadow: none;
        outline: none;

        @media all and ($breakpoint-framework-width) {
            padding: 14px 32px;   
        }

        &.hover,
        &:hover,
        &:focus {
            background-color: $color-sea-blue-100;
            color: $color-white;
        }
    }
}