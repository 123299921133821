
$image-width-mobile: 142px;
$column-indent-mobile: 10px;

@mixin list-page-header {
    @include container;
    display: flex;
    justify-content: space-between;
    margin: 14px 0;

    @media all and ($breakpoint-framework-width) {
        margin: 60px 0 35px;
    }
}

@mixin list-page-heading {
    @include container;

    font-size: 24px;
    line-height: 1.4;
    font-weight: 400;
    margin: 14px 0;

    @media all and ($breakpoint-framework-width) {
        font-size: 46px;
        line-height: 1.2;
        margin: 60px 0 35px;
    }
}

@mixin list-page-intro {
    @include container;
    margin: 10px 0 40px;

    @media all and ($breakpoint-framework-width) {
        margin: 38px 0 90px;
    }
}

@mixin list-page-month {
    &:not(:last-child) {
        margin-bottom: 20px;

        @media all and ($breakpoint-framework-width) {
            margin-bottom: 32px;
        }
    }

    &-heading {
        font-size: 20px;
        line-height: 1.33;
        font-weight: 700;
        margin-bottom: 22px;
        text-transform: capitalize;

        @media all and ($breakpoint-framework-width) {
            font-size: 24px;
            margin-bottom: 32px;
        }
    }
}

@mixin list-page-item {
    $self: &;
    display: flex;
    flex-flow: wrap;

    &:not(:last-child) {
        margin-bottom: 32px;
    }

    &__image {
        flex: 0 0 $image-width-mobile;

        @media all and ($breakpoint-framework-width) {
            flex: 0 0 223px;
        }

        &-link {
            &:focus {
                outline: auto;
            }
        }
    }

    &__content {
        flex: 0 0 100%;

        @media all and ($breakpoint-framework-width) {
            margin-left: 32px;
            flex: 1;
        }
    }

    &__heading {
        margin-bottom: 8px;

        @media all and ($breakpoint-framework-width) {
            flex: 0 0 100%;
        }

        @media all and ($breakpoint-framework-width-max) {
            .link-with-icon__icon {
                display: none;
            }
        }

    }

    &__top-info {
        display: none;

        @media all and ($breakpoint-framework-width) {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        &_mobile {
            display: flex;
            flex-direction: column;
            margin-left: $column-indent-mobile;
            flex: 1;

            #{$self}__tags {
                order: 1;
            }

            #{$self}__heading {
                order: 2;
            }

            #{$self}__date {
                order: 3;
            }

            @media all and ($breakpoint-framework-width) {
                display: none;
            }
        }
    }

    &__date {
        font-size: 12px;
        line-height: 1.2;

        @media all and ($breakpoint-framework-width) {
            font-size: 20px;
        }
    }

    &__tags {
        margin-bottom: 10px;

        @media all and ($breakpoint-framework-width) {
            margin-left: 60px;
            margin-bottom: 0;
            flex: 1;
        }
    }

    &__intro {
        margin: 20px 0 16px;
        font-size: 14px;

        @media all and ($breakpoint-framework-width) {
            font-size: 20px;
            margin: 24px 0;
        }
    }

    &__contact-person {
        font-size: 14px;
        line-height: 1.2;
        margin-top: 16px;

        @media all and ($breakpoint-framework-width) {
            font-size: 20px;
            margin-top: 24px;
        }
    }

    &__link {
        color: inherit;
        text-decoration: underline;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}

@mixin list-page-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: rgba(#ffffff, 0.7);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

@mixin list-page-error {
    color: $color-error-text;
    margin: 20px 0;
}