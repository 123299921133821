$z-index-card: 1;
$z-index-card-hover: 2;
$z-index-daterange: 3;
$z-index-search-results: 3;
$z-index-list-header: 3;
$z-index-scrollhint: 3;
$z-index-header: 4;
$z-index-menu: 6;

$z-index-modal: 10;
