@import "../../style/vars/_all";

.hide { display: none; }

.office {
    width: 100%;
    background-color: $color-sea-blue-100;
    color: $color-white;
}

.country {
    margin: 0 0 $spacing-m;

}

.intro {
    margin: 0 0 $spacing-m;
}

.employees {
    margin: 0 0 $spacing-m;

    > div {
        margin: $spacing-s 0 0;
    }
}

.info {
    @media all and (min-width: 1024px) {
        display: flex;
        flex-wrap: wrap;

        .intro {
            flex: 1;
            margin-bottom: 0;
            margin-right: 90px;
        }

        .details {
            flex: 0 0 276px;
        }
    }
}

.map {
    margin: $spacing-s 0 0;
}

.countryReport {
    margin: $spacing-m 0 0;
}