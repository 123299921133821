@import "../../style/vars/_all";

.header {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: $color-deep-blue-100;
    color: $color-white;
    padding: $spacing-sm;

    @media all and ($breakpoint-framework-width) {
        padding: $spacing-l $spacing-l $spacing-m;
    }

    @media all and ($breakpoint-wide-l) {
        $width: $framework-max-width;
        $padding: calc(50% - (#{$width} / 2));

        padding-left: $padding;
        padding-right: $padding;
    }
}

.searchWrapper {
    max-width: 36.25rem;
}

.resultText {
    display: block;
    margin-top: 10px;

    &:after {
        display: inline-block;
        content: " ";
    }
}

.seeMore {
    margin: $spacing-s 0 0;

    @media all and (min-width: $breakpoint-card-single) {
        margin: $spacing-m 0 0;
    }
}

.employeeList {
    display: block;
}

.noResults {
    padding: $spacing-m $spacing-sm;
    background-color: $color-cool-gray-25;

    @media all and ($breakpoint-framework-width) {
        padding: $spacing-l;
    }
}