@import "../../style/vars/_all";
$height: 40px;

$self: '.link-with-icon';

:global {
    body {

        #{$self} {
            color: $color-deep-blue-100;
            box-shadow: none !important;
            border: none;
            font-size: 16px;
            line-height: 1.45;

            &_size {
                &-small {
                    font-size: 14px !important;
                }

                &-large {
                    font-weight: 700;
                    line-height: 1.7;
                }
            }

            &_icon-position-left{
                display: inline-flex;
                flex-direction: row-reverse;
            }

            @media all and ($breakpoint-framework-width) {
                font-size: 20px;

                &_size {    
                    &-large {
                        font-size: 32px;
                        line-height: 1.18;
                    }
                }
            }

            &.link-with-icon_external {
                @media all and ($breakpoint-framework-width) {
                    font-size: 18px;
                }
            }

            &:hover,
            &:focus-visible {
                border-bottom: none;
                box-shadow: none;

                #{$self}__text {
                    border-color: transparent !important;
                }
            }

            &:focus {
                outline: auto;
            }

            &_in-country-page-activities-campaigns-block{
                display: inline-block;
                color: white !important;
                background: $color-deep-blue;
                padding: 14px;
               
                @media all and ($breakpoint-framework-width) {
                    padding: 14px 24px;
                }
            }
        }

        #{$self}__text {
            display: inline;
            line-height: 1.3;
            border-bottom-width: 2px;
            border-bottom-style: solid;
            border-color: $color-blue-border;
            transition: border-color 0.3s ease-in-out;
            padding: 0 0 2px;
            vertical-align: top;
            border-color: $color-blue-border;
        }

        #{$self}__icon {
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-left: 10px;
            margin-top: 4px;
            align-self: flex-start;
            fill: currentColor;
        }
        
        .link-with-icon_restricted #{$self}__text,
        .link-with-icon_download #{$self}__text,
        .link-with-icon_regular #{$self}__text {
            border-color: $color-blue-border;
        }

        .link-with-icon_icon-position-left #{$self}__icon {
            margin-right: 10px;
            margin-left: 0;
        }
     
        .link-with-icon_see-all #{$self}__text {
            font-size: convert-to-rem(18px);
        }

        .link-with-icon_in-country-page-activities-campaigns-block {
            #{$self}__text{
                padding: 0 !important;
                border-color: transparent;
                vertical-align: middle;
                font-size: convert-to-rem(18px);
                line-height: convert-to-rem(28px);
                font-weight: 400;
            }

            #{$self}__icon{
                vertical-align: middle;
                margin-top: 0 !important;
            }

            &:hover,
            &:focus-visible{
                text-decoration: none;
                
                #{$self}__text{
                    border-color: white !important;
                }
            }
            
        }

        .link-with-icon_dark-mode #{$self}__text {
            border-color: $color-deep-blue-100;
        }

        .link-with-icon_restricted.link-with-icon_in-country-page-activities-campaigns-block #{$self}__icon{
            fill: white;
        }
        
        .link-with-icon_in-country-page-contact-us-block #{$self}__text,
        .link-with-icon_in-country-page-contact-us-block #{$self}__icon{
            color: white;
        }

        .link-with-icon_in-country-page-contact-us-block #{$self}__text{
            border-color: white;
        }

        .link-with-icon_download #{$self}__icon {
            width: 21px;
            height: 21px;
        }

        .link-with-icon_restricted #{$self}__icon {
            width: 16px;
            height: 21px;
            margin-top: 4px;
            fill: $color-deep-blue-100;
        }

        .link-with-icon_see-all #{$self}__icon {
            width: 16px;
            height: 16px;
        }

        .link-with-icon_external #{$self}__icon {
            width: 14px;
            height: 14px;
            margin-left: 5px;
        }

        .link-with-icon_in-market-insight-link-with-description-block #{$self}__icon {
            width: 20px;
            height: 16px;
            margin-left: 7px;
        }

        .link-with-icon_size-large {
            #{$self}__icon {
                @media all and ($breakpoint-framework-width) {
                    width: 23px;
                    height: 30px;
                }
            }

            #{$self}__text {
                padding: 0;
            }
        }

        .link-with-icon_size-small {
            #{$self}__icon {
                margin: 0 0 2px 4px;

                @media all and ($breakpoint-framework-width) {
                    width: 16px;
                    height: 16px;
                }
            }

            #{$self}__text {
                padding: 0;
            }
        } 
    }
}