.employees-list {
    margin: 0 0 $spacing-m;

    @media print {
        @include no-break-inside;
    }

    h4 {
        margin: 0 0 $spacing-s;
    }

    .employee-card {
        margin: 0 0 $spacing-s;
    }

    .employee-page-link {
        display: inline-block;
    }
}
