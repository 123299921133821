.error-page {
    height: 100%;

    &.error-404 main {
        background-image: url('../assets/404.jpg');
    }

    &.error-500 main {
        background-image: url('../assets/500.jpg');
    }

    main {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: $color-white;
        background-color: $color-404-background;
        text-align: center;

        @media all and (max-width: $breakpoint-framework-max-px) {
            justify-content: flex-start;
            padding: 20px;
        }

        &.error-background-image {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top center;

            @media all and (max-width: $breakpoint-framework-max-px) {
                background-size: 180%;
            }
        }

        p {
            padding-top: 35vh;

            @media all and ($breakpoint-framework-width) {
                padding-top: 100px;
            }
        }
    }
}
