$page-width: 64.375rem;
$banner-image-width: 1440px;
$medium-container-width: 1200px;
$small-container-width: 984px;
$popup-container-width: 1500px;
$content-width: 820px;
$imageShadowWidth: 15px;

$spacing-s: 15px; // 15px
$spacing-sm: 20px; // 20px
$spacing-m: 30px; // 30px
$spacing-l: 60px; // 60px
$spacing-xl: 90px; // 90px

$spacing-s-mobile: 10px;
$spacing-m-mobile: $spacing-sm;
$spacing-l-mobile: $spacing-sm * 2;
$spacing-xl-mobile: calc($spacing-xl / 2);

$header-height-s: 50px;
$header-height: 70px;

$aside-width:320px;
$container-width: 1320px;
$container-width-medium: 1236px;
$container-width-wide: 1440px;
$container-width-narrow: 990px;