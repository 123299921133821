@import "../../style/vars/_all";

:global {
    .pager {
        $self: &;
        display: flex;
        margin: 0 auto $spacing-sm;
        flex-wrap: wrap;
        width: 100%;

        &.negative {
            .page {
                background-color: $color-white;
            }
        }

        @media all and (min-width: $breakpoint-card-single) {
            margin-bottom: $spacing-l;
        }

        #{$self} {
            * {
                outline: none;
            }

            &__control {
                border-radius: 0;
                border-color: $color-cool-gray-50;
                box-shadow: none;
                cursor: pointer;
                height: 60px;

                input {
                    &:focus {
                        box-shadow: none;
                    }
                    &:focus-visible {
                        color: $color-deep-blue-100 !important;
                    }
                }
            }

            &__dropdown-indicator {
                color: black;

                svg {
                    height: 24px;
                    width: 24px;
                }
            }

            &__indicator{
                padding-right: 15px;
            }

            &__indicator-separator {
                display: none;
            }

            &__menu {
                top: 100%;
                margin-top: 0;
            }

            &__value-container {
                box-shadow: none;
                padding-top: 0;
                padding-bottom: 0;
                padding: 0 30px 0 12px;
            }

            &__single-value {
                font-size: 20px;
                color: black;
            }

        }

        &__select {

            &-container {
                min-width: 185px;

                @media all and ($breakpoint-framework-width) {
                    order: 2;
                }

                @media all and ($breakpoint-framework-max) {
                    flex: 1 0 100%;
                    order: 1;
                }
            }
        }

        &__button {
            background-color: $color-deep-blue-100;
            -webkit-appearance: none;
            border: none;
            cursor: pointer;
            text-decoration: none;
            box-shadow: none;
            color: $color-white;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 16px;
            display: inline-flex;
            flex-wrap: nowrap;
            height: 60px;

            &.hover,
            &:hover,
            &:focus {
                background-color: $color-sea-blue-100;
            }

            &_prev {
                margin-right: 24px;
                order: 1;

                .pager__button-icon {
                    margin-right: 11px;
                }

            }

            font-size: 20px;
            line-height: 1.2;

            &_next {
                margin-left: 24px;
                order: 3;

                .pager__button-icon {
                    margin-left: 11px;
                }
            }

            @media all and ($breakpoint-framework-max) {
                order: 2;
                margin-top: 10px;

                &_next {
                    margin-left: 0;
                }

            }

            &-icon {
                fill: white;
                display: block;
                width: 20px;
                height: 14px;
            }
        }
    }
}