.backgroundVideoWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.backgroundVideo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    max-height: none;
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%,-50%);
    object-fit: cover;
}