@import "../../style/vars/_all";
@import "../../style/shared-components-styles/country-page-styles";

:global {
    .country-page-latest-news-on-market-access-block {
        $self: &;
        @include container-wide;

        &__heading {
            @include country-page-block-heading;
        }

        &__list {
            display: flex;
            flex-direction: column;

            @media (min-width: $breakpoint-ipad) {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            &-item {
                margin-bottom: 43px;

                &:last-child {
                    margin-bottom: 0;
                }

                @media (min-width: $breakpoint-ipad) {
                    flex: 0 0 #{$item-half-width};
                    margin-bottom: 0;
                }
            }
        }

        &__item {
            color: $color-deep-blue;

            &-heading {
                font-size: convert-to-rem(24px);
                line-height: convert-to-rem(32px);
                margin: 0 0 25px;
                font-weight: 700;

                @media (min-width: $breakpoint-ipad) {
                    margin-bottom: 19px;
                }
            }

            #{$self}__item-link {
                display: inline;
                line-height: 1.3;
                color: $color-deep-blue;
                text-decoration: underline;
                padding: 0;
                border: none;
                box-shadow: none;

                &:hover,
                &:focus-visible {
                    text-decoration: none;
                    border: none;
                    box-shadow: none;
                }
            }
        }

        &__published-date {
            margin: 19px 0 0;

            @media (min-width: $breakpoint-ipad) {
                margin-top: 24px;
            }
        }

        &__archive-link {
            margin-top: 48px;
            display: inline-flex;

            @media (min-width: $breakpoint-ipad) {
                margin-top: 50px;
            }
        }
    }
}